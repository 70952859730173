.isnot-home .chat-popup {
  width: max-content;
  position: relative;
}

.chat-popup {
  width: auto;
  display: flex;
  z-index: 3;
  flex-direction: row;
  // height: 100%;
  // height: calc(100% - 190px);
  height: calc(100% - 135px);
  max-height: 90vh;
  background-color: #ffffff;
  border-radius: 18px;
  position: fixed;
  transition: all 0.3s ease;
  right: 20px;
  // top: 69px;
  top: 90px;
  box-sizing: border-box;
  // box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.15);
  filter: drop-shadow(0px 4px 20px rgba(0, 0, 0, 0.15));

  &-list {
    width: 380px;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    &__header {
      padding: 20px 25px;
      display: flex;
      justify-content: space-between;
      border-bottom: 1px solid #ececec;
      align-items: center;
      height: 80px;
      box-sizing: border-box;

      &-title {
        font-size: 20px;
        color: #000000;
      }

      &-actions {
        display: flex;
      }
    }

    &__body {
      padding: 25px 15px;
      height: calc(100% - 199px);
      box-sizing: border-box;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      gap: 30px;

      &-search {
        // margin-bottom: 30px;
        padding: 0 15px;

        &_form {
          width: 100%;
        }

        &_input {
          width: 100%;
          font-size: 15px;
          color: #363c65;
          border-radius: 8px;
          outline: none;
          box-sizing: border-box;
          background-color: #f8fafc;
          transition: 0.2s;
          border: 1px solid #ececec;
          padding: 10px 8px;
          font-size: 15px;

          .MuiInputBase-input {
            color: #ACACAC;

            &::placeholder {
              color: #ACACAC;
              opacity: 1;
            }
          }


          & label {
            color: #acacac;
          }
          & .MuiInputBase-root {
            border-radius: 8px;
          }
          & .MuiInput-underline:after {
            border-bottom-color: green;
          }
          & .MuiOutlinedInput-root {
            & fieldset {
              border-color: #ececec;
            }
            &:hover fieldset {
              border-color: $blue;
            }
            &.Mui-focused fieldset {
              border-color: $blue;
            }
          }

          &::placeholder {
            color: #acacac;
          }
        }
      }

      &-messages {
        height: 100%;

        &_container {
          height: 95%;

          & .MuiBox-root {
            padding: 0;
            height: 100%;
          }
          & .MuiTypography-root {
            height: 100%;
          }
        }

        &_list {
          height: 100%;
          display: flex;
          flex-direction: column;
          gap: 26px;

          & .MuiTabs-flexContainerVertical {
            gap: 10px;
          }

          .MuiTabScrollButton-root {
            display: none;
          }
        }

        &_item {
          width: 100%;
          display: flex !important;
          justify-content: space-between !important;
          align-items: center !important;
          gap: 14px;
          text-transform: unset !important;
          flex-direction: row !important;
          padding: 10px 20px 10px 20px !important;
          border-radius: 8px !important;

          &_avatar {
            width: 50px;
            height: 50px;

            & img {
              height: inherit;
              width: inherit;
            }

            & .MuiAvatar-root {
              width: inherit;
              height: inherit;
              background-color: #E0EFFF;
              color: #4382C4;
            }
          }

          &_content {
            overflow: hidden;
            text-align: left;

            &_name {
              font-size: 15px;
              color: #000000;
              font-weight: 500;

              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
              box-sizing: border-box;
              margin-bottom: 5px;
              line-height: 16px;
            }

            &_last-message {
              font-size: 14px;
              font-weight: 400;
              color: #6e6f6f;

              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
              box-sizing: border-box;
              line-height: 16px;
            }
          }

          &_status {
            &-unread {
              color: #51A3FF;
              // width: 12px !important;
              // height: 12px !important;
              font-size: 6px !important;
            }

            &-read {
              width: 17px !important;
              height: 17px !important;
            }
          }

          &_actions {
            transition: all 0.3s ease;
            display: none;
          }

          &:hover {
            background-color: #F2F2F2;
            border-radius: 14px !important;

              .chat-popup-list__body-messages_item_actions {
                  display: flex;
              }

              .chat-popup-list__body-messages_item_status {
                display: none;
              }
          }
        }
      }

      &-chanels {
      }

      &-contacts {
      }
    }

    &__footer {
      height: 79px;
      border-top: 1px solid #ececec;
      z-index: 2;
      background-color: #fff;
      border-radius: 0 0 18px 18px;

      &_container {
        padding: 0 40px;

        & .MuiTabs-flexContainer {
          justify-content: space-between !important;
        }

        & .MuiButtonBase-root {
          text-transform: unset;
          letter-spacing: 1px;
          // min-height: unset;
          // min-width: 80px;
          padding: 0;
          color: #727272;
          font-size: 13px;
          font-weight: 400 !important;

          img {
            width: 20px;
            height: 20px;
          }

          &.Mui-selected {
            color: #51A3FF;
            font-weight: 500 !important;
          }
        }

        & .MuiTabs-indicator {
          bottom: 5px;
          display: none;
        }
      }
    }
  }

  &-messages {
    width: 380px;
    height: 100%;
    background-color: #fff;
    border-left: 1px solid #ECECEC;
    border-radius: 0 18px 18px 0;

    &-tabpanel {
      height: 100%;
      & .MuiBox-root {
        padding: 0 !important;
        height: 100%;
      }
      & .MuiTypography-root  {
        height: 100%;
      }
    }

    &__header {
      padding: 20px 15px 18px;
      display: flex;
      justify-content: space-between;
      border-bottom: 1px solid #ececec;
      height: 80px;
      box-sizing: border-box;

      &-info {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        flex-direction: row;
        gap: 10px;

        &_img {
          width: 40px;
          height: 40px;
        }

        &_name {
          font-size: 22px;
          color: #000000;
        }
      }

      &-actions {
        display: flex;
      }
    }

    &__body {
      height: calc(100% - 80px);
      position: relative;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      &-terms {
        padding: 15px;
        font-size: 13px;
        color: #888;
      }

      &-date {
        text-align: center;
        margin-top: 15px;
        border-top: 1px solid #ECECEC;

        & p {
          font-size: 13px;
          font-weight: 500;
          color: #000000;
          padding: 5px 25px;
          border: 1px solid #ECECEC;
          border-radius: 30px;
          display: inline-block;
          transform: translateY(-15px);
          background-color: #FFFFFF;
        }
      }

      &-content {
        padding: 20px 15px;
        // height: 480px;
        height: 100%;
        overflow-y: auto;
        display: flex;
        flex-direction: column;
        gap: 15px;

        &_friend {
          padding-right: 25px;
          display: flex;
          justify-content: flex-start;
          gap: 10px;

          &-avatar {
            width: 32px;
            height: 32px;

            & img {
              width: inherit;
              height: inherit;
            }
          }

          &-message {
            display: flex;
            flex-direction: column;
            gap: 15px;

            &_text {
              background-color: #F6F6F6;
              padding: 10px 15px 10px 20px;
              border-radius: 25px;
              display: flex;
              align-items: flex-end;
              justify-content: space-between;
              gap: 3px;

              & p {
                font-size: 15px;
                color: #000000;
              }

              & span {
                display: inline-block;
                text-align: right;
                font-size: 12px;
                color: #909090;
              }
            }

            &_text:first-child {
              border-radius: 0 25px 25px 25px;
            }

          }

        }


        &_own {
          padding-left: 40px;
          display: flex;
          justify-content: flex-end;
          gap: 10px;

          &-message {
            display: flex;
            flex-direction: column;
            gap: 15px;

            &_text {
              background-color: #E5F1FF;
              padding: 10px 10px 10px 20px;
              border-radius: 25px;
              display: flex;
              align-items: flex-end;
              justify-content: space-between;
              gap: 15px;


              & p {
                font-size: 15px;
                color: #000000;
              }

              & span {
                display: inline-block;
                text-align: right;
                font-size: 12px;
                color: #909090;
              }
            }

            &_text:first-child {
              border-radius: 25px 0 25px 25px;
            }

          }

        }
      }

      &-footer {
        position: relative;

        & .emoji-picker {
          position: absolute;
          bottom: 100%;
        }

        &_container {
          padding: 15px;
          display: flex;
          justify-content: space-between;
          align-items: center;
          gap: 10px;
        }

        &_inputs {
          width: 85%;
          display: flex;
          justify-content: space-between;
          align-items: center;

          padding: 10px 5px;
          border: 1px solid #CFCFCF;
          box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.05);

          border-radius: 25px;

          &-emoji {
            cursor: pointer;

            & label {
              color: #909090;
            }
          }

          &-text {
            width: 100%;

            & .MuiFormControl-root {
              margin-top: 0 !important;
              width: 100%;
            }
            & .MuiInputBase-root  {
              padding: 0 !important;
            }
            & .MuiInputBase-input {
              padding: 8.5px 5px !important;
            }
            & fieldset {
              border: unset;
            }
            & textarea.MuiInputBase-input {
              // height: 22px !important;
              // max-height: 200px;
              line-height: 25px;
              padding: 0 !important;
            }
          }

          &-upload {

            & label {
              color: #909090;
            }

          }
        }

        &_send-btn {
          & .MuiIconButton-root {
            transition: all 0.3s ease;
            background-color: $blue;
            color: white;
          }


          & .MuiIconButton-root:hover {
            background-color: $blue;
            color: white;
          }

        }
      }
    }
  }

}


// .siteWrapperLG .chat-popup {
//   &-list {
//     width: 330px;

//     &__header {
//       padding: 20px;

//       &-title {
//         font-size: 20px;
//       }
//     }

//     &__body {
//       padding: 25px 20px;
//       gap: 0;

//       &-messages {
//         &_container {
//           height: 97%;
//         }
//         &_item {
//           &_avatar {
//             width: 45px;
//             height: 45px;
//           }

//           &_content {
//             &_name {
//               font-size: 14px;
//             }

//             &_last-message {
//               font-size: 13px;
//             }
//           }
//         }
//       }
//     }

//     &__footer {
//       &_container {
//         padding: 0 20px;
//       }
//     }
//   }

//   &-messages {
//     width: 330px;

//     &__header {
//       &-info {
//         &_name {
//           font-size: 20px;
//         }
//       }
//     }

//     &__body {
//       &-date {
//         & p {
//           font-size: 12px;
//         }
//       }

//       &-content {
//         &_friend {
//           &-message {
//             &_text {
//               & p {
//                 font-size: 14px;
//               }

//               & span {
//                 font-size: 11px;
//               }
//             }
//           }

//         }


//         &_own {
//           &-message {
//             &_text {
//               & p {
//                 font-size: 14px;
//               }

//               & span {
//                 font-size: 11px;
//               }
//             }
//           }

//         }
//       }
//     }
//   }

// }


// .siteWrapperMD .chat-popup {
//   &-list {
//     width: 330px;

//     &__header {
//       &-title {
//         font-size: 20px;
//       }
//     }

//     &__body {
//       gap: 0;

//       &-messages {
//         &_container {
//           height: 97%;
//           padding-top: 10px;
//           box-sizing: border-box;
//         }
//         &_item {
//           &_avatar {
//             width: 45px;
//             height: 45px;
//           }

//           &_content {
//             &_name {
//               font-size: 14px;
//             }

//             &_last-message {
//               font-size: 13px;
//             }
//           }
//         }
//       }
//     }
//   }

//   &-messages {
//     width: 330px;

//     &__header {
//       &-info {
//         &_name {
//           font-size: 20px;
//         }
//       }
//     }

//     &__body {
//       &-date {
//         & p {
//           font-size: 12px;
//         }
//       }

//       &-content {
//         &_friend {
//           &-message {
//             &_text {
//               & p {
//                 font-size: 14px;
//               }

//               & span {
//                 font-size: 11px;
//               }
//             }
//           }

//         }


//         &_own {
//           &-message {
//             &_text {
//               & p {
//                 font-size: 14px;
//               }

//               & span {
//                 font-size: 11px;
//               }
//             }
//           }

//         }
//       }
//     }
//   }

// }


// .siteWrapperSM .chat-popup {
//   &-list {
//     width: 350px;

//     &__header {
//       &-title {
//         font-size: 20px;
//       }
//     }

//     &__body {
//       &-messages {
//         &_container {
//           height: 97%;
//         }
//         &_item {
//           &_avatar {
//             width: 45px;
//             height: 45px;
//           }

//           &_content {
//             &_name {
//               font-size: 14px;
//             }

//             &_last-message {
//               font-size: 13px;
//             }
//           }
//         }
//       }
//     }
//   }

//   &-messages {
//     width: 350px;

//     &__header {
//       &-info {
//         &_name {
//           font-size: 20px;
//         }
//       }
//     }

//     &__body {
//       &-date {
//         & p {
//           font-size: 12px;
//         }
//       }

//       &-content {
//         &_friend {
//           &-message {
//             &_text {
//               & p {
//                 font-size: 14px;
//               }

//               & span {
//                 font-size: 11px;
//               }
//             }
//           }

//         }


//         &_own {
//           &-message {
//             &_text {
//               & p {
//                 font-size: 14px;
//               }

//               & span {
//                 font-size: 11px;
//               }
//             }
//           }

//         }
//       }
//     }
//   }

// }

// .siteWrapperXS .chat-popup {
//   &-list {
//     width: 350px;

//     &__header {
//       &-title {
//         font-size: 20px;
//       }
//     }

//     &__body {
//       &-messages {
//         &_container {
//           height: 97%;
//         }
//         &_item {
//           &_avatar {
//             width: 45px;
//             height: 45px;
//           }

//           &_content {
//             &_name {
//               font-size: 14px;
//             }

//             &_last-message {
//               font-size: 13px;
//             }
//           }
//         }
//       }
//     }
//   }

//   &-messages {
//     width: 350px;

//     &__header {
//       &-info {
//         &_name {
//           font-size: 20px;
//         }
//       }
//     }

//     &__body {
//       &-date {
//         & p {
//           font-size: 12px;
//         }
//       }

//       &-content {
//         &_friend {
//           &-message {
//             &_text {
//               & p {
//                 font-size: 14px;
//               }

//               & span {
//                 font-size: 11px;
//               }
//             }
//           }

//         }


//         &_own {
//           &-message {
//             &_text {
//               & p {
//                 font-size: 14px;
//               }

//               & span {
//                 font-size: 11px;
//               }
//             }
//           }

//         }
//       }
//     }
//   }
// }


.dark .chat-popup {
  background-color: $dark-theme-background-color;

  &-list {
    &__header {
      border-bottom: 1px solid #7c7c7c;

      &-title {
        color: $dark-theme-title-color;
      }

      &-actions {

        &_more .MuiSvgIcon-root, &_close .MuiSvgIcon-root  {
          color: $dark-theme-title-color;
        }
      }
    }

    &__body {
      &-search {
        &_input {
          color: $dark-theme-title-color;
          background-color: $dark-theme-darker-background-color;
          border-color: #7c7c7c;

          & input {
            color:  $dark-theme-title-color;
          }

          & label {
            color:  $dark-theme-title-color;
          }
          & .MuiOutlinedInput-root {
            &:hover fieldset {
              border-color:  $dark-theme-title-color;
            }
            &.Mui-focused fieldset {
              border-color:  $dark-theme-title-color;
            }
          }
        }
      }

      &-messages {
        &_item {

          &_content {

            &_name {
              color: $dark-theme-title-color;
            }

            &_last-message {
              color:  $dark-theme-subtitle-color;
            }
          }
          &_status {
            &-read {
              color:  $dark-theme-subtitle-color;
            }
          }
        }

        & .MuiTabScrollButton-root .MuiSvgIcon-root {
          color: $dark-theme-title-color;
        }
      }

      &-chanels {
      }

      &-contacts {
      }
    }

    &__footer {
      border-color: #7c7c7c;

      &_container {
        & .MuiTabs-flexContainer {
          justify-content: space-between !important;
        }

        & .MuiButtonBase-root:not(.Mui-selected) {
          color:  $dark-theme-title-color;

          & .MuiSvgIcon-root {
            color:  $dark-theme-title-color;
          }
        }
      }
    }
  }

  &-messages {
    background-color: $dark-theme-background-color;
    border-color: #7c7c7c;

    &__header {
      border-color:#7c7c7c;

      &-info {
        &_name {
          color: $dark-theme-title-color;
        }
      }

      &-actions {
        & .MuiSvgIcon-root {
          color: $dark-theme-title-color;
        }
      }
    }

    &__body {
      &-terms {
        color: $dark-theme-title-color;
      }

      &-date {
        border-color: #7c7c7c;

        & p {
          color: $dark-theme-title-color;
          border-color: #7c7c7c;
          background-color: $dark-theme-darker-background-color;
        }
      }

      &-content {

        &_friend {
          &-message {

            &_text {
              background-color: $dark-theme-friend-chat-background;
              & p {
                color: $dark-theme-title-color;
              }

              & span {
                color: $dark-theme-subtitle-color;
              }
            }
          }

        }


        &_own {

          &-message {
            &_text {
              background-color: $dark-theme-own-chat-background;

              & p {
                color: $dark-theme-title-color;
              }

              & span {
                color: $dark-theme-subtitle-color;
              }
            }

          }

        }
      }

      &-footer {
        &_inputs {
          &-emoji {
            & label {
              color: $dark-theme-title-color;
            }
          }

          &-text {
            & .MuiInputBase-input {
              color: $dark-theme-title-color;
            }
          }

          &-upload {
            & label {
              color: $dark-theme-title-color;
            }

          }
        }
      }
    }
  }

}
