@import "../../../styles/sass/variables.scss";

.work-drive-page {
  width: 100%;
  padding-bottom: 10px;
  // height: 65vh;
  // height: calc(100% - 90px);
  height: 100%;

  & .MuiGrid-container {
    margin: 0;
  }

  & .MuiGrid-item {
    padding-top: 0 !important;
  }

  & .MuiGrid-root {
    height: 100%;
  }

  &_sidebar {
    padding: 0 5px 10px 5px;
    display: flex;
    flex-direction: column;
    gap: 20px;
    height: 100%;
    justify-content: space-between;

    &_actions {
      &_create-event {
        &_btn.MuiButtonBase-root {
          font-size: 16px;
          font-weight: 400;
          background-color: #4382C4;
          width: 100%;
          padding: 10px;
          text-align: center;
          color: #fff;
          border-radius: 8px;
          text-transform: none;
          height: 44px;
          box-sizing: border-box;
        }

        &_btn.MuiButtonBase-root:hover {
          background-color: #4382C4;
        }

        &_list {
          .MuiButtonBase-root {
              width: 100%;
              padding: 10px;
              border-radius: 8px;
            }
        }
      }

      &-section {
        margin-top: 15px;

          &_drive-tree {
              max-height: 300px;
              overflow: auto;

              & .MuiTreeItem-content {
                  box-sizing: border-box;
                  border-radius: 8px;
                  height: 40px;
              }

              & .MuiTreeItem-content {
                .blue-icon {
                  display: none;
                }
              }

              & .Mui-selected.Mui-focused {

                .blue-icon {
                  display: inline-block;
                }

                .gray-icon {
                  display: none;
                }

                .MuiTreeItem-iconContainer {
                  color: #4382C4;
                }

                .MuiListItemIcon-root {
                  color: #4382C4;
                }

                .MuiListItemText-root {
                  color: #4382C4;

                  .MuiTypography-root {
                    font-weight: 600;
                  }
                }
              }
              &_item {
                  display: flex;
                  padding: 8px 0;
                  align-items: center;

                  & .MuiListItemIcon-root {
                      min-width: 30px;
                  }

                  & .MuiListItemText-root {
                    color: #414141;
                  }
              }
          }

          &_drive-actions {

              & .MuiButtonBase-root {
                  box-sizing: border-box;
                  border-radius: 8px;
              }
              &_item.MuiButtonBase-root {
                  display: flex;
                  padding: 8px;
                  align-items: center;
                  min-height: unset;
                  height: 40px;

                  & .MuiListItemIcon-root {
                      min-width: 30px;
                  }

                  .MuiTypography-root {
                    color: #414141;
                  }
              }
          }
      }
    }

    &_space {
      padding-bottom: 15px;
      display: flex;
      flex-direction: column;
      gap: 10px;

      &-progressbar.MuiLinearProgress-root  {
        height: 10px;
        border-radius: 5px;
        background-color: #4382C433;

        .MuiLinearProgress-bar {
          background-color: #4382C4;
          border-radius: 5px;
        }
      }

      &-text {
        color: #4382C4;
        font-size: 12px;

        b {
          font-weight: 500;
        }
      }
    }
  }

  &_main {
    height: 100%;
    padding: 0 5px 0 15px;
    box-sizing: border-box;

    &_list {
        width: 100%;
        height: 100%;
        background-color: #ffffff;
        border-radius: 8px;

        &-header {
          display: flex;
          position: relative;
          font-size: 14px;
          height: 40px;
          align-items: center;

          & div:nth-child(1) {
            padding: 2px 4px;
            z-index: 20;
            font-size: 1.1em;
            box-sizing: border-box;
          }

          & div:nth-child(2) {
            flex: 1 1 300px;
            z-index: 20;
            overflow: hidden;
            box-sizing: border-box;
            white-space: nowrap;
            padding-left: 8px;
            text-overflow: ellipsis;
          }

          & div:nth-child(3) {
            flex: 0 1 120px;
            padding: 2px 6px;
            z-index: 20;
            overflow: hidden;
            font-size: 14px;
            box-sizing: border-box;
            white-space: nowrap;
            font-size: 15px;
            color: #333;
            font-weight: 500;
            text-align: left;
          }

          & div:nth-child(4) {
            flex: 0 1 190px;
            padding: 2px 6px;
            z-index: 20;
            overflow: hidden;
            font-size: 14px;
            box-sizing: border-box;
            white-space: nowrap;
            text-align: left;
            padding-right: 25px;
            font-size: 15px;
            color: #333;
            font-weight: 500;
          }
        }

        &-filters {
          display: flex;
          justify-content: space-between;
          padding: 5px 10px;
          // border-bottom: 1px solid #D1CFCD;
          // padding-bottom: 0;
          box-shadow: rgba(0, 0, 0, 0.12) 0px -1px 0px inset;

          &-refresh {
            display: flex;
            align-items: center;
            // padding-top: 4px;
            gap: 10px;
            padding-left: 10px;

            img {
              width: 20px;
              height: 20px;
            }

            span {
              display: inline-block;
              padding-top: 2px;
            }

          }


          .chonky-toolbarLeft {
            display: none;
          }
        }
    }
  }
}

.secondPopupShow .work-drive-page {
  height: calc(100% - 150px);
}

.work-drive-cloud-page{
    width: 100%;
}

.cloud-page__header_work-drive-page-details {
    display: flex;
    gap: 10px;
    align-items: center;
    width: 100%;
    box-sizing: border-box;

    .MuiGrid-item {
      padding-left: 10px !important;
    }

    &_search {
        .MuiFormControl-root {
            width: 100%;
        }

        .MuiBox-root {
          padding-bottom: 0;
        }

        &-toolbar {
          display: flex;
          align-items: center;

          .chonky-toolbarRight {
            display: none;
          }

          &-close.MuiButtonBase-root {
            height: auto;
            width: auto;
            padding: 8px;
            border-radius: 50%;
          }

          .chonky-toolbarWrapper {
            width: 100%;

            .chonky-toolbarContainer {
              .chonky-toolbarRight {
                padding-bottom: 0;
              }
            }
          }
        }

        &-input {
            border: unset;
            border-radius: 8px;
            padding: 5px 10px;
            // width: 300px;
            // max-width: 350px;
            background-color: rgba(255, 255, 255, 0.7);
            color: #3C3C43B2;
            height: 40px;

            &:placeholder{
                color: red;
            }

            &::before, &::after {
                display: none;
            }
        }
    }

}

.work-drive-page_sidebar_actions_create-event_list {
  .MuiPaper-root {
    max-width: 253.3px;

    .MuiList-root {
      .MuiButtonBase-root  {
        .MuiListItemText-root {
          color: #414141;
        }

        .MuiListItemIcon-root {
          min-width: unset;
          margin-right: 8px;
        }
      }
    }
  }

}



