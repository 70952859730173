.isnot-home .email-popup {
  width: max-content;
  position: relative;
}

.email-popup {
    width: auto;
    display: flex;
    z-index: 3;
    flex-direction: row;
    // height: 100%;
    // height: calc(100% - 190px);
    height: calc(100% - 135px);
    max-height: 90vh;
    background-color: #ffffff;
    border-radius: 18px;
    position: fixed;
    transition: all 0.3s ease;
    right: 20px;
    // top: 69px;
    top: 90px;
    box-sizing: border-box;
    // box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.15);
    filter: drop-shadow(0px 4px 20px rgba(0, 0, 0, 0.15));

    &-list {
      width: 380px;
      height: 100%;
      display: flex;
      flex-direction: column;

      &__header {
        padding: 20px;
        display: flex;
        justify-content: space-between;
        border-bottom: 1px solid #ececec;
        align-items: center;
        height: 80px;
        box-sizing: border-box;

        &-title {
          font-size: 20px;
          color: #000000;
        }

        &-actions {
          display: flex;
        }
      }

      &__body {
        overflow: hidden;
        height: calc(100% - 80px);
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        &-create {
          padding: 30px 30px 0 !important;
          margin-bottom: 22px;

          &_btn {
            width: 100%;
            justify-content: left !important;
            padding: 20px 15px !important;
            color: #51A3FF !important;
            border-radius: 14px !important;
            text-transform: unset !important;
            border: 1px solid #CFCFCF !important;
            font-size: 14px !important;
            font-weight: 500 !important;
          }
        }

        &-messages {
          height: 99%;
          padding: 0 15px !important;
          gap: 15px;
          display: flex;
          flex-direction: column;
          box-sizing: border-box;


          &_container {
            // height: 95%;
            height: calc(100% - 85px);

            & .MuiBox-root {
              padding: 0;
              height: 100%;
            }
            & .MuiTypography-root {
              height: 100%;
            }
          }

          &_list {
            height: 100%;
            display: flex;
            flex-direction: column;
            // gap: 20px;

            & .MuiTabs-flexContainerVertical {
              // gap: 20px;
            }

            .MuiTabScrollButton-root {
              display: none;
            }
          }

          &_item {
            width: 100%;
            display: flex !important;
            justify-content: space-between !important;
            align-items: center !important;
            gap: 15px;
            text-transform: unset !important;
            flex-direction: row !important;
            padding: 20px 15px !important;
            border-bottom: 1px solid #ECECEC !important;
            height: 80px;

            &:hover {
              background-color: #0000000D;
              border-radius: 14px;
            }

            &.Mui-selected {
              color: #00000099 !important;
            }

            &_content {
              overflow: hidden;
              text-align: left;

              &_name {
                font-size: 15px;
                color: #000000E5;
                font-weight: 500;

                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                box-sizing: border-box;
                margin-bottom: 8px;
              }

              &-message {
                font-size: 14px;
                font-weight: 400;
                color: #6e6f6f;

                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                box-sizing: border-box;
              }
            }

            &_status {
              display: flex;
              gap: 5px;

              img {
                width: 15px;
                height: 15px;
              }

              p {
                width: max-content;
              }
            }
          }
        }

      }
    }


    &-messages {
      width: 380px;
      height: 100%;
      background-color: #fff;
      border-left: 1px solid #ECECEC;
      border-radius: 0 18px 18px 0;

      &-tabpanel {
        height: 100%;
        & .MuiBox-root {
          padding: 0 !important;
          height: 100%;
        }
        & .MuiTypography-root  {
          height: 100%;
        }
      }

      &__header {
        padding: 20px 20px 20px;
        display: flex;
        justify-content: space-between;
        border-bottom: 1px solid #ececec;
        height: 80px;
        box-sizing: border-box;

        &-info {
          display: flex;
          align-items: center;
          justify-content: flex-start;
          flex-direction: row;
          gap: 10px;

          &_img {
            width: 40px;
            height: 40px;
          }

          &_name {
            font-size: 22px;
            color: #000000;
          }
        }

        &-actions {
          display: flex;
        }
      }

      &__body {
        height: calc(100% - 100px);
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        &-single-mail {
          height: 100%;
          box-sizing: border-box;
          overflow-y: auto;

          &__header {
              display: flex;
              flex-direction: column;
              // justify-content: space-between;
              padding: 30px 15px 20px;

              &-content {
                  display: flex;
                  flex-direction: row;
                  align-items: center;
                  gap: 10px;

                  &-icon {
                      width: 50px;
                      height: 50px;

                      .MuiAvatar-root {
                        width: 100%;
                        height: 100%;
                      }

                      &-name.MuiAvatar-root {
                        text-transform: capitalize;
                        background-color: #E0EFFF;
                        color: #4382C4;
                        font-size: 20px;
                        width: 100%;
                        height: 100%;
                      }
                  }

                  &-info {
                      display: flex;
                      flex-direction: column;
                      gap: 15px;

                      &_title {
                          font-size: 15px;
                          color: #333333;
                          font-weight: 500;
                      }

                      &_subject {
                        font-size: 14px;
                      }



                  }


              }

              &-details {
                display: flex;
                flex-direction: column;
                gap: 15px;
                margin-top: 30px;

                &_from {
                    font-size: 14px;
                    color: #333333;
                    display: flex;
                    align-items: center;
                    font-weight: 300;

                    span {
                      display: inline-block;
                      width: 12px;
                      height: 12px;
                      background-color: #E0EFFF;
                      border-radius: 16px;
                      margin: 0 15px;
                    }
                }

                &_to {
                    font-size: 14px;
                    color: #333333;
                    font-weight: 300;
                }
              }

              &-actions {
                  &_close {
                      cursor: pointer;
                  }
              }
          }

          &__content {
            padding: 20px;

              &-subject {
                  font-size: 15px;
                  color: #000;

                  b {
                    font-weight: 500;
                  }
              }

              &-message {
                  margin-top: 15px;
                  font-size: 15px;
                  color: #000;

                  P {
                      line-height: 2;
                  }
              }
          }
        }
      }
    }
}


// .siteWrapperLG .email-popup {

//   &-list {
//     width: 330px;


//     &__header {
//       padding: 20px;

//       &-title {
//         font-size: 20px;
//       }
//     }

//     &__body {
//       &-messages {
//         padding: 0 20px !important;

//         &_item {
//           &_header {
//               &-icon {
//                   & .MuiSvgIcon-root {
//                   font-size: 11px;
//                   }
//               }

//               &-category {
//                   font-size: 13px;
//               }

//               &-time {
//                   font-size: 12px;
//               }
//           }

//           &_content {
//               & .MuiListItemText-primary {
//                   font-size: 13px;
//               }

//               & .MuiListItemText-secondary {
//                   font-size: 12px;
//               }
//           }
//         }
//       }

//     }
//   }

//   &-messages {
//     width: 330px;

//     &__header {
//       &-info {
//         &_name {
//           font-size: 20px;
//         }
//       }
//     }

//     &__body {
//       &-date {
//         & p {
//           font-size: 12px;
//         }
//       }

//       &-content {
//         &_friend {
//           &-message {
//             &_text {
//               & p {
//                 font-size: 14px;
//               }

//               & span {
//                 font-size: 11px;
//               }
//             }
//           }

//         }


//         &_own {
//           &-message {
//             &_text {
//               & p {
//                 font-size: 14px;
//               }

//               & span {
//                 font-size: 11px;
//               }
//             }
//           }

//         }
//       }
//     }
//   }
// }

// .siteWrapperMD .email-popup {

//   &-list {
//     width: 330px;

//     &__header {
//       &-title {
//         font-size: 20px;
//       }
//     }

//     &__body {
//       &-messages {
//         &_item {
//           &_header {
//               &-icon {
//                   & .MuiSvgIcon-root {
//                   font-size: 11px;
//                   }
//               }

//               &-category {
//                   font-size: 13px;
//               }

//               &-time {
//                   font-size: 12px;
//               }
//           }

//           &_content {
//               & .MuiListItemText-primary {
//                   font-size: 13px;
//               }

//               & .MuiListItemText-secondary {
//                   font-size: 12px;
//               }
//           }
//         }
//       }

//     }
//   }

//   &-messages {
//     width: 330px;

//     &__header {
//       &-info {
//         &_name {
//           font-size: 20px;
//         }
//       }
//     }

//     &__body {
//       &-date {
//         & p {
//           font-size: 12px;
//         }
//       }

//       &-content {
//         &_friend {
//           &-message {
//             &_text {
//               & p {
//                 font-size: 14px;
//               }

//               & span {
//                 font-size: 11px;
//               }
//             }
//           }

//         }


//         &_own {
//           &-message {
//             &_text {
//               & p {
//                 font-size: 14px;
//               }

//               & span {
//                 font-size: 11px;
//               }
//             }
//           }

//         }
//       }
//     }
//   }
// }

// .siteWrapperSM .email-popup {

//   &-list {
//     width: 350px;

//     &__header {
//       &-title {
//         font-size: 20px;
//       }
//     }

//     &__body {
//       &-messages {
//         &_item {
//           &_header {
//               &-icon {
//                   & .MuiSvgIcon-root {
//                   font-size: 11px;
//                   }
//               }

//               &-category {
//                   font-size: 13px;
//               }

//               &-time {
//                   font-size: 12px;
//               }
//           }

//           &_content {
//               & .MuiListItemText-primary {
//                   font-size: 13px;
//               }

//               & .MuiListItemText-secondary {
//                   font-size: 12px;
//               }
//           }
//         }
//       }

//     }
//   }

//   &-messages {
//     width: 350px;

//     &__header {
//       &-info {
//         &_name {
//           font-size: 20px;
//         }
//       }
//     }

//     &__body {
//       &-date {
//         & p {
//           font-size: 12px;
//         }
//       }

//       &-content {
//         &_friend {
//           &-message {
//             &_text {
//               & p {
//                 font-size: 14px;
//               }

//               & span {
//                 font-size: 11px;
//               }
//             }
//           }

//         }


//         &_own {
//           &-message {
//             &_text {
//               & p {
//                 font-size: 14px;
//               }

//               & span {
//                 font-size: 11px;
//               }
//             }
//           }

//         }
//       }
//     }
//   }
// }

// .siteWrapperXS .email-popup {
//   &-list {
//     width: 350px;

//     &__header {
//       &-title {
//         font-size: 20px;
//       }
//     }

//     &__body {
//       &-messages {
//         &_item {
//           &_header {
//               &-icon {
//                   & .MuiSvgIcon-root {
//                   font-size: 11px;
//                   }
//               }

//               &-category {
//                   font-size: 13px;
//               }

//               &-time {
//                   font-size: 12px;
//               }
//           }

//           &_content {
//               & .MuiListItemText-primary {
//                   font-size: 13px;
//               }

//               & .MuiListItemText-secondary {
//                   font-size: 12px;
//               }
//           }
//         }
//       }

//     }
//   }

//   &-messages {
//     width: 350px;

//     &__header {
//       &-info {
//         &_name {
//           font-size: 20px;
//         }
//       }
//     }

//     &__body {
//       &-date {
//         & p {
//           font-size: 12px;
//         }
//       }

//       &-content {
//         &_friend {
//           &-message {
//             &_text {
//               & p {
//                 font-size: 14px;
//               }

//               & span {
//                 font-size: 11px;
//               }
//             }
//           }

//         }


//         &_own {
//           &-message {
//             &_text {
//               & p {
//                 font-size: 14px;
//               }

//               & span {
//                 font-size: 11px;
//               }
//             }
//           }

//         }
//       }
//     }
//   }
// }

.dark .email-popup {
  background-color: $dark-theme-background-color;

  &-list {
    &__header {
      border-color: #7c7c7c;

      &-title {
        color: $dark-theme-title-color;
      }

      &-actions {
        & .MuiSvgIcon-root {
          color: $dark-theme-title-color;
        }
      }
    }

    &__body {
      &-messages {
        &_item {
          border-color: #7c7c7c;

          &_header {
              &-icon {
                  & .MuiSvgIcon-root {
                  color: #51a3ff;
                  }
              }

              &-category {
                  color: #51a3ff;
              }

              &-time {
                  color: $dark-theme-subtitle-color;
              }
          }

          &_content {
            & .MuiListItemText-primary {
                color: $dark-theme-title-color;
            }

            & .MuiListItemText-secondary {
                color: $dark-theme-subtitle-color;
            }
          }
        }
      }
    }
  }
}
