.isnot-home .note-popup {
  width: max-content;
  position: relative;
}

.note-popup {
    width: auto;
    display: flex;
    z-index: 3;
    flex-direction: row;
    // height: 100%;
    // height: calc(100% - 190px);
    height: calc(100% - 135px);
    max-height: 90vh;
    background-color: #ffffff;
    border-radius: 18px;
    position: fixed;
    transition: all 0.3s ease;
    right: 20px;
    // top: 69px;
    top: 90px;
    box-sizing: border-box;
    // box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.15);
    filter: drop-shadow(0px 4px 20px rgba(0, 0, 0, 0.15));

    &-list {
      width: 380px;
      height: 100%;
      display: flex;
      flex-direction: column;

      &__header {
        padding: 20px 25px;
        display: flex;
        justify-content: space-between;
        border-bottom: 1px solid #ececec;
        align-items: center;
        height: 80px;
        box-sizing: border-box;

        &-title {
          font-size: 20px;
          color: #000000;
        }

        &-actions {
          display: flex;
        }
      }

      &__body {
        padding: 30px 15px 20px 15px;
        height: calc(100% - 80px);
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        &-create {
          padding: 0 15px;
          margin-bottom: 10px;

            &_btn {
                width: 100%;
                justify-content: left !important;
                padding: 20px 15px !important;
                color: #51A3FF !important;
                border-radius: 14px !important;
                text-transform: unset !important;
                border: 1px solid #CFCFCF !important;
                font-size: 14px !important;
                font-weight: 500 !important;
            }
        }

        &-messages {
          height: 100%;

          &_container {
            height: 95%;

            & .MuiBox-root {
              padding: 0;
              height: 100%;
            }
            & .MuiTypography-root {
              height: 100%;
            }
          }

          &_list {
            height: 100%;
            display: flex;
            flex-direction: column;

            & .MuiTabs-flexContainer  {
              flex-direction: column-reverse;
            }
          }

          &_item {
            width: 100%;
            display: flex !important;
            justify-content: space-between !important;
            align-items: center !important;
            gap: 15px;
            text-transform: unset !important;
            flex-direction: row !important;
            padding: 20px 15px !important;
            border-bottom: 1px solid #ECECEC !important;
            border-radius: 15px !important;

            &:hover {
              background-color: #F2F2F2;
            }

            &_content {
              overflow: hidden;
              text-align: left;

              &_name {
                font-size: 15px;
                color: #000000E5;
                font-weight: 500;

                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                box-sizing: border-box;
                margin-bottom: 6px;
              }

              &_last-message {
                font-size: 14px;
                font-weight: 400;
                color: #6e6f6f;

                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                box-sizing: border-box;
              }
            }

            &_actions {
              transition: all 0.3s ease;
              display: none;

              &-edit {
                & button {
                  padding: 2px !important;
                }
              }

              &-delete {
                & button {
                  padding: 2px !important;
                }
              }
            }

            &:hover &_actions {
              display: flex;
              gap: 10px;
            }
          }

          &_item:first-child {
            border: unset !important;
          }
        }

      }
    }



    &-messages {
      width: 380px;
      height: 100%;
      background-color: #fff;
      border-left: 1px solid #ECECEC;
      border-radius: 0 18px 18px 0;

      &-tabpanel {
        height: 100%;

        & .MuiBox-root {
          padding: 0 !important;
          height: 100%;
        }
        & .MuiTypography-root {
          height: 100%;
        }
      }

      &__header {
        padding: 20px;
        display: flex;
        justify-content: space-between;
        border-bottom: 1px solid #ececec;
        height: 80px;
        box-sizing: border-box;

        &-info {
          display: flex;
          align-items: center;
          justify-content: flex-start;
          flex-direction: row;
          gap: 10px;

          &_name {
            font-size: 22px;
            color: #000000;
          }
        }

        &-actions {
          display: flex;
        }
      }

      &__body {
        height: calc(100% - 80px);
        padding-bottom: 30px;
        position: relative;
        display: flex;
        flex-direction: column;
        box-sizing: border-box;

        &-date {
          text-align: center;
          padding: 15px 0;
          height: 20px;

          & p {
            font-size: 14px;
            font-weight: 400;
            color: #6E6F6F;
          }
        }

        &-content {
          padding: 20px;
          height: calc(100% - 50px);
          overflow-y: auto;

          &_text {
            height: 100%;

            &-area {
              width: 100%;
              height: 100%;

              & fieldset {
                border: unset;
                padding: 0;
              }

              & .MuiInputBase-root {
                padding: 0 !important;
                align-items: start !important;
                height: 100%;
              }

              & textarea {
                height: 100%;
              }
            }

            &-title {
              width: 100%;
              height: auto;
              color: #363C65;
              border-radius: 8px;
              outline: none;
              width: 100%;
              box-sizing: border-box;
              background-color: #F8FAFC;
              transition: 0.2s;
              border: 1px solid #ECECEC;
              padding: 10px 8px;
              font-size: 15px;

              & .MuiInputBase-root {
                border-radius: 8px;
              }

              & .MuiInput-underline:after {
                border-bottom-color: green;
              }

              & .MuiOutlinedInput-root {
                & fieldset {
                  border-color: #ECECEC;
                }

                &:hover fieldset {
                 border-color: $blue;
                }

                &.Mui-focused fieldset {
                  border-color: $blue;
                }

                & textarea {
                  height: 100%;
                }
              }
            }

            &-message {
              width: 100%;
              height: calc(100% - 100px);

              & fieldset {
                border: unset;
                padding: 0;
              }

              & .MuiInputBase-root {
                padding: 20px 0 !important;
                align-items: start !important;
                height: 100%;
              }

              & textarea {
                height: 100%;
              }
            }
          }
        }

        &-submit {
          text-align: right;
          padding: 0 30px;
        }

      }
    }

}


// .siteWrapperLG .note-popup {
//   &-list {
//     width: 330px;

//     &__header {
//       padding: 20px;

//       &-title {
//         font-size: 20px;
//       }
//     }

//     &__body {
//       padding: 20px;

//       &-create {
//           &_btn {
//               padding: 15px 12px !important;
//           }
//       }

//       &-messages {
//         &_item {
//           &_content {
//             &_name {
//               font-size: 14px;
//             }

//             &_last-message {
//               font-size: 13px;
//             }
//           }
//         }
//       }

//     }
//   }

//   &-messages {
//     width: 330px;

//     &__header {
//       padding: 20px;

//       &-info {
//         &_name {
//           font-size: 20px;
//         }
//       }
//     }

//     &__body {
//       &-date {
//         & p {
//           font-size: 13px;
//         }
//       }

//       &-content {
//         padding: 20px;

//         &_text {
//           height: 100%;

//           &-title {
//             & textarea {
//               font-size: 14px;
//             }
//           }

//           &-message {
//             & textarea {
//               font-size: 14px;
//             }
//           }
//         }
//       }
//     }
//   }
// }

// .siteWrapperMD .note-popup {
//   &-list {
//     width: 330px;

//     &__header {
//       &-title {
//         font-size: 20px;
//       }
//     }

//     &__body {
//       &-create {
//           &_btn {
//               padding: 15px 12px !important;
//           }
//       }

//       &-messages {
//         &_item {
//           &_content {
//             &_name {
//               font-size: 14px;
//             }

//             &_last-message {
//               font-size: 13px;
//             }
//           }
//         }
//       }

//     }
//   }

//   &-messages {
//     width: 330px;

//     &__header {
//       &-info {
//         &_name {
//           font-size: 20px;
//         }
//       }
//     }

//     &__body {
//       &-date {
//         & p {
//           font-size: 13px;
//         }
//       }

//       &-content {
//         &_text {
//           height: 100%;

//           &-title {
//             & textarea {
//               font-size: 14px;
//             }
//           }

//           &-message {
//             & textarea {
//               font-size: 14px;
//             }
//           }
//         }
//       }
//     }
//   }
// }

// .siteWrapperSM .note-popup {
//   &-list {
//     width: 350px;

//     &__header {
//       &-title {
//         font-size: 20px;
//       }
//     }

//     &__body {
//       &-create {
//           &_btn {
//               padding: 15px 12px !important;
//           }
//       }

//       &-messages {
//         &_item {
//           &_content {
//             &_name {
//               font-size: 14px;
//             }

//             &_last-message {
//               font-size: 13px;
//             }
//           }
//         }
//       }

//     }
//   }

//   &-messages {
//     width: 350px;

//     &__header {
//       &-info {
//         &_name {
//           font-size: 20px;
//         }
//       }
//     }

//     &__body {
//       &-date {
//         & p {
//           font-size: 13px;
//         }
//       }

//       &-content {
//         &_text {
//           height: 100%;

//           &-title {
//             & textarea {
//               font-size: 14px;
//             }
//           }

//           &-message {
//             & textarea {
//               font-size: 14px;
//             }
//           }
//         }
//       }
//     }
//   }
// }

// .siteWrapperXS .note-popup {
//   &-list {
//     width: 350px;

//     &__header {
//       &-title {
//         font-size: 20px;
//       }
//     }

//     &__body {
//       &-create {
//           &_btn {
//               padding: 15px 12px !important;
//           }
//       }

//       &-messages {
//         &_item {
//           &_content {
//             &_name {
//               font-size: 14px;
//             }

//             &_last-message {
//               font-size: 13px;
//             }
//           }
//         }
//       }

//     }
//   }

//   &-messages {
//     width: 350px;

//     &__header {
//       &-info {
//         &_name {
//           font-size: 20px;
//         }
//       }
//     }

//     &__body {
//       &-date {
//         & p {
//           font-size: 13px;
//         }
//       }

//       &-content {
//         &_text {
//           height: 100%;

//           &-title {
//             & textarea {
//               font-size: 14px;
//             }
//           }

//           &-message {
//             & textarea {
//               font-size: 14px;
//             }
//           }
//         }
//       }
//     }
//   }
// }

.dark .note-popup {
  background-color: $dark-theme-background-color;

  &-list {
    &__header {
      border-color:#7c7c7c;

      &-title {
        color: $dark-theme-title-color;
      }

      &-actions {
        & .MuiSvgIcon-root  {
          color: $dark-theme-title-color;
        }
      }
    }

    &__body {
      &-messages {
        &_item {
          border-color: #7c7c7c !important;

          &_content {
            &_name {
              color: $dark-theme-title-color;
            }

            &_last-message {
              color: $dark-theme-subtitle-color;
            }
          }

          &_actions {
            & .MuiSvgIcon-root {
              color: $dark-theme-title-color;
            }
          }
        }
      }

    }
  }

  &-messages {
    background-color: $dark-theme-background-color;
    border-color:#7c7c7c;

    &__header {
      border-color: #7c7c7c;

      &-info {
        &_name {
          color: $dark-theme-title-color;
        }
      }
      &-actions {
        & .MuiSvgIcon-root {
          color: $dark-theme-title-color;
        }
      }
    }

    &__body {
      &-date {
        & p {
          color: $dark-theme-title-color;
        }
      }

      &-content {
        &_text {
          &-title {
            color: $dark-theme-title-color;
            background-color: $dark-theme-darker-background-color;
            border-color: #7c7c7c;

            & .MuiOutlinedInput-root {
              &:hover fieldset {
               border-color: $dark-theme-title-color;
              }

              &.Mui-focused fieldset {
                border-color: $dark-theme-title-color;
              }

              & textarea {
                color: $dark-theme-title-color;
              }
            }
          }

          &-message {
            & textarea {
              color: $dark-theme-title-color;
            }
          }
        }
      }
    }
  }

}
