@import './variables.scss';

html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong,tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0 ;
  padding: 0 ;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block;
}
html {
  width:100%;
  color:black;
  font-size: 15px;
  height: 100%;
}
body {
  line-height: 1;
  letter-spacing: .0rem;
  -webkit-text-size-adjust: none;
  font-family: 'Poppins', sans-serif !important;
  font-weight: 300;
  font-style: normal;
  width:inherit;
  color: #363c65;
  background-color: $silver;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto !important;
  // min-width: $min-width;
}

.dark body {
  color: $dark-theme-subtitle-color;
}
body * {
  font-family: 'Poppins', sans-serif !important;
  font-weight: 400;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}
section {
  display: block;
}
a {
  color: $blue;
  text-decoration: none;
}
a, a img {
  outline: none;
}
.rub {
  &:before {
    content:'\20BD';
  }
}
b {
  font-weight: 600;
}
.fix, section {
  max-width: 1300px;
  margin: 0 auto;
  width:100%;
  padding: 0 30px;
  box-sizing: border-box;
}
/* For modern browsers */
.clearfix:before,
.clearfix:after {
  content: " ";
  display: table;
}
input {
  outline: none;
}
table {
  width:100%;
}
.clearfix:after {
  clear: both;
}
.clearfix {
  *zoom: 1;
}
h2, .div-h2 {
  font-size: 18px;
  margin-bottom: 8px;
  line-height: 1.3;
}
h1, .div-h1 {
  font-size: 22px;
  margin-bottom: 10px;
  line-height: 1.3;
  display: flex;
  align-items: center;
}
.siteWrapper {
  background-size: cover;
  background-repeat: no-repeat;
  // background-attachment: fixed;
  background-position: top center;
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 100vh;
  &.siteWrapperXL {
    background-image: url('../../assets/images/my-services/XLback.jpg');
  }
  &.siteWrapperLG {
    background-image: url('../../assets/images/my-services/LGback.jpg');
  }
  &.siteWrapperMD {
    background-image: url('../../assets/images/my-services/LGback.jpg');
  }
  &.siteWrapperSM {
    background-image: url('../../assets/images/my-services/LGback.jpg');
  }
  &.siteWrapperXS {
    background-image: url('../../assets/images/my-services/LGback.jpg');

    & .container {
      padding: 45px 0 20px 50px;
    }
  }
}

.cloud {
  box-shadow: 0 2px 3px rgba(60, 65, 140, 0.08);
  background-color: white;
  border-radius: $border-radius-big;
  &--h-100 {
    height: 100%;
  }
}
.container {
  // padding: 45px 70px 20px 70px;
  padding: 60px 0 20px 70px;
}
.apps-modal.MuiModal-root  {
  z-index: 2;
  top: 45px;
  bottom: 45px;
  // bottom: 100px;

  align-items: start;
  display: flex;
  justify-content: center;
  padding-top: 45px;

  left: 100px;
  right: 100px;

  transition: all 0.5s ease;

  &.is-fullpage {
    top: 5px;
    bottom: 5px;
    right: 5px;
    left: 90px;


    @media (min-width: 1600px) {
      left: 90px;
    }

    .apps-modal-container {
      max-height: 100%;
      max-width: 100%;

      .page-container {
        max-width: 100%;
      }
    }
  }
}
.apps-modal .apps-modal-container {
  // width: 90vw;
  width: 100%;
  height: 100%;
  max-width: 1600px;
  outline: none;
  box-sizing: border-box;
  max-height: 90vh;
  margin: 0 auto;
  transition: all 0.5s ease;
  // position: absolute;
  // left: 50%;
  // top: 50%;
  // transform: translate(-50%, -50%);
}

@media (min-width: 1600px) {

  .apps-modal.apps-modal-shift-one {
    position: fixed;
    right: 425px;
  }

  .apps-modal.apps-modal-shift-two {
    position: fixed;
    right: 800px;
  }
}


@media (max-width: 1600px) {
  .apps-modal.apps-modal-shift-one {
    position: fixed;
    right: 420px;
  }

  .apps-modal.apps-modal-shift-two {
    position: fixed;
    right: 800px;
  }
}

.apps-modal .MuiBackdrop-root {
  // -webkit-backdrop-filter: blur(3px);
  // backdrop-filter: blur(3px);
  background: unset;
  top: 45px;
  left: 100px;
}


.meeting-page-modal.MuiModal-root  {
  z-index: 2;
  top: 90px;
  bottom: 45px;
  left: 100px;
  right: 100px;
}

.meeting-page-modal .meeting-page-modal-container {
  width: 100%;
  height: 100%;
  max-width: 1600px;
  outline: none;
  box-sizing: border-box;
  max-height: 90vh;
  margin: 0 auto;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);

  border-radius: 16px;
  // background: rgba(223, 233, 248, 0.65);
  // box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.15);
  // backdrop-filter: blur(30px);
  background: rgb(223 233 248 / 70%);
  -webkit-backdrop-filter: blur(8px);
  backdrop-filter: blur(8px);
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.15);

  .cloud-page {
    background: unset;
    backdrop-filter: unset;
    box-shadow: unset;
  }
}

@media (min-width: 1600px) {

  .meeting-page-modal.meeting-page-modal-shift-one {
    position: fixed;
    right: 425px;
    left: 100px;
  }

  .meeting-page-modal.meeting-page-modal-shift-two {
    position: fixed;
    right: 800px;
    left: 100px;
  }
}


@media (max-width: 1600px) {

  .meeting-page-modal.meeting-page-modal-shift-one {
    position: fixed;
    right: 370px;
    left: 100px;
  }

  .meeting-page-modal.meeting-page-modal-shift-two {
    position: fixed;
    right: 700px;
    left: 100px;
  }
}

.meeting-page-modal .MuiBackdrop-root {
  // -webkit-backdrop-filter: blur(3px);
  // backdrop-filter: blur(3px);
  background: unset;
  backdrop-filter: unset;
  top: 45px;
  left: 95px;
}


.page-container {
  // padding: 70px 100px 20px 100px;
  // padding: 90px 100px 20px 100px;
  margin: auto;
  max-width: 1600px;
  width: 100%;
  height: 100%;
  transition: all 0.5s ease;
}
.popup-show .page-container {
 padding: 70px 40px 20px 100px;
}
.slider-big {
  width: 100%;
  position: relative;
  &__full-div {
    width:100%;
    height: 100%;
    background-size:cover;
    background-repeat: no-repeat;
    background-position: center;
  }
  .swiper-container:before,
  .swiper-container:after {
    position: absolute;
    top:-3px;
    height: calc(100% + 6px);
    width:200px;
    z-index: 3;
    display: block;
    content: "";
    background: rgb(255,255,255);
    background: linear-gradient(90deg, rgba(255,255,255,1) 39%, rgba(255,255,255,0) 100%);
  }
  .swiper-container:before {
    left: -75px;
  }
  .swiper-container:after {
    right: -75px;
    transform: rotate(180deg) !important;
  }
  .swiper-slide {
    width:600px;
    height: 330px;
    border-radius: $border-radius-main;
    background: white;
    overflow: hidden;
  }
  .swiper-button-next,
  .swiper-button-prev {
    width:30px;
    height: 48px;
    background-size: 15px 24px;
  }
}
.swiper-container {
  width:100%;
}
.swiper-button-next,
.swiper-button-prev {
  outline: none;
  background-image: url('../../assets/icons/arrow-right.svg');
  background-repeat: no-repeat;
  background-position: center;
}
.swiper-button-next:after,
.swiper-button-prev:after {
  display: none !important;
}
.swiper-button-prev {
  transform:rotate(180deg);
}
.swiper-pagination-bullet-active {
  background: $blue !important;
}
.swiper-pagination-bullet {
  outline: none;
}
.swiper-pagination-bullet {
  width:10px !important;
  height: 10px !important;
}
.select__indicator-separator {
  display: none;
}
.select__clear-indicator {
  display: none !important;
}
.max-width-container {
  max-width: 800px;
  margin: 0 auto;
}
.select__dropdown-indicator {
  color: $blue !important;
}
.text {
  line-height: 1.4;
}
.note {
  font-size: 12px;
}
button {
  outline: none;
  border: none;
  border-radius: 0;
  cursor: pointer;
}
button:disabled {
  cursor: default;
}

.plus-button {
  display: inline-block;
  background: url('../../assets/icons/plus.png');
  width:21px;
  height: 21px;
  vertical-align: top;
  background-repeat: no-repeat;
  background-size:contain;
}
.dashboard {
  font-size: 13px;
  line-height: 1.3;
  &__cloud {
    padding: 30px;
    position: relative;
    .info-icon {
      position: absolute;
      top: 35px;
      right: 30px;
      z-index: 2;
    }
  }
}
.link-dashed {
  display: inline-block;
  border-bottom: 1px dashed $light-blue;
  cursor: pointer;
}
.link {
  display: inline-block;
  border-bottom: 1px solid $blue;
  cursor: pointer;
}
.color {
  &-red {
    color:$red;
  }
  &-blue {
    color: $blue;
  }
  &-green {
    color: $green;
  }
}
.custom-checkbox {
  display:inline-block;
  height: 26px;
  box-sizing: border-box;
  border:2px solid #e8e8ea;
  border-radius: 13px;
  width:50px;
  position: relative;
  cursor: pointer;
  &:before {
    content: "";
    display: inline-block;
    position: absolute;
    border-radius: 100%;
    width: 18px;
    height: 18px !important;
    top: 2px;
    left: 3px;
    background: #e8e8ea;
  }
  &--active {
    &:before {
      left: auto;
      right:3px;
      background: $green;
    }
  }
  &--version-2 {
    width: 28px;
  }
}
.site-tab-toolbar {
  border-bottom: 1px solid $delimiter;
  display: flex;
  margin-bottom: $space;
  &__option {
    height: 30px;
    line-height: 30px;
    font-size: 16px;
    padding-left: $space;
    padding-right: $space;
    cursor: pointer;
    position: relative;
    &:hover {
      color: $blue;
    }
    &--active {
      cursor: default;
      color: $blue;
      &:after {
        content: "";
        position: absolute;
        bottom: 0;
        height: 1px;
        background-color: $blue;
        left:0;
        width: 100%;
      }
    }
  }
}
.site-segmented-control {
  background-color: $silver;
  display: flex;
  border-radius: $border-radius-second;
  overflow: hidden;
  &__option {
    height: 30px;
    line-height: 30px;
    font-size: 16px;
    padding-left: $space;
    padding-right: $space;
    text-align: center;
    cursor: pointer;
    position: relative;
    flex: 1;
    transition: 0.2s;
    &:hover {
      color: white;
      background-color: $blue;
    }
    &--active {
      cursor: default;
      background-color: $blue;
      color: white;
    }
  }
}
.mt-1 {
  margin-top: $space;
}
.mt-2 {
  margin-top: 2*$space;
}
.mr-1 {
  margin-right: $space;
}
.mr-2 {
  margin-right: 2*$space;
}
.ml-1 {
  margin-left: $space;
}
.ml-2 {
  margin-left: 2*$space;
}
.site-vertical-separator {
  height: 2*$space;
}
.site-horizontal-separator {
  height: auto;
  width: 2*$space;
}
.site-input-checkbox {
  border:2px solid #e8e8ea;
  height: 26px;
  display:inline-block;
  border-radius: 100%;
  width:26px;
  box-sizing: border-box;
  cursor: pointer;
  position: relative;
  vertical-align: middle;
  margin-right: 8px;
  &:before {
    content: "";
    display: inline-block;
    position: absolute;
    border-radius: 100%;
    width: 18px;
    height: 18px !important;
    top: 2px;
    left: 2px;
    background: transparent;
  }
  &--active {
    &:before {
      left: auto;
      right:2px;
      background: $green;
    }
  }
}
.info-icon {
  span {
    border-bottom: 1px dashed #c2d8ec;
    cursor: pointer;
    display: inline-block;
    margin-right: 5px;
    vertical-align: middle;
    color: $blue;
  }
  &:after {
    content: "";
    width:21px;
    height: 21px;
    background-image: url('../../assets/icons/info.png');
    background-size: 100% 100%;
    background-position: center;
    background-repeat: no-repeat;
    display: inline-block;
    cursor: pointer;
    vertical-align: middle;
  }
}
.site-input {
  font-size: 15px;
  color: inherit;
  border-radius: $border-radius-second;
  height: 30px;
  line-height: 30px;
  display: block;
  outline: none;
  width:100%;
  box-sizing: border-box;
  background-color: transparent;
  transition: 0.2s;
  border: 1px solid $delimiter;
  padding: 0 10px;
  &::placeholder {
    color: rgba(0,0,0,0.3);
  }
  &:focus {
    border-color: $blue;
  }
  &.error {
    border-color: $red;
  }
  &--big {
    border: 2px solid $blue;
    border-radius: $border-radius-main;
    height: 60px;
    line-height: 60px;
    padding-left: 40px;
    font-size: 18px;
    font-weight: 100;
  }
}
.select {
  &__control {
    border-radius: $border-radius-second !important;
    border-color: $delimiter !important;
    min-height: 30px !important;
  }
  &__value-container {
    padding: 0 $space !important;
  }
  &__indicator {
    padding: 0 $space !important;
  }
}
.site-textarea {
  font-size: 15px;
  color: inherit;
  border-radius: 2px;
  display: block;
  outline: none;
  width:100%;
  box-sizing: border-box;
  background-color: transparent;
  transition: 0.2s;
  border: 1px solid $delimiter;
  padding: 6px 10px;
  min-height:42px;
  resize: none;
  &.error {
    border-color: $red;
  }
  &--min-rows-2 {
    min-height: 58px;
  }
}
.site-input-label {
  font-size: 15px;
  line-height: 1.3;
  display:block;
  padding-bottom:5px;
}
.site-input-error-label {
  font-size: 13px;
  line-height: 1.3;
  display:block;
  color: $red;
  padding-top:5px;
}
.site-table {
  tr:nth-of-type(1) {
    font-weight:bold;
  }
  td {
    padding-top: 15px;
    text-align: center;
    padding-bottom: 15px;
    border-bottom:1px solid $delimiter;
    &:nth-of-type(1) {
      padding-left: 1 * $space;
    }
  }
  &--first-td-text-left {
    tr td:nth-of-type(1) {
      text-align: left;
    }
  }
  &__tr {

    &--silver {
      background-color: $silver;
    }
    &--border-none {
      td {
        border-bottom: none;
      }
    }
  }
}
.btn {
  font-size: 15px;
  color:white;
  border-radius: 30px;
  text-align: center;
  display: inline-block;
  white-space: nowrap;
  outline: none;
  border: none;
  background-color: $blue;
  padding: 10px 24px;
  box-sizing: border-box;
  transition: 0.2s;
  cursor: pointer;
  &:disabled {
    opacity: 0.5 !important;
    cursor: default !important;
  }
  &--blue-text {
    background-color: transparent;
    color: $blue;
    &:hover {
      background-color: $light-blue;
      opacity: 1;
    }
  }
  &:hover {
    opacity: 0.8;
  }
  &--orange {
    background-color: $orange;
  }
  &--green {
    background-color: $green;
    color:black;
  }
  &--light-blue {
    background-color: $light-blue;
    color: $blue;
  }
  &--big {
    height: 60px;
    line-height: 60px;
    font-size: 18px;
    border-radius: $border-radius-main;
    padding-left:30px;
    padding-right: 30px;
  }
  &--transparent-dashed {
    background: transparent;
    border:1px dashed $light-blue;
    color: $blue;
    line-height: 38px;
  }
  &--transparent-dashed-orange {
    background: transparent;
    color: $orange;
    border:1px dashed $orange;
    line-height: 38px;
  }
  &--transparent-blue {
    background-color: transparent;
    color: $blue;
    border:1px solid $blue;
    line-height: 28px;
  }
  &--with-mouse {
    padding-right:30px;
    &:before {
      content: "";
      background-image: url('../../assets/icons/mouse-white.png');
      width: 12px;
      height: 12px;
      background-size: 100%;
      background-repeat: no-repeat;
      display: inline-block;
      vertical-align: middle;
      position: relative;
      margin-bottom: 4px;
      margin-right: 10px;
    }
  }
  &--w-100 {
    width:100%;
  }
  &--with-right {
    padding-left:30px;
    &:after {
      content: "";
      background-image: url('../../assets/icons/arrow-right-white.svg');
      width: 7px;
      height: 11px;
      background-size: 100%;
      background-repeat: no-repeat;
      display: inline-block;
      vertical-align: middle;
      position: relative;
      margin-left: 10px;
    }
  }
  &--with-left {
    padding-right:30px;
    &:before {
      content: "";
      background-image: url('../../assets/icons/arrow-right-white.svg');
      width: 7px;
      height: 11px;
      background-size: 100%;
      background-repeat: no-repeat;
      display: inline-block;
      vertical-align: middle;
      position: relative;
      transform: rotate(180deg);
      margin-right: 10px;
      margin-bottom: 3px;
    }
  }
  &--with-pencil {
    padding-right:30px;
    &:before {
      content: "";
      background-image: url('../../assets/icons/pencil.svg');
      width: 16px;
      height: 16px;
      background-size: contain;
      background-repeat: no-repeat;
      display: inline-block;
      vertical-align: middle;
      position: relative;
      margin-right: 10px;
    }
  }
}
.layoutLG .btn {
  font-size: 14px;
}
.layoutMD .btn {
  font-size: 13px;
  padding: 8px 18px;
}
.layoutXS .btn {
  font-size: 13px;
  padding: 6px 15px;
}
.btn-outline {
  font-size: 15px;
  color:$blue;
  border-radius: 30px;
  text-align: center;
  display: inline-block;
  white-space: nowrap;
  outline: none;
  border: 1px solid $blue;
  background-color: #FFF;
  padding: 10px 24px;
  box-sizing: border-box;
  transition: 0.2s;
  cursor: pointer;
  &:disabled {
    opacity: 0.5 !important;
    cursor: default !important;
  }
  &:hover {
    opacity: 0.8;
  }
}
.layoutLG .btn-outline {
  font-size: 14px;
}
.layoutMD .btn-outline {
  font-size: 13px;
  padding: 8px 18px;
}
.layoutXS .btn-outline {
  font-size: 13px;
  padding: 6px 15px;
}
.layout {
  &__table-1-3 {
    table-layout: fixed;
    &_td-1 {
      width: calc(34%);
      vertical-align: top;
    }
    &_td-2 {
      width: calc(66%);
      vertical-align: top;
    }
  }
  &__flex {
    display: flex;
    &--3-cols-margin {
      div {
        flex: 1 1 auto;
        margin: 8px;
        &:nth-of-type(1) {
          margin-left:0;
        }
        &:nth-of-type(3) {
          margin-right: 0;
        }
      }
    }
    &_col {
      flex: 1 1 auto;
    }
    &--2-cols-margin {
      div {
        flex: 1 1 auto;
        margin: 8px;
        &:nth-of-type(1) {
          margin-left:0;
        }
        &:nth-of-type(2) {
          margin-right: 0;
        }
      }
    }
    &_50 {
      width:50%;
    }
    &_33 {
      width:33.33%;
    }

  }
}
.table-layout-50 {
  td {
    width:50%;
  }
}
.inline-block {
  display:inline-block;
}
.display-table {
  display:table;
  width: 100%;
}
.display-table-cell {
  display:table-cell;
  vertical-align:middle;
}
.be-loading-full-size {
  width:100vw;
  height: 100vh;
  position: fixed;
  top:0;
  left:0;
}
.be-screen-silver {
  background: $silver;
}
.text-red {
  color: $red;
}
.text-blue {
  color: $blue;
}
.text-orange {
  color: $orange
}
.text-green {
  color: $green;
}
.text-gray {
  color: $gray;
}
.error-container {
  text-align: center;
  padding-top:100px;
  &__number {
    font-size:80px;
    color: $blue;
    margin-bottom:30px;
    font-weight: bold;
  }
  &__description {
    font-size: 15px;
  }
  &__back {
    margin: 20px 0;
  }
}
.text-danger {
  color: $red;
}
.popup-tab {
  z-index:12;
  position: relative;
  opacity: 0;
  transition: opacity 0.3s;
  margin: 20px;
  width: calc(100% - 40px);
  height: calc(100% - 100px);
  &__iframe {
    width:100%;
    height: calc(100vh - 140px);
    min-height: 100%;
  }
}
// .popup {
//   z-index: 2001;
//   position: relative;
//   opacity: 0;
//   transition: opacity 0.3s;
//   min-width: 350px;
//   &__close-button {
//     position: absolute;
//     top: -35px;
//     right: 0;
//     width: 25px;
//     height: 25px;
//     background-image: url('../../assets/icons/close.svg');
//     background-size: cover;
//     background-position: center;
//     cursor: pointer;
//     display: none;
//   }
//   &__form-group {
//     margin-bottom: 20px;
//     &--more {
//       margin-bottom:40px;
//     }
//   }
//   &__title {
//     font-size: 20px;
//     padding-bottom: 30px;
//   }
//   &__subtitle {
//     font-size:18px;
//     padding-bottom: 20px;
//   }
//   &__text {
//     line-height: 1.3;
//     font-size: 16px;
//   }
//   &__submit-holder {
//     margin-top:50px;
//     text-align: right;
//   }
//   &__container-flex {
//     display: flex;
//   }
//   &__inner {
//     padding: 2 * $space;
//     overflow: hidden;
//     border-radius: $border-radius-main;
//     background-color: white;
//   }
//   &__container-flex_col-1 {
//     width:400px;
//     padding-right: 30px;
//     border-right: 1px solid $delimiter;
//   }
//   &__container-flex_col-2 {
//     width:300px;
//     padding-left: 30px;
//     box-sizing: border-box;
//   }
// }
.be-modal-open {
  .modal-backdrop {
    display: block !important;
  }
  .popup__close-button {
    display: inline-block !important;
  }
  .modal-open {
    overflow: hidden;
  }
}
.modal-backdrop {
  display: none;
  width:100vw;
  height: 100vh;
  background: rgba(50,50,50,.7);
  position: fixed;
  z-index: 2000;
  top:0;
  left:0;
}
.row {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  justify-content: center;
  &--space-between {
    justify-content: space-between;
  }
  &--center {
    align-items: center;
  }
  &--nowrap {
    flex-wrap: nowrap;
  }
}
.col {
  &-12 {
    max-width: 100%;
    flex: 0 0 100%;
    box-sizing: border-box;
  }
  &-9 {
    max-width: calc(9/12*100% - (1 - 9/12)*20px);
    flex: 0 0 calc(9/12*100% - (1 - 9/12)*20px);
    box-sizing: border-box;
  }
  &-8 {
    max-width: calc(8/12*100% - (1 - 8/12)*20px);
    flex: 0 0 calc(8/12*100% - (1 - 8/12)*20px);
    box-sizing: border-box;
  }
  &-7 {
    max-width: calc(7/12*100% - (1 - 7/12)*20px);
    flex: 0 0 calc(7/12*100% - (1 - 7/12)*20px);
    box-sizing: border-box;
  }
  &-6 {
    max-width: calc(6/12*100% - (1 - 6/12)*20px);
    flex: 0 0 calc(6/12*100% - (1 - 6/12)*20px);
    box-sizing: border-box;
  }
  &-5 {
    max-width: calc(5/12*100% - (1 - 5/12)*20px);
    flex: 0 0 calc(5/12*100% - (1 - 5/12)*20px);
    box-sizing: border-box;
  }
  &-4 {
    max-width: calc(4/12*100% - (1 - 4/12)*20px);
    flex: 0 0 calc(4/12*100% - (1 - 4/12)*20px);
    box-sizing: border-box;
  }
  &-3 {
    max-width: calc(3/12*100% - (1 - 3/12)*20px);
    flex: 0 0 calc(3/12*100% - (1 - 3/12)*20px);
    box-sizing: border-box;
  }
  &-2 {
    max-width: calc(2/12*100% - (1 - 2/12)*20px);
    flex: 0 0 calc(2/12*100% - (1 - 2/12)*20px);
    box-sizing: border-box;
  }
}


.grid-content {
  display: grid;
  grid-template-columns: 234px auto;
  height: 100%;
  width: 100%;

  &_left {
    overflow: hidden;
    height: 100%;
    box-sizing: border-box;
  }

  &_right{
    height: 100%;
    overflow: hidden;
    box-sizing: border-box;
  }
}


