.react-calendar {
  border: none !important;
  width: 100% !important;
  height: 100%;
  display: flex;
  flex-direction: column;

  &__navigation {
    margin-bottom: 0;
    height: 44px;
    justify-content: space-between;
    display: flex;

    &__next2-button, &__prev2-button {
      display: none;
    }

    & button{
      font-size: 15px;
      min-width: 44px;
      background: none;
      color: #828282;

      .MuiSvgIcon-root {
        font-size: 16px;
      }
    }


    & button[disabled] {
      background-color: transparent;
    }

    &__label {
      flex-grow: 0 !important;
       font-size: 16px;
     }

     &__arrow {
      font-size: 23px !important;
      line-height: 0;
      // padding-bottom: 0.1em;
    }
  }

  &__navigation button:enabled:hover,
  &__tile:enabled:hover {
      // background-color: $delimiter;
      abbr {
        background-color: $delimiter;
      }
  }

  &__month-view__weekdays {
    font-size: 1em;
    text-align: center;
    text-transform: uppercase;
    font-weight: 700;
  }
  &__month-view__days__day--weekend {
    // color: #d10000;
    color: #828282 !important;
  }
  &__month-view__weekdays__weekday {
    text-transform: none;
    padding: 0.5em 0;
    // color: $gray-text;
    color: #828282;
    font-size: 12px;
    abbr {
      text-decoration: none;
    }
  }
  &__tile {
    background: none;
    font-size: 13px;
    color: #333;
    border-radius: 50%;
    width: 30px;
    height: 36px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;

    abbr {
      // width: inherit;
      // height: inherit;
      // line-height: 40px;
      border-radius: 100%;
      // display: inline-block;
      // padding: 5px 10px;
      width: 28px;
      height: 29px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
  &__tile--active {
    // background-color: #006edc;
    color: #fff;
    // background-color: #4373C4;

    abbr {
      // background-color: #006edc;
      background-color: #4373C4;
      font-weight: 500;
    }
    &:enabled:hover {
      // background-color: #006edc;
      abbr {
        background-color: #4373C4;
      }
    }
  }

  &__month-view__days {
    &__day--neighboringMonth {
      color: #B8B8B8 !important;
    }
  }
}


.dark .react-calendar {

  &__navigation {
    & button{
      color: $dark-theme-title-color;
    }
  }

  &__navigation button:enabled:hover,
  &__tile:enabled:hover {
      background-color: $dark-theme-subtitle-color;
  }

  &__month-view__weekdays__weekday {
    color: $dark-theme-subtitle-color;
  }
}

.layoutLG {
  .react-calendar {
    &__navigation {
      height: 30px;
    }

    &__tile {
      font-size: 13px;
      width: auto;
      height: auto;
    }
  }
}
  