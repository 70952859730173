.single-mail {
    padding: 30px 15px 30px 30px;
    height: 100%;
    box-sizing: border-box;
    overflow-y: auto;
    border-left: 1px solid rgb(224, 224, 224);

    &__header {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin-bottom: 67px;

        &-content {
            display: flex;
            flex-direction: row;
            gap: 12px;

            &-icon {
                width: 50px;
                height: 50px;

                .MuiAvatar-root {
                    width: 100%;
                    height: 100%;
                }
            }
    
            &-info {
                display: flex;
                flex-direction: column;
                gap: 10px;
    
                &_title {
                    font-size: 15px;
                    color: #000000;
                    font-weight: bold;
                }
    
                &_from {
                    font-size: 14px;
                    color: #000000;
                }
    
                &_to {
                    font-size: 14px;
                    color: #000000;
                }
            }
        }

        &-actions {
            &_close {
                cursor: pointer;
            }
        }
    }

    &__content {
        padding-right: 15px;

        &-subject {
            font-size: 15px;
            color: #000000;
        }

        &-message {
            margin-top: 30px;
            font-size: 15px;
            color: #000000;

            P {
                line-height: 2;
            }
        }
    }
}