// @import '../../../styles/sass/variables.scss';

.header {
  height: 45px;
  width: 100%;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 1.5 * $space;
  padding-right: 2 * $space;
  z-index: 3;
  position: fixed;
  // background: rgba(255, 255, 255, 0.5);
  // backdrop-filter: blur(10px);
  // background: rgba(223, 233, 248, 0.5);
  // backdrop-filter: blur(10px);
  // background: rgba(223, 233, 248, 0.5);
  background: #DFE9F880;
  backdrop-filter: blur(10px);

  &-without-logged {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px;

    &__logo-area {
      display: flex;
      align-items: center;
      gap: 25px;

      &_link {
        display: flex;
        align-items: center;
      }

      &_img {
        width: 77px;
        height: 27px;
      }

      &_btn.MuiButtonBase-root {
        height: 34px;
        box-sizing: border-box;

        line-height: 34px;
        padding: 0 12px;

        border-radius: 8px;
        border: 1px solid rgba(255, 255, 255, 0.30);
        background: rgba(248, 250, 252, 0.50);

        color: #727272;
        font-size: 14px;
        text-transform: capitalize;
        font-weight: 400;

        &:hover {
          border: 1px solid rgba(255, 255, 255, 0.30);
          background: rgba(248, 250, 252, 0.50);
        }
      }
    }
  }

  &__logo-area {
    display: flex;
    align-items: center;
    gap: 25px;

    &_link {
      display: flex;
      align-items: center;
    }

    &_img {
      width: 77px;
      height: 27px;
    }

    &_btn.MuiButtonBase-root {
      height: 34px;
      box-sizing: border-box;

      line-height: 34px;
      padding: 0 12px;

      border-radius: 8px;
      border: 1px solid rgba(255, 255, 255, 0.30);
      background: rgba(248, 250, 252, 0.50);

      color: #727272;
      font-size: 14px;
      text-transform: capitalize;
      font-weight: 400;

      &:hover {
        border: 1px solid rgba(255, 255, 255, 0.30);
        background: rgba(248, 250, 252, 0.50);
      }
    }
  }

  &__details-area {
    display: flex;
    position: relative;
    gap: 12px;

    &_projects {
      border-radius: 8px;
      border: 1px solid rgba(248, 250, 252, 0.50);
      min-width: 140px;
      box-sizing: border-box;
      height: 34px;

      &-btn.MuiButtonBase-root {
        height: 34px;
        width: 100%;
        line-height: 33px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        border: unset;
        background-color: transparent;
        color: #727272;
        font-size: 14px;
        font-weight: 400;
        text-transform: none;
        padding: 0px 12px;

        &:hover {
          background-color: transparent;
          border: unset;
        }

        .MuiButton-endIcon {
          margin-right: unset;
          margin-left: 10px;
        }
      }
    }

    &_searchbar {
      position: relative;
      input::-webkit-input-placeholder, input:-moz-placeholder {
        color: #fff !important;
      }

      &_container {
        height: 34px;
        position: relative;
        color: #666666;
        margin-left: 0;
        width: 100%;
        // margin-right: 10px;
        display: flex;
        align-items: center;
        gap: 10px;
        align-items: center;
        // background: rgba(240, 242, 245, 0.8);
        background-color: #ffffff80;
        border-radius: 8px;
        transform: translateX(0);
        transition: all 0.3s ease;
        width: auto;
        padding: 0 12px;
        box-shadow: inset 0px 0px 1px 1px rgba(255, 255, 255, 0.3019607843);

        label img {
          width: 18px;
        }
      }

      &-input {
        // width: 215px;
        font-size: 14px;
        background-color: transparent;
        // border-radius: 25px;
        // padding: 0 13px;
        // padding: 0 8px;
        height: 34px;
        line-height: 34px;
        border: unset;
        transition: all 0.3s ease;
        color: #74737B;
        transform: translateX(0);
        // background: rgba(240, 242, 245, 0.8);
        // border-radius: 8px;
        cursor: pointer;

        &::placeholder {
          color: #74737B;

        }

        // &:focus {
        //   width: 500px;
        //   // transform: translateX(45px);
        // }
      }

      &-gif {
        width: 30px;
        height: 30px;
        z-index: 1;
      }

      &-globe {
        width: 21px;
        height: 21px;
        border-radius: 16px;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;

        img {
          width: 21px;
          height: 21px;
        }
      }
    }

    &_items {
      display: flex;
      column-gap: 10px;
      align-items: center;
      z-index: 2;
      background: #ffffff80;
      border-radius: 8px;
      padding: 0 5px;
      box-shadow: inset 0px 0px 1px 1px rgba(255, 255, 255, 0.3019607843);

      &-icon {
        width: 32px;
        height: 32px;
        border-radius: 16px;
        cursor: pointer;
        // background-color: #4382C4;
        display: flex;
        align-items: center;
        justify-content: center;

        img {
          width: 23px;
          height: 23px;
        }
      }
      // &-globe {
      //   width: 32px;
      //   height: 33px;
      //   border-radius: 16px;
      //   cursor: pointer;
      //   display: flex;
      //   align-items: center;
      //   justify-content: center;

      //   img {
      //     width: 100%;
      //     height: 100%;
      //   }
      // }
    }

    &_account {
      display: flex;
      margin-left: 12px;

      &-icon {
        width: 32px;
        height: 32px;
        border-radius: 16px;
        cursor: pointer;
        // background-color: #4382C4;
        display: flex;
        align-items: center;
        justify-content: center;

        img {
          width: 23px;
          height: 23px;
        }
      }
    }
  }
}

.dark .header {
  background-color: #5c5c5cb0;
}
