@font-face {
    font-family: 'Roboto';
    src: url('../../assets/fonts/Roboto/Roboto-Bold.eot');
    src: url('../../assets/fonts/Roboto/Roboto-Bold.eot?#iefix') format('embedded-opentype'),
    url('../../assets/fonts/Roboto/Roboto-Bold.woff') format('woff'),
    url('../../assets/fonts/Roboto/Roboto-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
  }
  
  @font-face {
    font-family: 'Roboto';
    src: url('../../assets/fonts/Roboto/Roboto-Light.eot');
    src: url('../../assets/fonts/Roboto/Roboto-Light.eot?#iefix') format('embedded-opentype'),
    url('../../assets/fonts/Roboto/Roboto-Light.woff') format('woff'),
    url('../../assets/fonts/Roboto/Roboto-Light.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
  }
  
  @font-face {
    font-family: 'Roboto';
    src: url('../../assets/fonts/Roboto/Roboto-Thin.eot');
    src: url('../../assets/fonts/Roboto/Roboto-Thin.eot?#iefix') format('embedded-opentype'),
    url('../../assets/fonts/Roboto/Roboto-Thin.woff') format('woff'),
    url('../../assets/fonts/Roboto/Roboto-Thin.ttf') format('truetype');
    font-weight: 100;
    font-style: normal;
  }


  // poppins
  @font-face {
    font-family: 'Poppins';
    src: url('../../assets/fonts/Poppins/Poppins-Bold.eot');
    src: url('../../assets/fonts/Poppins/Poppins-Bold.eot?#iefix') format('embedded-opentype'),
    url('../../assets/fonts/Poppins/Poppins-Bold.woff') format('woff'),
    url('../../assets/fonts/Poppins/Poppins-Bold.ttf') format('truetype');
    font-weight: 600;
    font-style: normal;
  }
  
  @font-face {
    font-family: 'Poppins';
    src: url('../../assets/fonts/Poppins/Poppins-Light.eot');
    src: url('../../assets/fonts/Poppins/Poppins-Light.eot?#iefix') format('embedded-opentype'),
    url('../../assets/fonts/Poppins/Poppins-Light.woff') format('woff'),
    url('../../assets/fonts/Poppins/Poppins-Light.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
  }

  @font-face {
    font-family: 'Poppins';
    src: url('../../assets/fonts/Poppins/Poppins-Light.eot');
    src: url('../../assets/fonts/Poppins/Poppins-Light.eot?#iefix') format('embedded-opentype'),
    url('../../assets/fonts/Poppins/Poppins-Light.woff') format('woff'),
    url('../../assets/fonts/Poppins/Poppins-Light.ttf') format('truetype');
    font-weight: 400;
    font-style: normal;
  }
  
  @font-face {
    font-family: 'Poppins';
    src: url('../../assets/fonts/Poppins/Poppins-Thin.eot');
    src: url('../../assets/fonts/Poppins/Poppins-Thin.eot?#iefix') format('embedded-opentype'),
    url('../../assets/fonts/Poppins/Poppins-Thin.woff') format('woff'),
    url('../../assets/fonts/Poppins/Poppins-Thin.ttf') format('truetype');
    font-weight: 100;
    font-style: normal;
  }

  @font-face {
    font-family: 'Poppins';
    src: url('../../assets/fonts/Poppins/Poppins-Medium.eot');
    src: url('../../assets/fonts/Poppins/Poppins-Medium.eot?#iefix') format('embedded-opentype'),
    url('../../assets/fonts/Poppins/Poppins-Medium.woff') format('woff'),
    url('../../assets/fonts/Poppins/Poppins-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
  }
  

// Open sans
  @font-face {
    font-family: 'OpenSans';
    src: url('../../assets/fonts/OpenSans/OpenSans-Light.eot');
    src: url('../../assets/fonts/OpenSans/OpenSans-Light.eot?#iefix') format('embedded-opentype'),
    url('../../assets/fonts/OpenSans/OpenSans-Light.woff') format('woff'),
    url('../../assets/fonts/OpenSans/OpenSans-Light.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
  }

  @font-face {
    font-family: 'OpenSans';
    src: url('../../assets/fonts/OpenSans/OpenSans-Regular.eot');
    src: url('../../assets/fonts/OpenSans/OpenSans-Regular.eot?#iefix') format('embedded-opentype'),
    url('../../assets/fonts/OpenSans/OpenSans-Regular.woff') format('woff'),
    url('../../assets/fonts/OpenSans/OpenSans-Regular.ttf') format('truetype');
    font-weight: 400;
    font-style: normal;
  }

  @font-face {
    font-family: 'OpenSans';
    src: url('../../assets/fonts/OpenSans/OpenSans-Medium.eot');
    src: url('../../assets/fonts/OpenSans/OpenSans-Medium.eot?#iefix') format('embedded-opentype'),
    url('../../assets/fonts/OpenSans/OpenSans-Medium.woff') format('woff'),
    url('../../assets/fonts/OpenSans/OpenSans-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
  }

  @font-face {
    font-family: 'OpenSans';
    src: url('../../assets/fonts/OpenSans/OpenSans-SemiBold.eot');
    src: url('../../assets/fonts/OpenSans/OpenSans-SemiBold.eot?#iefix') format('embedded-opentype'),
    url('../../assets/fonts/OpenSans/OpenSans-SemiBold.woff') format('woff'),
    url('../../assets/fonts/OpenSans/OpenSans-SemiBold.ttf') format('truetype');
    font-weight: 600;
    font-style: normal;
  }

  @font-face {
    font-family: 'OpenSans';
    src: url('../../assets/fonts/OpenSans/OpenSans-Bold.eot');
    src: url('../../assets/fonts/OpenSans/OpenSans-Bold.eot?#iefix') format('embedded-opentype'),
    url('../../assets/fonts/OpenSans/OpenSans-Bold.woff') format('woff'),
    url('../../assets/fonts/OpenSans/OpenSans-Bold.ttf') format('truetype');
    font-weight: 700;
    font-style: normal;
  }

    
  // Inter
  @font-face {
    font-family: 'Inter';
    src: url('../../assets/fonts/Inter/Inter-Light.eot');
    src: url('../../assets/fonts/Inter/Inter-Light.eot?#iefix') format('embedded-opentype'),
    url('../../assets/fonts/Inter/Inter-Light.woff') format('woff'),
    url('../../assets/fonts/Inter/Inter-Light.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
  }

  @font-face {
    font-family: 'Inter';
    src: url('../../assets/fonts/Inter/Inter-Regular.eot');
    src: url('../../assets/fonts/Inter/Inter-Regular.eot?#iefix') format('embedded-opentype'),
    url('../../assets/fonts/Inter/Inter-Regular.woff') format('woff'),
    url('../../assets/fonts/Inter/Inter-Regular.ttf') format('truetype');
    font-weight: 400;
    font-style: normal;
  }

  @font-face {
    font-family: 'Inter';
    src: url('../../assets/fonts/Inter/Inter-Medium.eot');
    src: url('../../assets/fonts/Inter/Inter-Medium.eot?#iefix') format('embedded-opentype'),
    url('../../assets/fonts/Inter/Inter-Medium.woff') format('woff'),
    url('../../assets/fonts/Inter/Inter-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
  }

  @font-face {
    font-family: 'Inter';
    src: url('../../assets/fonts/Inter/Inter-SemiBold.eot');
    src: url('../../assets/fonts/Inter/Inter-SemiBold.eot?#iefix') format('embedded-opentype'),
    url('../../assets/fonts/Inter/Inter-SemiBold.woff') format('woff'),
    url('../../assets/fonts/Inter/Inter-SemiBold.ttf') format('truetype');
    font-weight: 600;
    font-style: normal;
  }

  @font-face {
    font-family: 'Inter';
    src: url('../../assets/fonts/Inter/Inter-Bold.eot');
    src: url('../../assets/fonts/Inter/Inter-Bold.eot?#iefix') format('embedded-opentype'),
    url('../../assets/fonts/Inter/Inter-Bold.woff') format('woff'),
    url('../../assets/fonts/Inter/Inter-Bold.ttf') format('truetype');
    font-weight: 700;
    font-style: normal;
  }