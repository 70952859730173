.register-page-mobile {
  width: 100vw;
  height: 100vh;
  background-color: #fff;

  &__wrapper {
      width: 100%;
      height: 100%;
  }

  &__content {
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 60px;
      position: relative;

      &__quit {
          position: absolute;
          top: 50px;
          right: 50px;

          &-container {
              button {
                  color: #727272;
                  text-align: center;
                  font-family: "OpenSans" !important;
                  font-size: 3rem;
                  text-decoration: underline;
                  background-color: transparent;
              }
          }
      }

      &__motion {
          width: 400px;
          height: 400px;
          position: absolute;
          top: 10%;

          &-item {
              position: absolute;
              &-img {
                  width: 400px;
                  height: 400px;
              }
          }
      }

      &__info {
        //   max-width: 400px;
          box-sizing: border-box;
          display: flex;
          flex-direction: column;
          gap: 70px;
          position: absolute;
          top: 45%;
          padding: 0 10%;
          width: 100%;

          &_text {
              width: inherit;
              color: #727272;
              text-align: center;
              font-family: 'OpenSans' !important;
              font-size: 3rem;
              line-height: 1.25;
              position: relative;

              &::after {
                  content: "";
                  position: absolute;
                  transform:rotate(20deg);
                  top: 0;
                  background: #ffffffc2;
                  filter: blur(8px);
                  -webkit-filter: blur(8px);
                  animation: shine-effect 3s infinite;
              }
          }

          &_form {
              display: flex;
              flex-direction: column;
              gap: 50px;
              margin-top: 15px;

              &-input {
                  width: 100%;
                  color: #414141 !important;
                  font-family: 'OpenSans' !important;
                  font-size: 3rem !important;
                  font-weight: 600 !important;

                  &.shake {
                      animation: shake 0.82s cubic-bezier(.36,.07,.19,.97) both;
                      transform: translate3d(0, 0, 0);
                      backface-visibility: hidden;
                      perspective: 1000px;
                  }

                  &::before {
                      border-color: #DCDCDC !important;
                      bottom: -6px !important;
                  }

                  &::after {
                      display: none;
                  }

                  &:hover::before {
                      border-width: 1px !important;
                  }

                  input::-webkit-outer-spin-button,
                  input::-webkit-inner-spin-button {
                    -webkit-appearance: none;
                    margin: 0;
                  }

                  /* Firefox */
                  input[type=number] {
                    -moz-appearance: textfield;
                  }
              }

              &-enter {
                  background-color: transparent;
                  padding: 0;

                  img {
                    height: 60px;
                  }

                  &:hover {
                      background-color: transparent !important;
                  }
              }

              &-btns {
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  gap: 20px;

                  button.MuiButtonBase-root {
                      font-size: 3rem;
                      text-transform: none;
                      border-radius: 40px;
                      padding: 10px 25px;
                  }

                  &_yes.MuiButtonBase-root {
                      color: #fff;
                      background-color: #fea1d9;
                      border: 1px solid #fea1d9;

                      &:hover {
                          color: #fff;
                          background-color: #fea1d9;
                          border: 1px solid #fea1d9;
                      }
                  }

                  &_no.MuiButtonBase-root {
                      color: #fea1d9;
                      border: 1px solid #fea1d9;
                      background-color: #fff;

                      &:hover {
                          color: #fea1d9;
                          border: 1px solid #fea1d9;
                          background-color: #fff;
                      }
                  }
              }

              &.shake {
                  animation: shake 0.82s cubic-bezier(.36,.07,.19,.97) both;
                  transform: translate3d(0, 0, 0);
                  backface-visibility: hidden;
                  perspective: 1000px;
              }

          }

          &_list {
              width: 200px;
              margin-top: -40px;

              &-item {
                  text-align: center;
                  opacity: 0;

                  img {
                      width: 30px;
                      height: 30px;
                  }
              }

              .swiper-slide-prev {
                  opacity: 0.2;
              }

              .swiper-slide-active {
                  opacity: 0.4;
              }

              .swiper-slide-next {
                  opacity: 1;
              }
          }

          &_line {
              width: 100%;
              display: flex;
              justify-content: center;
              margin-top: -40px;

              p {
                  width: 1.5px;
                  height: 60px;
                  background-color: #eee;
              }
          }


      }
  }
}

@keyframes shine-effect {
  0% {
      left: -150%;
  }
  100% {
      left: 150%;
  }
}


@keyframes shake {
10%, 90% {
transform: translate3d(-1px, 0, 0);
}

20%, 80% {
transform: translate3d(2px, 0, 0);
}

30%, 50%, 70% {
transform: translate3d(-4px, 0, 0);
}

40%, 60% {
transform: translate3d(4px, 0, 0);
}
}
