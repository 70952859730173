@import "../../../../styles/sass/variables.scss";

.video-conferencing-page {
  width: 100%;
  padding-bottom: 10px;
  // height: 75vh;
  height: 100%;
  overflow: auto;

  & .MuiGrid-container {
    margin: 0;
  }

  & .MuiGrid-item {
    padding-top: 0 !important;
  }

  & .MuiGrid-root {
    height: 100%;
  }

  &_sidebar {
    width: 100%;
    height: 100%;
    overflow: auto;
  }

}

.cloud-page__header_video-conferencing_details {
    width: 100%;
    text-align: right;
    padding-left: 5px;
    box-sizing: border-box;

    &-close {
      text-align: right;

      img {
        width: 22px;
        height: 22px;
      }
    }

}
