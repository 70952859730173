.tiptap {
  outline: none;
  height: 100%;
  padding: 0 10px;

  > * + * {
    margin-top: 0.75em;
  }

  ul,
  ol {
    padding: 0 1rem;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    // line-height: 1.1;
    line-height: normal;
  }


  p {
    color: #414141;
    font-size: 15px;
    line-height: normal;
  }

  & > p:first-child:not(.is-empty) {
    font-size: 20px;
    font-weight: 700;

    strong {
      color: #414141;
      font-size: 15px;
      line-height: normal;
      font-weight: 400;
    }
  }



  strong {
    color: #414141;
    font-size: 20px;
    font-weight: 700;
    line-height: normal;
  }

  code {
    background-color: rgba(#616161, 0.1);
    color: #616161;
  }

  pre {
    background: #0D0D0D;
    color: #FFF;
    font-family: 'JetBrainsMono', monospace;
    padding: 0.75rem 1rem;
    border-radius: 0.5rem;

    code {
      color: inherit;
      padding: 0;
      background: none;
      font-size: 0.8rem;
    }
  }

  img {
    max-width: 100%;
    height: auto;
  }

  blockquote {
    padding-left: 1rem;
    border-left: 2px solid rgba(#0D0D0D, 0.1);
  }

  hr {
    border: none;
    border-top: 2px solid rgba(#0D0D0D, 0.1);
    margin: 2rem 0;
  }

  ul[data-type="taskList"] {
      list-style: none;
      padding: 0;

      p {
        margin: 0;
      }

      li {
        display: flex;

        > label {
          flex: 0 0 auto;
          margin-right: 0.5rem;
          user-select: none;
        }

        > div {
          flex: 1 1 auto;
        }

        ul li,
        ol li {
            display: list-item;
        }

        ul[data-type="taskList"] > li {
            display: flex;
        }


        input[type="checkbox"] {
          width: 16px;
          height: 16px;
          padding: 0;
          margin: 5px;
          cursor: pointer;
          appearance: none;
          -webkit-appearance: none;
          border-radius: 50%;
          border: 1px solid #999;
          display: flex;
          align-items: center;
          justify-content: center;

        }


        input[type="checkbox"]:checked {
          background-color: #51A3FF;
          border-color: #51A3FF;
        }

        input[type="checkbox"]:checked::before {
          transform: scale(1);
        }

        input[type="checkbox"]::before {
          width: 12px;
          height: 12px;
          content: "";
          background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='11' height='9' viewBox='0 0 11 9'%3E%3Cpath d='M3.55556 8.35556L0 4.8L1.24444 3.55556L3.55556 5.86667L9.42222 0L10.6667 1.24445L3.55556 8.35556Z' fill='white'/%3E%3C/svg%3E");
          transform: scale(0);
          background-position: center center;
          background-size: contain;
          background-repeat: no-repeat;
      }


      }
  }

  table {
      border-collapse: collapse;
      margin: 0;
      overflow: hidden;
      table-layout: fixed;
      width: 100%;

      td,
      th {
        border: 2px solid #ced4da;
        box-sizing: border-box;
        min-width: 1em;
        padding: 10px 5px;
        position: relative;
        vertical-align: top;

        > * {
          margin-bottom: 0;
        }
      }

      th {
        background-color: #f1f3f5;
        font-weight: bold;
        text-align: left;
      }

      .selectedCell:after {
        background: rgba(200, 200, 255, 0.4);
        content: "";
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        pointer-events: none;
        position: absolute;
        z-index: 2;
      }

      .column-resize-handle {
        background-color: #adf;
        bottom: -2px;
        position: absolute;
        right: -2px;
        pointer-events: none;
        top: 0;
        width: 4px;
      }

      p {
        margin: 0;
      }
  }

  p.is-editor-empty:first-child::before {
    color: #adb5bd;
    content: attr(data-placeholder);
    float: left;
    height: 0;
    pointer-events: none;
  }
}



.tableWrapper {
  padding: 1rem 0;
  overflow-x: auto;
}

.resize-cursor {
  cursor: ew-resize;
  cursor: col-resize;
}

.has-focus {
border-radius: 3px;
box-shadow: 0 0 0 3px #68cef8;
}
