$signin-bg-color: #FFF;

.MuiInputBase-root-MuiInput-root-MuiSelect-root:before {
  border-bottom: none !important;
}

.signin {
  padding-top:60px;
  padding-bottom: 100px;
  text-align:center;
  min-width:100vw;
  box-sizing: border-box;
  position: relative;

  .signin-wrapper {
    display: flex;
    justify-content: center;
  }

  &__footer-link {
    margin-top: 30px;
    font-size: 14px;
  }

  &__footer-link-title {
    font-weight: 400;
  }

  &__footer-link-links {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 8px;
    margin-top: 15px;
  }

  &__form-group {
    margin-bottom: 20px;
  }
  .btn {
    margin-top:10px;
  }
  &__inner-container {
    z-index:1;
    color:white;
    position: relative !important;
  }
  &__block {
    width: 380px;
    padding: 50px 45px;
    padding-bottom: 30px;
    background: $signin-bg-color !important;
    text-align: left;
    color: #333;
    box-shadow: 0px 0px 29px 0px rgba(231, 192, 252, 0.50);
  }
  &__logo {
    font-size: 27px;
    font-weight: 600;
    margin-bottom: 15px;
  }
  &__subtitle {
    font-size: 14px;
    font-weight: 400;
    margin-bottom: 30px;
    color: #777;
  }
  &__bg-swiper {
    height: 100%;
    width: 100%;
    position: absolute !important;
    top: 0;
    z-index: 0 !important;
    &_slide {
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
      width:100%;
      height: 100%;
      background-color: rgba(0,0,0,0.2);
      background-blend-mode: multiply;
    }
    &_slide-info {
      text-align: right;
      bottom:97px;
      position: absolute;
      width:100%;
    }
    &_slide-info-block {
      color:white;
      font-size: 16px;
      display: inline-block;
      padding: 7px 10px;
      border-radius: $border-radius-second;
      line-height: 1.3;
    }
    &_nav {
      position: absolute;
      bottom: 60px;
      width: 100%;
      z-index: 1;
      color:white;
      text-align: right;
    }
    &_prev,
    &_next {
      display: inline-block;
      width: 30px;
      height: 30px;
      border-radius: $border-radius-second;
      background-image: url('../../assets/icons/arrow-right-white.svg');
      background-size: 10px auto;
      background-repeat: no-repeat;
      background-position: center;
      cursor: pointer;
      outline: none;
      transition: 0.2s;
    }
    &_prev {
      margin-right:7px;
      transform: rotate(180deg);
    }
  }
  &__language {
    text-align: right;
    font-size: 13px;
  }
  .site-input {
    border: 1px solid #DDDFE1;
    line-height: unset;
    height: unset;
    padding: 8px 10px;
    font-size: 14px;
  }

  .site-input.invalid {
    border: 1px solid #EF4051;
  }

  .site-input-error {
    color: #EF4051;
    font-size: 14px;
    font-weight: 500;
  }

  .site-input:focus {
    border: 1px solid #51A3FF;
    background: rgba(248, 250, 252, 0.50);
    box-shadow: 0px 0px 0px 2px rgba(81, 163, 255, 0.20);
  }

  .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
    border: 1px solid #51A3FF;
    background: rgba(248, 250, 252, 0.50);
    box-shadow: 0px 0px 0px 2px rgba(81, 163, 255, 0.20);
  }

  .MuiCheckbox-colorSecondary.Mui-checked {
    color: #51A3FF;
  }

  .site-input-label {
    font-size: 14px;
    font-weight: 500;
  }
  &__error-message {
    font-size: 15px;
  }
  &__footer {
    text-align: right;
    bottom:0;
    position: absolute;
    width:100%;
    padding-top: 20px;
    padding-bottom: 20px;
    z-index: 1;
    color:white;
    font-size: 13px;
    .fix {
      &:before {
        top:0;
        content: "";
        position: absolute;
        width: 100%;
        height: 1px;
        left:0;
        background: rgba(255,255,255,0.2);
      }
    }
  }
  &__main-table {
    width:100%;
    td {
      vertical-align: middle;
    }
  }
  &__offer {
    color: white;
    box-sizing: border-box;
    padding: 0 20px;
    line-height: 1.3;
    font-size: 33px;
    &_main {
      font-weight: 600;
      margin-bottom: 13px;
    }
  }

  .MuiOutlinedInput-root {
    border-radius: 8px !important;
  }

  .MuiInputBase-input:focus {
    color: #000 !important;
  }

  .MuiOutlinedInput-input {
    font-size: 14px;
    padding: 10px 15px;
  }

  .trouble-shooting-menu {
    border-radius: 8px;
    border: 1px solid #DEDFE2;
    background: #FFF;
    box-shadow: 0px 4px 10px 1px rgba(0, 0, 0, 0.10);
  }
}
