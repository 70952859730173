.create-eventpopup {
    width: 320px;
    padding: 20px;
    box-sizing: border-box;

    &-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 10px 0;

        &-title {
            display: flex;
            align-items: center;
            gap: 5px;
            font-size: 20px;
            color: #51A3FF;

            p {
                font-family: 'OpenSans' !important;
            }
        }
        
        &-btn {
            .MuiButtonBase-root {
                color: #51A3FF;
            }
        }
    }

    &-list {
        padding: 10px 0;
        display: flex;
        flex-direction: column;
        gap: 20px;
    }

    &-item {
        &-title {
            font-size: 13px;
            display: flex;
            align-items: center;
            gap: 5px;
            margin-bottom: 5px;
            color: #444;
            font-family: 'OpenSans' !important;
        }

         

        & .MuiInputBase-root {
            width: 100%;
            border-radius: 8px;
            background-color: #F8FAFC;
            height: 35px;
            box-sizing: border-box;

            input {
                font-size: 13px;
                color: #444;
                padding: 12px 10px 10px 10px;
                height: 35px;
                box-sizing: border-box;
                font-family: 'OpenSans' !important;

                &::placeholder {
                    color: #444;
                    opacity: 1;
                }
            }

            .MuiOutlinedInput-notchedOutline {
                border-radius: 8px;
                border-color: #ececec;
            }

            &:hover .MuiOutlinedInput-notchedOutline {
                border-color: #1976d2;
            }
        }

        &-input {

            & .MuiInputBase-root {
                width: 100%;
                border-radius: 8px;
                background-color: #F8FAFC;
    
                input {
                    padding: 10px;
                }
    
                .MuiOutlinedInput-notchedOutline {
                    border-radius: 8px;
                    border-color: #ececec;
                }
    
                &:hover .MuiOutlinedInput-notchedOutline {
                    border-color: #1976d2;
                }
            }
        }

        &-select {
            width: 100%;

            .MuiSelect-select {
                padding: 10px;
                // background-color: #F8FAFC;
                display: flex;
                align-items: center;
                justify-content: space-between;
                font-size: 13px;
                color: #444;
                font-family: 'OpenSans' !important;
            }

            .MuiOutlinedInput-notchedOutline  {
                border-radius: 8px;
            }

            .MuiSvgIcon-root {
                color: #ACACAC;
            }

            .color-bullet {
                width: 20px;
                height: 20px;
                display: inline-block;
            }

            &.MuiInputBase-root:hover .MuiOutlinedInput-notchedOutline {
                border-color: #1976d2;
            }
        }

        &-checkbox {

            .MuiFormControlLabel-root {
                margin: 0;

                .MuiButtonBase-root {
                    padding: 0;

                    .MuiSvgIcon-root {
                        stroke: #fff;
                        font-size: 21px;
                    }
                }

                .MuiTypography-root {
                    font-size: 13px;
                    color: #444;
                    padding-left: 5px;
                }
            }

        }

        &-double {
            display: flex;
            gap: 10px;
            width: 100%;

            .MuiFormControl-root {
                width: 100%;
            }

            .MuiStack-root {
                width: 100%;
                padding-top: 0;
            }

        }

        &.note {
            display: flex;
            flex-direction: column;
            gap: 5px;

            .MuiFormControl-root {
                width: 100%;
                max-width: unset;
            }

            & .MuiInputBase-root {
                border-radius: 8px;
                background-color: #F8FAFC;
                width: 100%;
                min-height: 100px;
                align-items: start;
                height: auto;

                textarea {
                    font-family: 'OpenSans' !important;
                    font-size: 13px;
                    color: #444;
                }
    
                input {
                    padding: 10px;
                }
    
                .MuiOutlinedInput-notchedOutline {
                    border-radius: 8px;
                }
    
                &:hover .MuiOutlinedInput-notchedOutline {
                    border-color: #1976d2;
                }
            }
        }

        &-repeat-days {
            width: 40%;
            height: 35px;
            display: flex;

            &-decrease {
                background-color: #f0f0f0;
                width: 30px;
                height: 100%;
                border-radius: 8px 0 0 8px;
                border-right: 1px solid #ECECEC;
                cursor: pointer;

                span {
                    width: 100%;
                    height: 100%;
                    display: flex;
                    color: #444;
                    align-items: center;
                    justify-content: center;
                    user-select: none; // chrome and Opera
                     -moz-user-select: none; // Firefox
                     -webkit-text-select: none; // IOS Safari
                     -webkit-user-select: none; // Safari
                }

            }

            &-number {
                width: calc(100% - 60px);
                height: 100%;

                input::-webkit-outer-spin-button,
                input::-webkit-inner-spin-button {
                  -webkit-appearance: none;
                  margin: 0;
                }

                /* Firefox */
                input[type=number] {
                  -moz-appearance: textfield;
                }

                input {
                    width: 100%;
                    height: 100%;
                    box-sizing: border-box;
                    padding: 5px;
                    color: #444;
                    border: unset;
                    background-color: #F8FAFC;
                    text-align: center;

                }
            }

            &-increase {
                background-color: #f0f0f0;
                width: 30px;
                height: 100%;
                border-radius: 0 8px 8px 0;
                border-left: 1px solid #ECECEC;
                cursor: pointer;

                span {
                    width: 100%;
                    height: 100%;
                    display: flex;
                    color: #444;
                    align-items: center;
                    justify-content: center;
                    user-select: none; // chrome and Opera
                     -moz-user-select: none; // Firefox
                     -webkit-text-select: none; // IOS Safari
                     -webkit-user-select: none; // Safari
                }
            }
        }

        &-repeat-loop {
            width: 60%;
            height: 35px;
        }

        &-end-select {
            width: 60%;

            &-radio {
                .MuiFormControlLabel-root {
                    width: 100%;
                    height: 35px;
                    box-sizing: border-box;
                }

                .MuiButtonBase-root {
                    width: 30px;
                    height: 35px;
                    box-sizing: border-box;
                    
                    &.Mui-checked {
                        color: #51A3FF;
                    }

                }

                .MuiSvgIcon-root {
                    font-size: 18px;
                }

                .MuiTypography-root {
                    color: #444;
                    font-size: 13px;
                    font-family: 'OpenSans' !important;
                }
            }

        }

        &-end-data {
            width: 40%;

            .MuiTextField-root {
                padding-top: 35px;
            }
            
            .MuiFormControl-fullWidth {
                padding-top: 70px;
            }
        }
    }

    &-btn {
        display: flex;
        justify-content: end;
        gap: 10px;

        &-submit.MuiButtonBase-root {
            text-transform: none;
            color: #fff;
            background-color: #51A3FF;
            padding: 10px 24px;
            border-radius: 30px;
            font-size: 14px;
            margin-top: 20px;
            font-family: 'OpenSans' !important;
            height: 40px;
            box-sizing: border-box;

            &:hover {
                text-transform: none;
                color: #fff;
                background-color: #51A3FF;
                padding: 10px 24px;
                border-radius: 30px;
            }
        }

        
        &-reset.MuiButtonBase-root {
            text-transform: none;
            color: #888888;    
            background-color: #fff;
            padding: 10px 24px;
            border-radius: 30px;
            border: 1px solid #cdcdcd;
            font-size: 14px;
            margin-top: 20px;
            font-family: 'OpenSans' !important;
            height: 40px;
            box-sizing: border-box;

            &:hover {
                text-transform: none;
                color: #888888;    
                background-color: #fff;
                padding: 10px 24px;
                border-radius: 30px;
                border: 1px solid #cdcdcd;
            }
        }
    }
}


.settingpopup {
    width: 80%;
    max-width: 620px;
    height: auto;
    max-height: 70vh;
    overflow-y: auto;
    background-color: white;
    border-radius: 8px;
    padding: 40px;
    box-sizing: border-box;

    &-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 0 10px 0;
        border-bottom: 1px solid #ECECEC;


        &-title {
            display: flex;
            align-items: center;
            gap: 5px;
            font-size: 20px;
            color: #51A3FF;

            p {
                font-family: 'OpenSans' !important;
            }
        }
        
        &-btn {
            .MuiButtonBase-root {
                color: #51A3FF;
            }
        }
    }

    &-list {
        padding: 20px 0;
        display: flex;
        flex-direction: column;
        // gap: 20px;
    }

    &-item {
        padding-bottom: 20px;

        &.pb-0 {
            padding-bottom: 0;
        }

        &.flex {
            display: flex;
            align-items: center;
            justify-content: space-between;
        }

        &-title {
            font-size: 14px;
            color: #444;
            width: auto;
            font-family: 'OpenSans' !important;
        }

        .MuiFormControl-root {
            width: 100%;
            max-width: 380px;
        }

        &-input {

            & .MuiInputBase-root {
                width: 100%;
                border-radius: 8px;
                background-color: #F8FAFC;
    
                input {
                    padding: 10px;
                    height: 35px;
                    box-sizing: border-box;
                    font-size: 14px;
                    color: #444;
                    font-family: 'OpenSans' !important;
                }
    
                .MuiOutlinedInput-notchedOutline {
                    border-radius: 8px;
                    border-color: #ececec;
                }
    
                &:hover .MuiOutlinedInput-notchedOutline {
                    border-color: #1976d2;
                }
            }
        }

        &-select {
            width: 100%;

            .MuiSelect-select {
                height: 35px !important;
                box-sizing: border-box;
                padding: 10px;
                background-color: #F8FAFC;
                line-height: 1;
                border-radius: 8px;
            }

            .MuiOutlinedInput-notchedOutline  {
                border-radius: 8px;
                border-color: #ececec;
            }

            .MuiSvgIcon-root {
                color: #ACACAC;
            }

            &.MuiInputBase-root:hover .MuiOutlinedInput-notchedOutline {
                border-color: #1976d2;
            }

            &-container {
                height: 100%;
                width: 100%;
                display: flex;
                justify-content: space-between;
                align-items: center;
                font-size: 14px;
                color: #444;
                font-family: 'OpenSans' !important;

                .bullet {
                    width: 20px;
                    height: 20px;
                    display: inline-block;
                }
            }

            &-color {
                .circle-picker {
                    width: 340px !important;
                }
            }
        }

        &-checkbox {

            .MuiFormControlLabel-root {
                margin: 0;

                .MuiButtonBase-root {
                    padding: 0;

                    .MuiSvgIcon-root {
                        stroke: #fff;
                        font-size: 21px;
                    }
                }

                .MuiTypography-root {
                    font-size: 13px;
                    color: #444;
                    padding-left: 5px;
                }
            }

        }
    }

    &-btn {
        display: flex;
        justify-content: end;
        gap: 10px;

        &-submit.MuiButtonBase-root {
            text-transform: none;
            color: #fff;
            background-color: #51A3FF;
            padding: 10px 24px;
            border-radius: 30px;
            font-size: 14px;
            margin-top: 20px;
            font-family: 'OpenSans' !important;
            height: 40px;
            box-sizing: border-box;

            &:hover {
                text-transform: none;
                color: #fff;
                background-color: #51A3FF;
                padding: 10px 24px;
                border-radius: 30px;
            }
        }

        
        &-reset.MuiButtonBase-root {
            text-transform: none;
            color: #888888;    
            background-color: #fff;
            padding: 10px 24px;
            border-radius: 30px;
            border: 1px solid #cdcdcd;
            font-size: 14px;
            margin-top: 20px;
            font-family: 'OpenSans' !important;
            height: 40px;
            box-sizing: border-box;

            &:hover {
                text-transform: none;
                color: #888888;    
                background-color: #fff;
                padding: 10px 24px;
                border-radius: 30px;
                border: 1px solid #cdcdcd;
            }
        }
    }
}