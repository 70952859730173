@import "../../../styles/sass/variables.scss";

.tasks-page {
  width: 100%;
  // padding-bottom: 10px;
  padding: 0 5px 0px 5px;
  box-sizing: border-box;
  // height: 75vh;
  height: 100%;
  overflow: auto;

  @media (min-width: 1600px) {
    &.tasks-page-zoomout-one {
      zoom: 0.85;
    }
  
    &.tasks-page-zoomout-two {
      zoom: 0.65;
    }
  }

  @media (max-width: 1600px) {
    &.tasks-page-zoomout-one {
      zoom: 0.85;
    }
  
    &.tasks-page-zoomout-two {
      zoom: 0.65;
    }
  }

  & .MuiGrid-container {
    margin: 0;
  }

  & .MuiGrid-item {
    padding-top: 0 !important;
  }

  & .MuiGrid-root {
    height: 100%;
  }

  &_main {
    height: 100%;
    padding: 0 15px 0 15px;
    background-color: white;
    border-radius: 8px;

    &_list {
        width: 100%;
        height: 100%;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;

        &-actions {
          display: flex;
          gap: 10px;
          margin-bottom: 15px;

          .input-container {
            width: auto;
            // max-width: 300px;
            // margin-right: 0.5rem;
            margin-top: 10px;
          
            .input-content {
              width: auto;
              // max-width: 300px;
              border-radius: 5px;
              // background-color: #ebecf0;
              // padding: 10px;
              box-sizing: border-box;
              
              .add-backlog-btn.MuiButtonBase-root {
                // background-color: #3089dc;
                background-color: transparent;
                width: 100%;
                padding: 8px 20px;
                padding-left: 7px;
                text-align: center;
                // color: #ffffff;
                color: #000;
                border-radius: 8px;
                text-transform: none;
                font-size: 14px;

                .MuiSvgIcon-root {
                  font-size: 16px;
                  border: 1px solid #000;
                  border-radius: 50%;
                }
              }

            }

          }
        }

        .wrapper {
          width: 100%;
          height: 99%;
          box-sizing: border-box;
          display: flex;
          gap: 15px;
          overflow-x: auto;
          
          /* Track */
          &::-webkit-scrollbar-track { 
            border-radius: 8px;
          }
           
          /* Handle */
          &::-webkit-scrollbar-thumb {
            border-radius: 8px;
          }

          & > div {
            width: 314px;
            flex: 0 0 314px;
          }
          

          & .list-cards {
            // width: 300px;
            padding: 5px 0;
            background-color: #f1f2f4;
            // margin-right: 0.5rem;
            border-radius: 8px;
            cursor: pointer;

            &.dragged-item {
              // background-color: gray !important;
              filter: blur(1px);
              opacity: 0.5;
            }
          
            .title-list {
              height: 38px;
              // padding: 10px;
              // background-color: #ffffff;
              border-radius: 8px;
              box-sizing: border-box;

              &-container {
                display: flex;
                justify-content: space-between;
                align-items: center;
                padding: 10px;
                box-sizing: border-box;
                height: 100%;
              }


              &-actions {
                // display: none;
                width: 65px;

                &-btn.MuiButtonBase-root {
                  padding: 2px 7px;
                  width: 32px;
                  height: 32px;

                  & .MuiSvgIcon-root {
                    font-size: 18px;
                  }
                }
              }

              &:hover .title-list-actions {
                display: inline-block;
              }

              & .editable-title-container {
                flex: 1;
                box-sizing: border-box;
                height: 100%;
                position: relative;
                display: flex;
                align-items: center;
              
                .editable-title {
                  // height: 1.7rem;
                  color: #333;
              
                  flex-grow: 1;
                  font-size: 15px;
                  font-weight: 400;
                  line-height: 22px;

                  margin-bottom: 0;
                  padding: 7px 0;
                }
              
                .list-button {
                  height: 1.5rem;
                  border: none;
              
                  cursor: pointer;
              
                  transition: background-color 0.2s;
              
                  &:hover {
                    background-color: #ddd;
                  }
                }
              
                .menu-card {
                  position: absolute;
                  right: 0;
              
                  background-color: #fff;
                  border: 1px solid #ddd;
              
                  border-radius: 4px;
                  z-index: 999;
                  list-style-type: none;
                  cursor: default;
              
                  li {
                    color: #333;
                    font-size: 0.875rem;
                    border-bottom: 1px solid #ddd;
                    padding: 0.5rem 0.5rem 0.3rem;
              
                    &:hover {
                      background-color: #ddd;
                    }
                  }
                }
              }

              & .input-title-edit {
                flex: 1;

                & .input-title {
                  width: 100%;
                  box-sizing: border-box;
                  border: 1px solid #51A3FF;
                  border-radius: 8px;
                  padding: 8px;
                  font-size: 15px;
                  height: 33px;
                }
              }
            }

          
            .container-cards {
              padding: 0 10px;
              overflow-x: auto;
              // max-height: 55vh;
              // padding: 0 1rem;

              & .card-container {
                overflow-y: hidden;
                margin: 0.5rem 0;
                display: flex;
                flex-direction: column;
                gap: 8px;



                & .card-content {
                  // border-left: 2px solid;
                  min-height: 2.5rem;
                  cursor: pointer;
                
                  display: flex;
                  justify-content: space-between;
                  align-items: flex-start;
                  transition: filter 0.3s ease;


                
                  margin: 2px;
                
                  background: #fff;
                  border-radius: 8px;
                  // border-bottom: 1px solid #ccc;
                
                  position: relative;

                  &:hover {
                    outline: solid 2px;
                  }

                  &.dragged-item {
                    // background-color: gray !important;
                    filter: blur(1px);
                    opacity: 0.5;
                  }
                
                  .card-content-container {
                    width: 100%;
                    height: 100%;
                    // padding: 5px 0;
                
                    // display: flex;
                    justify-content: space-between;
                    align-items: flex-start;
                
                    font-size: 15px;
                
                    transition: background-color 0.1s;
                    position: relative;
                
                    div {
                      // padding: 0.7rem 0.5rem 0.5rem;
                
                      // max-width: 90%;
                      overflow: hidden;
                      word-wrap: break-word;
                    }

                    .card-content_img {
                      width: 100%;
                      padding: 0;
                      border-radius: 8px 8px 0 0;

                      img {
                        width: 100%;
                      }
                    }

                    .card-content_info {
                      display: flex;
                      justify-content: space-between;
                      align-items: center;
                      padding: 5px 10px 5px 10px;

                      &-title {
                        font-size: 14px;
                        color: #333;
                        // white-space: nowrap;
                        // overflow: hidden;
                        // text-overflow: ellipsis;
                        padding: 0;
                        flex: 1;
                        // height: 100%;
                        min-height: 20px;
                        line-height: 20px;

                        p {
                          // padding: 5px;

                          .MuiSvgIcon-root {
                            font-size: 18px;
                            color: #727272;
                            margin-top: 5px;
                          }
                        }

                          &-textarea {
                            width: 100%;
                            box-sizing: border-box;
                            // outline: unset;
                            // border: 1px solid #51A3FF;
                            // resize: none;
                            // min-height: 25px;
                            // border-radius: 5px;
                            // line-height: 25px;
                            // padding: 0 8px;
                            
                            // white-space: wrap;

                            .MuiInputBase-root {
                              padding: 5px !important;
                              font-size: 13px;
                              line-height: 20px;
                              color: #333;

                              fieldset.MuiOutlinedInput-notchedOutline {
                                border: 1px solid #51A3FF;
                                border-radius: 5px;
                              } 
                            }
                          }
                      }

                      // &-members {
                      //   padding: 0;
                      //   display: flex;
                      //   justify-content: end;
                      //   gap: 5px;
                        
                      //   &-member, .MuiAvatar-root {
                      //     padding: 0;
                      //     width: 30px;
                      //     height: 30px;
                      //     font-size: 13px;
                      //   }
                      // }
                    }

                    .card-content_details {
                      display: flex;
                      justify-content: end;
                      align-items: center;
                      gap: 10px;
                      padding: 5px 10px 5px 10px;

                      &-hasdescription {
                        font-size: 18px;
                        color: #b5b5b5;
                        // float: left;
                      }

                      &-buttons {
                        // float: right;
                        display: flex;
                        padding: 0;
                        overflow: unset;
                        gap: 14px;

                        &-files {
                          display: flex;
                          align-items: center;
                          color: #A8ADB2;
                          font-size: 13px;  

                          img {
                            margin-right: 4px;
                          }
                        }

                        &-comments {
                          display: flex;
                          align-items: center;
                          overflow: unset;
                          color: #A8ADB2;
                          font-size: 13px;
                          
                          img {
                            margin-right: 4px;
                          }
                        }

                        &-setting {
                          display: flex;
                          align-items: center;
                          overflow: unset;
                          color: #A8ADB2;
                          font-size: 13px;
                        }

                        .MuiSvgIcon-root {
                          color: #A8ADB2;
                          font-size: 18px;
                        }

                        .MuiButtonBase-root {
                          padding: 1px;
                        }
                      }
                    }

                    .card-content_edit {
                      display: none;
                      position: absolute;
                      right: 5px;
                      top: 5px;
                      align-items: center;
                      overflow: unset;
                      color: #A8ADB2;
                      font-size: 13px;

                      button.MuiButtonBase-root {
                        background-color: #fff;
                        padding: 3px;
                      }
                    }

                    .card-content_members {
                      width: 100%;
                      box-sizing: border-box;
                      padding: 5px 10px 5px 10px;
                      display: flex;
                      flex-wrap: wrap;
                      justify-content: end;
                      gap: 5px;
                      
                      
                      &-member, .MuiAvatar-root {
                        padding: 0;
                        width: 25px;
                        height: 25px;
                        font-size: 12px;
                      }
                    }

                    // .card-content_members {
                    //   display: flex;
                    //   justify-content: end;
                    //   gap: 5px;

                    //   .card-content_member {
                    //     padding: 0;
                    //   }
                    // }

                    // .card-content_tags {
                    //     display: flex;
                    //     gap: 6px;
                    //     width: 100%;
                    //     overflow: auto;
                    //     box-sizing: border-box;

                    //   .card-content_tag {
                    //     font-size: 12px;
                    //     background-color: #EBEDF1;
                    //     padding: 5px 10px;
                    //     display: inline-block;
                    //     color: #727272;
                    //     border-radius: 30px;

                    //   }
                    // }

                    .card-content_tags {
                      display: flex;
                      flex-wrap: wrap;
                      gap: 6px;
                      width: 100%;
                      overflow: auto;
                      box-sizing: border-box;
                      padding: 5px 10px 5px 10px;

                      &-tag {
                        font-size: 12px;
                        background-color: #EBEDF1;
                        padding: 5px 10px;
                        display: inline-block;
                        color: #727272;
                        border-radius: 30px;

                      }
                    }

                    .card-content_actions {
                      display: flex;
                      position: absolute;
                      right: 0;
                      top: 0;
                      opacity: 0;
                      visibility: hidden;
                      padding: 5px;
                      gap: 2px;

                      button.MuiButtonBase-root {
                        padding: 5px;
                        background-color: #fff;

                        &:hover {
                          background-color: #d7d6d6;
                        }

                        .MuiSvgIcon-root {
                          font-size: 18px;
                        }
                      }
                    }

                    
                    &:hover {
                      .card-content_actions {
                        opacity: 1;
                        visibility: visible;
                      }
                      
                      .card-content_edit {
                        display: flex;
                      }
                    }
                  }
                
                  .input-card-title {
                    width: 100%;
                    height: 100%;
                
                    padding: 0.7rem 0.5rem 0.5rem;
                
                    resize: none;
                    overflow: hidden;
                
                    border-color: transparent;
                    border-radius: 3px;
                    font-size: 15px;
                
                    transition: border-color 0.2s;
                
                    &:focus {
                      border: 1px solid #e22bba;
                    }
                  }
                }


              }
            }

          }

          .input-container {
            width: 100%;
            // height: 33px;

            .input-content {
              width: 100%;
              border-radius: 5px;
              box-sizing: border-box;
              height: 100%;
              padding: 0 10px;
              
              .add-backlog-btn.MuiButtonBase-root {
                // background-color: #3089dc;
                // background-color: #ffffff;
                width: 100%;
                padding: 10px;
                text-align: center;
                justify-content: start;
                color: #333;
                border-radius: 8px;
                text-transform: none;
                height: 33px;

                &:hover {
                  background-color: #091E4224;
                }

                .MuiSvgIcon-root {
                  font-size: 16px;
                  border: 1px solid #333;
                  border-radius: 50%;
                }
              }

              .add-backlog-btn.list.MuiButtonBase-root {
                background-color: #ffffff3d;
                color: #333;
                width: 100%;
                text-align: left;
                font-size: 15px;
                font-weight: 400;
                height: 38px;
                justify-content: flex-start;
                box-sizing: border-box;
                padding-left: 15px;
                -webkit-backdrop-filter: blur(8px);
                backdrop-filter: blur(8px);
              }

            }

            .input-card {
              background-color: #ebecf0;
              padding: 0 10px;
              border-radius: 8px;
              box-sizing: border-box;
              display: flex;
              flex-direction: column;
              gap: 7px;
          
            
              .input-card-container {
                width: 100%;
                margin: auto;
            
                .input-text {
                  width: 100%;
                  height: 4rem;
                  background-color: #fff;
                  padding: 7px;
                  font-size: 14px;
                  border: none;
                  /* border-bottom: 1px solid #ccc; */
                  resize: none;
                  box-sizing: border-box;
                  box-shadow: 0px 1px 1px #091E4240, 0px 0px 1px #091E424F;
                  border: unset !important;
                  outline: unset;
                  border-radius: 8px;
                }
              }
            
              .confirm {
                width: 100%;
                display: flex;
                align-items: center;
            
                .button-confirm {
                  height: 30px;
                  width: auto;
                  font-size: 14px;
                  background-color: #51A3FF;
                  cursor: pointer;
                  border: none;
                  border-radius: 7px;
                  color: #fff;
                  /* font-weight: bold; */
                  margin-right: 0.5rem;
                  padding: 0 10px;
                  box-sizing: border-box;
             
                }
            
                .button-cancel {
                  background-color: unset;
                  border: none;
                  cursor: pointer;
                  display: flex;
            
                  svg {
                    transition: color 0.3s;
                    color: rgba(0, 0, 0, 0.54);
                  }
                }
              }
            }

            &.list {
              .input-content {
                padding: 0;
              }

              .input-card {
                padding: 8px 10px;
              }
            }

          }

        }
    }
  }
}

.backlog-modal {
  
  &_container {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    max-width: 765px;
    max-height: 90%;
    overflow-y: auto;
    box-sizing: border-box;
    background-color: #F0F2F4;
    border-radius: 8px;
    padding: 25px;
  }

  &_header {
    width: 100%;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;

    &-title {
      width: 100%;

      &-input.MuiFormControl-root  {
        width: 100%;
        min-height: 28px;
        height: unset;
        border-radius: 8px;
        outline: 2px solid #51A3FF;
        background: #FFF;
        padding: 3px 10px;
        box-sizing: border-box;

        .MuiInputBase-root {
          padding: 0;
          min-height: 28px;
          height: unset;
        }

        .MuiInputBase-input {
          color: #333;
          font-size: 20px;
          font-weight: 700;
          min-height: 28px;
          line-height: 26px;
          font-family: 'OpenSans' !important;
        }

        .MuiOutlinedInput-notchedOutline {
          border: unset;
          display: none;
        }
      }
  
      &-text {
        color: #333;
        font-size: 20px;
        min-height: 30px;
        line-height: 26px;
        font-weight: 700;
        padding: 3px 0;
        font-family: 'OpenSans' !important;
      }

      &-list {
        color: #333;
        font-size: 14px;
        height: 22px;
        line-height: 22px;
        font-weight: 400;
        font-family: 'OpenSans' !important;

        span {
          text-decoration: underline;
        }
      }
    }

    &_btn {
      button.MuiButtonBase-root {
        padding: 5px;
      }
    }
  }


  &_content {
    display: flex;
    justify-content: space-between;
    gap: 20px;

    &_left {
      flex: 1 1 0%;
      display: flex;
      flex-direction: column;
      gap: 30px;
      padding-top: 30px;
  
      &_item {
        width: 100%;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        gap: 8px;

        &-title {
          width: 100%;
          display: flex;
          justify-content: space-between;

          &-text {
            flex: 1 1 0%;
            font-size: 16px;
            color: #333;
            font-weight: 600;
            line-height: 20px;
            font-family: 'OpenSans' !important;
          }

          &-action {
            button.MuiButtonBase-root {
              height: 32px;
              border-radius: 6px;
              background: #E4E7EA;
              color: #666;
              text-transform: capitalize;
              font-size: 13px;
              line-height: 19px;
            }
          }
        }

        &-input {
          display: flex;
          align-items: center;
          gap: 15px;

          &.description {
            flex-direction: column;
            align-items: start;
            gap: 10px;

            .btns{
              &-save.MuiButtonBase-root {
                height: 35px;
                padding: 10px 14px;
                color: #fff;
                border-radius: 30px;
                background: #51A3FF;
                font-size: 14px;
                font-family: 'OpenSans' !important;
                text-transform: capitalize;
              }

              &-cancel {
                height: 35px;
                padding: 10px 14px;
                color: #666;
                border-radius: 30px;
                background: transparent;
                font-size: 14px;
                font-family: 'OpenSans' !important;
                text-transform: capitalize;
              }
            }
          }

          &.checklist {
            flex-direction: column;
            align-items: start;
            gap: 15px;

            .item {
              // width: 100%;
              height: 22px;
              padding: 0 15px;
              box-sizing: border-box;

              &.checked {
                .MuiTypography-root {
                  text-decoration: line-through;
                }
              }
  
              .MuiSvgIcon-root {
                color: #ADABA8 !important;
                width: 18px;
                height: 18px;
              }
  
              .Mui-checked {
                .MuiSvgIcon-root {
                  color: #51A3FF !important;
                }
              }
  
              .MuiTypography-root {
                color: #333;
                font-size: 14px;
              }
            }

            button.MuiButtonBase-root{
              height: 35px;
              padding: 10px 14px;
              border-radius: 30px;
              background-color: #E4E7EA;
              color: #333;
              text-transform: capitalize;
              font-family: 'OpenSans' !important;
              font-size: 14px;
              font-weight: 400;
            }

            .btns{
              display: flex;
              gap: 10px;

              &-save.MuiButtonBase-root {
                height: 35px;
                padding: 10px 14px;
                color: #fff;
                border-radius: 30px;
                background: #51A3FF;
                font-size: 14px;
                font-family: 'OpenSans' !important;
                text-transform: capitalize;
              }

              &-cancel.MuiButtonBase-root {
                height: 35px;
                padding: 10px 14px;
                color: #666;
                border-radius: 30px;
                background: transparent;
                font-size: 14px;
                font-family: 'OpenSans' !important;
                text-transform: capitalize;
              }
            }
          }

          &-field.MuiFormControl-root  {
            width: 100%;
            min-height: 28px;
            height: unset;
            border: unset;
            border-radius: 8px;
            background: #FFF;
            // padding: 3px 10px;
            box-sizing: border-box;
    
            .MuiInputBase-root {
              padding: 5px 10px;
              min-height: 55px;
              height: unset;

              &:hover {
                .MuiOutlinedInput-notchedOutline {
                  border-color: #ECECEC !important;
                }
              }
              

              &.Mui-focused .MuiOutlinedInput-notchedOutline {
                border-color: #51A3FF !important;
              }


            }
    
            .MuiInputBase-input {
              color: #333;
              font-size: 14px;
              font-weight: 400;
              min-height: 55px;
              line-height: 22px;
              font-family: 'OpenSans' !important;

              &::placeholder {
                color: #333;
                font-size: 14px;
                opacity: 1;
                font-weight: 400;
                min-height: 55px;
                line-height: 22px;
                font-family: 'OpenSans' !important;
              }
            }
    
            .MuiOutlinedInput-notchedOutline {
              border-radius: 8px;
              border-color: #ECECEC;
            }
          }

          &-field.comment.MuiFormControl-root {
            min-height: 35px;
    
            .MuiInputBase-root {
              min-height: 35px;
              box-sizing: border-box;
            }
    
            .MuiInputBase-input {
              padding: 3px 0;
              min-height: 25px;
              line-height: 25px;

              &::placeholder {
                min-height: 25px;
                line-height: 25px;
              }
            }

          }

          &-user {
            width: 30px;
            height: 30px;

            img {
              width: inherit;
              height: inherit;
            }
          }
      
        }

        &-comments {
          display: flex;
          flex-direction: column-reverse;
          gap: 30px;
          margin-top: 10px;
        }

        &-comment {
          display: flex;
          flex-direction: column;
          gap: 5px;

          &-details {
            display: flex;
            justify-content: space-between;
            align-items: center;

            &-info {
              display: flex;
              gap: 15px;
              flex: 1;

              &-name {
                font-size: 14px;
                color: #333;
                font-weight: 600;
                font-family: "OpenSans" !important;
              }
  
              &-date {
                font-size: 13px;
                color: #333;
                font-weight: 400;
                font-family: "OpenSans" !important;
              }
            }

            &-delete {
              background-color: transparent;
              color: #333;
              font-family: 'OpenSans' !important;
              font-size: 13px;
              text-decoration: underline;
            }
          }

          &-text {
            background: #fff;
            border-radius: 8px;
            padding: 10px;
            border: 1px solid #ECECEC;
          }

          &-file-container {
            width: 100%;
            box-sizing: border-box;
            display: flex;
            flex-direction: column;
            gap: 8px;
          }

          &-file {
            display: flex;
            gap: 20px;
            margin-top: 10px;
  
            &-img {
              max-width: 100px;
              max-height: 100px;
              // flex: 0 0 150px;
  
              img {
                max-width: inherit;
                max-height: inherit;
              }
            }
  
            &-details {
              display: flex;
              flex-direction: column;
              gap: 5px;
  
              &-name {
                color: #333;
                font-family: 'OpenSans' !important;
                font-size: 14px;
                font-weight: 700;
                line-height: 22px;
              }
  
              &-btn {
                color: #333;
                font-family: 'OpenSans' !important;
                font-size: 14px;
                font-weight: 400;
                line-height: 22px;
                text-decoration-line: underline;
                cursor: pointer;
              }
            }
          }
        }

        &-file {
          display: flex;
          gap: 20px;

          &-img {
            max-width: 150px;
            max-height: 100px;
            // flex: 0 0 150px;

            img {
              max-width: inherit;
              max-height: inherit;
            }
          }

          &-details {
            display: flex;
            flex-direction: column;
            gap: 5px;

            &-name {
              color: #333;
              font-family: 'OpenSans' !important;
              font-size: 14px;
              font-weight: 700;
              line-height: 22px;
            }

            &-btn {
              color: #333;
              font-family: 'OpenSans' !important;
              font-size: 14px;
              font-weight: 400;
              line-height: 22px;
              text-decoration-line: underline;
              cursor: pointer;
            }
          }
        }
    
        &-attach {
          min-height: 100px !important;
          background-color: #f1f2f4 !important;
    
          .MuiDropzoneArea-text {
            font-size: 16px;
            margin: 15px 0;
          }
    
          img {
            width: 35px;
            height: 35px;
          }
        }

        &-progressbar {
          display: flex;
          gap: 20px;

          &-counter {
            width: 40px;
            color: #777;
            font-size: 13px;
          }

          .MuiSlider-root {
            color: #51A3FF !important;
            padding: 5px 0;
            height: 5px;

            .MuiSlider-rail {
              color: #999;
            }

            .MuiSlider-track {
              border: unset;
            }
          }

          .MuiSlider-thumb {
            width: 0;
            height: 0;
          }
        }
    
      }

    }

    &_right {
      width: 165px;
      display: flex;
      flex-direction: column;
      gap: 20px;

      &-list {
        display: flex;
        flex-direction: column;
        gap: 8px;

        &-item {
          p {
            color: #333;
            font-family: 'OpenSans' !important;
            font-size: 14px;
            font-weight: 600;
            line-height: 22px;
          }

          button.MuiButtonBase-root {
            width: 100%;
            height: 32px;
            line-height: 32px;
            padding: 8px 14px 8px 8px;
            border-radius: 6px;
            background: #E4E7EA;
            color: #666;
            font-size: 13px;
            font-weight: 500 !important;
            justify-content: start;
            text-transform: capitalize;

            .MuiButton-startIcon {
              margin-left: 0;
            }
          }
        }
      }

      &-popup {
        &-container {
          padding: 15px 0;
          width: 300px;
          display: flex;
          flex-direction: column;
          gap: 10px;
          
          .MuiInputBase-root {
            box-sizing: border-box;
          }
        }

        &-header {
          // display: flex;
          // justify-content: space-between;
          // align-items: center;
          padding: 0 15px;
          text-align: center;
          position: relative;

          p {
            color: #333;
            text-align: center;
            font-family: 'OpenSans' !important;
            font-size: 14px;
            font-weight: 600;
            line-height: 22px;
          }

          button.MuiButtonBase-root {
            position: absolute;
            right: 15px;
            top: 0;
            padding: 0;

            .MuiSvgIcon-root {
              font-size: 17px;
            }
          }
        }

        .MuiPaper-root {
          background-color: #fff;
          box-shadow: 0px 8px 12px #091E4226, 0px 0px 1px #091E424F;
        }

        .MuiPaper-root {
          border-radius: 8px;
          background: #FFF;
          box-shadow: 0px 4px 10px 1px rgba(0, 0, 0, 0.10);
        }
      
        &-assign {
          display: flex;
          flex-direction: column;
          // gap: 5px;
          width: 100%;
          box-sizing: border-box;
      
          &-item {
            width: 100%;
            height: 36px;
            padding: 0 15px;
            box-sizing: border-box;
      
            &:hover {
              background-color: rgba(81, 163, 255, 0.05);
            }
      
            & .MuiFormControlLabel-root {
              height: 100%;
              margin: 0;
              box-sizing: border-box;
              display: flex;
              align-items: center;
              flex-direction: row-reverse;
      
              & .MuiButtonBase-root {
                width: 36px;
                padding: 0;
              }
      
              & .MuiTypography-root {
                flex: 1 1 100%;
      
                div {
                  display: flex;
                  gap: 15px;
                  align-items: center;
      
                  img {
                    width: 26px;
                    height: 26px;
                  }
      
                  p {
                    color: #444;
                    font-size: 14px;
                  }
                }
              }
            }
          }
        }

        &-input {
          padding: 0 15px;
          height: 35px;
          
          &-field.MuiFormControl-root  {
            width: 100%;
            min-height: 28px;
            height: unset;
            border: unset;
            border-radius: 8px;
            background: #FFF;
            // padding: 3px 10px;
            box-sizing: border-box;
            height: 35px;
    
            .MuiInputBase-root {
              padding: 5px 10px;
              box-sizing: border-box;
              height: 35px;

              &:hover {
                .MuiOutlinedInput-notchedOutline {
                  border-color: #ECECEC !important;
                }
              }
              

              &.Mui-focused .MuiOutlinedInput-notchedOutline {
                border-color: #51A3FF !important;
              }


            }
    
            .MuiInputBase-input {
              color: #333;
              font-size: 14px;
              font-weight: 400;
              height: 35px;
              box-sizing: border-box;
              font-family: 'OpenSans' !important;
              padding: 0;

              &::placeholder {
                color: #333;
                font-size: 14px;
                opacity: 1;
                height: 35px;
                box-sizing: border-box;
                font-family: 'OpenSans' !important;
                padding: 0;
              }
            }
    
            .MuiOutlinedInput-notchedOutline {
              border-radius: 8px;
              border-color: #ECECEC;
            }
          }

        }

        &-color {
          display: flex;
          flex-direction: column;
          // gap: 5px;
          width: 100%;
          box-sizing: border-box;
      
          &-item {
            width: 100%;
            height: 36px;
            padding: 0 15px;
            box-sizing: border-box;
      
            &:hover {
              background-color: rgba(81, 163, 255, 0.05);
            }
      
            & .MuiFormControlLabel-root {
              height: 100%;
              margin: 0;
              box-sizing: border-box;
              display: flex;
              align-items: center;
              flex-direction: row-reverse;
      
              & .MuiButtonBase-root {
                width: 36px;
                padding: 0;
              }
      
              & .MuiTypography-root {
                flex: 1 1 100%;
      
                div {
                  display: flex;
                  gap: 15px;
                  align-items: center;
      
                  span {
                    width: 20px;
                    height: 20px;
                    display: inline-block;
                    border-radius: 50%;
                  }
      
                  p {
                    color: #333;
                    font-size: 14px;
                    font-family: 'OpenSans' !important;
                  }
                }
              }
            }
          }
        }

        &-tags {
          display: flex;
          flex-direction: column;
          gap: 10px;
          width: 100%;
          box-sizing: border-box;
      
          &-item {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 0 15px 0 5px;

            &:hover {
              .backlog-modal_content_right-popup-tags-item-btn {
                display: flex;
              }
            }

            &-label {
              height: 22px;
              padding: 0 15px;
              box-sizing: border-box;
              margin: 0 !important;
              padding: 0 !important;

              .MuiSvgIcon-root {
                color: #ADABA8 !important;
                width: 18px;
                height: 18px;
              }

              .Mui-checked {
                .MuiSvgIcon-root {
                  color: #51A3FF !important;
                }
              }

              .MuiTypography-root {
                color: #333;
                font-size: 14px;
              }
            }
            
            &-btn.MuiButtonBase-root {
              padding: 0 !important;
              display: none;
              height: 22px;

              .MuiSvgIcon-root {
                font-size: 17px;
              }
            }
          }
        }

        &-checklist {
          display: flex;
          flex-direction: column;
          gap: 20px;
          width: 100%;
          box-sizing: border-box;
          padding: 0 15px;


          &_form {
            width: 100%;
            box-sizing: border-box;

            &-title {
              color: #888;
              font-size: 14px;
              line-height: 22px;
            }

            .MuiInputBase-root {
              width: 100%;
              border: unset;
              padding-right: 10px;
              padding-left: 12px;
              border-radius: 8px;
              background: #FFF;
              box-sizing: border-box;
              height: 35px;


              .MuiInputBase-input {
                height: 100%;
                padding: 0;
                font-family: 'OpenSans' !important;
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                color: #333;
                
                &::placeholder {
                  color: #888;
                }
              }

              .MuiOutlinedInput-notchedOutline {
                border: 1px solid #ECECEC;
              }

              &:hover {
                .MuiOutlinedInput-notchedOutline {
                  border: 1px solid #ECECEC;
                }
              }

              &.Mui-focused {
                .MuiOutlinedInput-notchedOutline {
                  border: 2px solid #51A3FF;
                }
              }

              .MuiInputAdornment-root {
                .MuiButtonBase-root {
                  padding: 3px;

                  .MuiSvgIcon-root {
                    font-size: 14px;
                  }
                }
              }
            }
          }

          &_btn.MuiButtonBase-root  {
            height: 35px;
            width: max-content;
            color: #fff;
            text-transform: capitalize;
            padding: 10px 14px;
            border-radius: 30px;
            background: #51A3FF;

            &:hover {
              color: #fff;
              background: #51A3FF;
            }
          }

        }

        &-addtags {
          margin-top: 5px;
          padding: 0 15px;

          &_form {
            width: 100%;
            box-sizing: border-box;
            margin-bottom: 15px;

            .MuiInputBase-root {
              width: 100%;
              border: unset;
              padding-right: 10px;
              padding-left: 12px;
              border-radius: 8px;
              background: #FFF;
              box-sizing: border-box;
              height: 35px;


              .MuiInputBase-input {
                height: 100%;
                padding: 0;
                font-family: 'OpenSans' !important;
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                color: #333;
                
                &::placeholder {
                  color: #888;
                }
              }

              .MuiOutlinedInput-notchedOutline {
                border: 1px solid #ECECEC;
              }

              &:hover {
                .MuiOutlinedInput-notchedOutline {
                  border: 1px solid #ECECEC;
                }
              }

              &.Mui-focused {
                .MuiOutlinedInput-notchedOutline {
                  border: 2px solid #51A3FF;
                }
              }

              .MuiInputAdornment-root {
                .MuiButtonBase-root {
                  padding: 3px;

                  .MuiSvgIcon-root {
                    font-size: 14px;
                  }
                }
              }
            }
          }

          &_btn.MuiButtonBase-root {
            width: 100%;
            height: 35px;
            text-align: center;
            border-radius: 8px;
            border: 1px solid #ECECEC;
            background: #E4E7EA;
            color: #666;

            font-family: 'OpenSans' !important;
            font-size: 14px;
            font-weight: 600 !important;
            text-transform: none;

            &:hover {
              border: 1px solid #ECECEC;
              background: #E4E7EA;
              color: #666;
            }
          }
        }
      }
    }
  }

  .view-modal {
    display: flex;
    flex-direction: column;
    gap: 10px;
    max-height: 60vh;
    overflow-y: auto;
    outline: none;

    .backlog-modal_item {
      padding: 7px 0;
    }
    
    .backlog-modal_item:first-child {
      border-bottom: 1px solid #e5e5e5;
    }

    .backlog-modal_item:last-child {
      text-align: center;
    }

    &_title {
      font-size: 22px;
      color: #000;
      word-break: break-all;
    }

    &_description {
      font-size: 18px;
      word-break: break-all;
    }

    &_tags {
      display: flex;
      gap: 6px;

      span {
        font-size: 12px;
        background-color: #EBEDF1;
        padding: 5px 10px;
        display: inline-block;
        color: #727272;
        border-radius: 30px;
      }
    }

    &_image {
      width: 100%;
    }
  }
}

.comments_popover {

  .MuiPaper-root {
    max-width: 400px;
    min-width: 400px;
    max-height: 450px;
    border-radius: 8px;
    overflow: hidden;
  }

  &-container {
    max-width: 400px;
    min-width: 100%;
    max-height: 450px;
    box-sizing: border-box;
    padding-bottom: 10px;
    display: flex;
    flex-direction: column;
    // gap: 15px;
    position: relative;
  }

  &-list {
    display: flex;
    flex-direction: column;
    gap: 30px;
    height: 100%;
    padding: 20px;
    overflow-y: auto;
    overflow-x: hidden;
    box-sizing: border-box;
  }

  &-item {
    display: flex;
    align-items: start;
    gap: 10px;


    &-avatar {
      .MuiAvatar-root {
        width: 35px;
        height: 35px;
      }
    }

    &-comment {
      display: flex;
      flex-direction: column;
      gap: 10px;
      width: 100%;

      &-info {
        display: flex;
        justify-content: space-between;
        padding-top: 5px;

        &-name {
          font-size: 15px;
          font-weight: 400;
          color: #333;
          text-transform: capitalize;
        }

        &-date {
          font-size: 13px;
          color: #BBBBBB;
        }
      }

      &-text {
        font-size: 13px;
        color: #333;
        line-height: 21px;
        word-break: break-all;
      }
    }
  }

  &-footer {
    padding: 20px;
    border-top: 1px solid #ECECEC;
    padding-top: 20px;
    display: flex;
    flex-direction: column;
    gap: 15px;
    position: sticky;
    bottom: 0;
    background-color: #ffffff;
    padding-bottom: 10px;

    &-input {
      width: 100%;

      .MuiInputBase-root {
        border-radius: 50px;
        padding: 10px 20px;

        &:hover .MuiOutlinedInput-notchedOutline {
          border-color: #1976d2;
        }
      }
    }

    &-submit {
      text-align: right;
      transition: all 0.3s ease;
      
      &_btn.MuiButtonBase-root  {
        background-color: #3089dc;
        color: white;
        border-radius: 50px;
        text-transform: capitalize;

        &:hover {
          background-color: #3089dc;
          color: white;
          border-radius: 50px;
        }
      }
    }
  }
}

.cloud-page__header_tasks-page_details {
  text-align: right;
  width: 100%;
  
  &-close {
    text-align: right;
  }

}


.deletepopup-container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.deletepopup {
  width: 80%;
  max-width: 340px;
  display: flex;
  flex-direction: column;
  gap: 25px;
  height: auto;
  background-color: white;
  border-radius: 12px;
  padding: 20px;
  box-sizing: border-box;

  &-header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    &-title {
      color: #333;
      font-size: 16px;
      font-weight: 500;
      line-height: 22px;
    }
   
    &-btn {
      button.MuiButtonBase-root {
        padding: 0;
      }
    }
  }

  &-content {
    p {
      color: #333;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 22px;
    }
  }

  &-actions {
    &-btn.MuiButtonBase-root {
      width: 100%;
      height: 40px;
      padding: 10px 24px;
      text-align: center;
      border-radius: 30px;
      border: 1px solid #FD5162;
      background-color: transparent;

      color: #FD5162;
      font-family: 'OpenSans' !important;
      font-size: 14px;
      font-weight: 600;
      text-transform: capitalize;

      &:hover {
        background-color: transparent;
      }
    }
  }
}



