@import "../../../styles/sass/variables.scss";

.todos-page {
    width: 100%;
    // padding: 0 10px 10px 10px;
    padding: 0 5px;
    box-sizing: border-box;
    height: 100%;

    &-main {
        width: 100%;
        height: 100%;
        padding: 30px 20px 40px 20px;
        box-sizing: border-box;
        border-radius: 8px;
        background: #FFF;

        &_header {
            margin-bottom: 15px;

            &-filter {
                display: flex;
                justify-content: space-between;

                &-project {
                    & button.MuiButtonBase-root {
                        color: #333;
                        font-size: 13px;
                        text-transform: capitalize;
                        font-weight: 400 !important;
                        padding: 0;

                        &:hover {
                            background-color: unset;
                        }

                        img {
                            width: 18px;
                            height: 18px;
                            margin-left: 8px;
                        }
                    }
                }

                &-actions {
                    display: flex;
                    gap: 12px;

                    &-btn.MuiButtonBase-root  {
                        padding: 8px 14px 8px 8px;
                        border-radius: 6px;
                        background-color: #F5F5F5;
                        color: #666;
                        font-size: 13px;
                        line-height: 19px;
                        text-transform: capitalize;
                        height: 28px;

                        &:hover {
                            background-color: #F5F5F5;
                        }

                        img {
                            width: 16px;
                            height: 16px;
                            margin-right: 3px;
                        }
                    }
                }
            }

            &-task {
                padding: 0 14px;
                margin-top: 20px;
                display: flex;
                justify-content: space-between;

                &-add {
                    &-btn.MuiButtonBase-root  {
                        border-radius: 6px;
                        color: #51A3FF;
                        font-size: 14px;
                        text-transform: capitalize;
                        height: 28px;
                        padding: 0;

                        &:hover {
                            background-color: unset;
                        }

                        img {
                            width: 16px;
                            height: 16px;
                            margin-right: 9px;
                        }
                    }
                }

                &-sort {
                    &-btn.MuiButtonBase-root  {
                        color: #444;
                        font-size: 13px;
                        text-transform: capitalize;
                        height: 28px;
                        padding: 0 8px;

                        &:hover {
                            background-color: unset;
                        }

                        img {
                            width: 18px;
                            height: 18px;
                        }
                    }

                    &-item.MuiButtonBase-root {
                        color: #333;
                        font-size: 13px;
                        text-transform: capitalize;
                        font-weight: 400 !important;
                        padding: 0;

                        &:hover {
                            background-color: unset;
                        }

                        img {
                            width: 18px;
                            height: 18px;
                            margin-left: 8px;
                        }
                    }
                }
            }

            &-input {
                margin-top: 15px;

                .MuiFormControl-root {
                    width: 100%;
                    background-color: #F8FAFC;
                    height: 40px;
                    box-sizing: border-box;

                    .MuiInputBase-root {
                        height: 40px;
                        box-sizing: border-box;

                        &:hover {
                            .MuiOutlinedInput-notchedOutline {
                                border-color: #51A3FF;
                            }
                        }

                        &.Mui-focused {
                            .MuiOutlinedInput-notchedOutline {
                                border-color: #51A3FF;
                            }
                        }

                        .MuiInputBase-input {
                            padding: 0;
                            color: #333;
                            font-size: 14px;

                            &::placeholder {
                                color: #ACACAC;
                                font-size: 14px;
                                opacity: 1;
                            }
                        }
                    }

                    .MuiOutlinedInput-notchedOutline {
                        border-radius: 8px;
                        border: 1px solid #ECECEC;
                        height: 45px;
                        box-sizing: border-box;
                    }
                }
            }
        }

        &_list {
            width: 100%;
            display: flex;
            flex-direction: column;
            height: calc(100% - 60px);
            overflow: auto;

            &-task {
                width: 100%;
                display: flex;
                flex-direction: column;
                min-height: 50px;
                box-sizing: border-box;
                border-top: 1px solid #E8E7E4;
                padding: 10px 14px;
                box-sizing: border-box;
                justify-content: center;

                // &:first-child {
                //     border-top: 1px solid #E8E7E4;
                // }

                &-info {
                    width: 100%;
                    display: flex;
                    align-items: center;

                    &-checkbox {
                        width: 25px;
                        margin-right: 10px !important;
                        padding: 0 !important;

                        img {
                            width: 18px;
                            height: 18px;
                        }
                    }

                    &-title {
                        flex: 1 1 100%;
                        overflow: hidden;
                        display: flex;
                        flex-direction: column;
                        gap: 8px;

                        p {
                            width: 100%;
                            white-space: nowrap;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            color: #333;
                            font-size: 14px;

                            &.done {
                                color: #ADABA8;
                                text-decoration: line-through;
                            }
                        }

                        &-date {
                            display: flex;
                            gap: 5px;
                            align-items: center;
                            color: #51A3FF;
                            font-family: 'OpenSans' !important;
                            font-size: 13px;

                            img {
                                width: 16px;
                                height: 16px;
                            }
                        }
                    }

                    &-members {
                        margin-right: 18px;

                        .MuiAvatarGroup-root {
                            .MuiAvatar-root {
                                width: 26px;
                                height: 26px;
                                font-size: 13px;
                                box-sizing: border-box !important;
                            }
                        }
                    }

                    &-numbers {
                        margin-right: 20px;
                        color: #ADABA8;
                        font-size: 14px;
                        white-space: nowrap;
                        text-wrap: nowrap;
                    }

                    &-action {
                        width: 18px;
                        box-sizing: border-box;
                        margin-right: 5px;

                        button.MuiButtonBase-root {
                            padding: 5px;
                        }

                        &-item.MuiButtonBase-root {
                            color: #333;
                            font-size: 13px;
                            text-transform: capitalize;
                            font-weight: 400 !important;
                            padding: 0;

                            &:hover {
                                background-color: unset;
                            }

                            img {
                                width: 18px;
                                height: 18px;
                                margin-left: 8px;
                            }
                        }
                    }
                }

                &-input {
                    margin-top: 15px;

                    .MuiFormControl-root {
                        width: 100%;
                        background-color: #F8FAFC;
                        height: 40px;
                        box-sizing: border-box;

                        .MuiInputBase-root {
                            height: 40px;
                            box-sizing: border-box;

                            &:hover {
                                .MuiOutlinedInput-notchedOutline {
                                    border-color: #51A3FF;
                                }
                            }

                            &.Mui-focused {
                                .MuiOutlinedInput-notchedOutline {
                                    border-color: #51A3FF;
                                }
                            }

                            .MuiInputBase-input {
                                padding: 0;
                                color: #333;
                                font-size: 14px;

                                &::placeholder {
                                    color: #ACACAC;
                                    font-size: 14px;
                                    opacity: 1;
                                }
                            }
                        }

                        .MuiOutlinedInput-notchedOutline {
                            border-radius: 8px;
                            border: 1px solid #ECECEC;
                            height: 45px;
                            box-sizing: border-box;
                        }
                    }
                }
            }

            & .accordion {
                box-shadow: unset;
                border: unset;
                background-color: unset;
                box-sizing: border-box;

                &.Mui-expanded {
                    margin: 0;

                    .accordion-summary {
                        min-height: unset;
                    }
                }

                &::before {
                    content: unset;
                }

                &-summary {
                    padding: 0;
                    flex-direction: row-reverse;
                    border-top: 1px solid #E8E7E4;
                    min-height: 50px;
                    box-sizing: border-box;

                    &.Mui-focusVisible {
                        background-color: unset;
                    }

                    .MuiAccordionSummary-content {
                        margin: 0;
                        overflow: hidden;

                        .todos-page-main_list-task-info {
                            border-top: unset;
                        }
                    }

                    .MuiAccordionSummary-expandIconWrapper {
                        position: absolute;
                        left: -3px;

                        &.Mui-expanded {
                            transform: rotate(90deg);
                        }
                    }
                }

                &-details {
                    padding: 0 15px;
                }
            }
        }
    }

    &-modal {
        display: flex;
        align-items: center;
        justify-content: center;
    }
}

.cloud-page__header-todos {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;

    &_info {
        display: flex;
        align-items: center;

        &-icon {
            width: 45px;
            height: 45px;

            img {
                width: inherit;
                height: inherit;
            }
        }

        &-title {
            color: #000;
            font-size: 20px;
            font-weight: 400;
            margin-left: 9px;
        }

        &-search {
            width: 310px;
            display: flex;
            align-items: center;
            margin-left: 68px;

            &_wrapper {
                display: flex;
                gap: 10px;
            }

            .MuiFormControl-root {
                width: 100%;
            }

            &_container {
                position: relative;
                color: #666666;
                margin-left: 0;
                width: 100%;
                // margin-right: 10px;
                display: flex;
                align-items: center;
                // background: rgba(240, 242, 245, 0.8);
                background-color: #ffffff80;
                border-radius: 8px;
                transform: translateX(0);
                transition: all 0.3s ease;
                width: auto;
                padding: 0 10px;

                label img {
                  width: 18px;
                }
            }

            &-input {
                width: 100%;
                height: 36px;
                font-size: 15px;
                background-color: transparent;
                padding: 0 8px;
                line-height: 33px;
                border: unset;
                transition: all 0.3s ease;
                color: #74737B;
                transform: translateX(0);

                &::placeholder {
                  color: #74737B;

                }
            }
        }
    }

    &_close {
        text-align: right;
    }
}
