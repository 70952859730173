.invoices-filterpopup {
    width: 320px;
    padding: 20px;
    box-sizing: border-box;

    &-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 10px 0;

        &-title {
            display: flex;
            align-items: center;
            gap: 5px;
            font-size: 20px;
            color: #51A3FF;

            p {
                font-family: 'OpenSans' !important;
            }
        }
        
        &-btn {
            .MuiButtonBase-root {
                color: #51A3FF;
            }
        }
    }

    &-list {
        padding: 10px 0;
        display: flex;
        flex-direction: column;
        gap: 20px;
    }

    &-item {
        &-title {
            font-size: 13px;
            display: flex;
            align-items: center;
            gap: 5px;
            margin-bottom: 5px;
            color: #444;
            font-family: 'OpenSans' !important;
        }

         

        & .MuiInputBase-root {
            width: 100%;
            border-radius: 8px;
            background-color: #F8FAFC;
            height: 35px;
            box-sizing: border-box;

            input {
                font-size: 13px;
                color: #444;
                padding: 10px;
                font-family: 'OpenSans' !important;
                height: 35px !important;
                box-sizing: border-box;

                &::placeholder {
                    color: #444;
                    opacity: 1;
                    font-size: 13px;
                }
            }

            .MuiOutlinedInput-notchedOutline {
                border-radius: 8px;
                border-color: #ECECEC;
            }

            &:hover .MuiOutlinedInput-notchedOutline {
                border-color: #1976d2;
            }
        }

        &-select {
            width: 100%;

            .MuiSelect-select {
                padding: 10px;
                // background-color: #F8FAFC;
                color: #444;
                font-size: 13px;
            }

            .MuiOutlinedInput-notchedOutline  {
                border-radius: 8px;
                border-color: #ECECEC;
            }

            .MuiSvgIcon-root {
                color: #ACACAC;
            }

            &.MuiInputBase-root:hover .MuiOutlinedInput-notchedOutline {
                border-color: #1976d2;
            }
        }


        &-double {
            display: flex;
            gap: 10px;

            .MuiInputBase-adornedStart {
                padding-left: 10px;

                input {
                    box-sizing: border-box;
                    padding-top: 0;
                    padding-bottom: 0;
                    height: 100%;
                    border-left: 1px solid #ECECEC;
                }

                .MuiTypography-body1 {
                    font-size: 14px;
                    color: #ACACAC;
                }
            }


        }
    }

    &-btn {
        display: flex;
        justify-content: end;
        gap: 10px;

        &-submit.MuiButtonBase-root {
            text-transform: none;
            color: #fff;
            background-color: #51A3FF;
            padding: 10px 24px;
            border-radius: 30px;
            font-size: 14px;
            margin-top: 20px;
            font-family: 'OpenSans' !important;
            height: 40px;
            box-sizing: border-box;

            &:hover {
                text-transform: none;
                color: #fff;
                background-color: #51A3FF;
                padding: 10px 24px;
                border-radius: 30px;
            }
        }

        
        &-reset.MuiButtonBase-root {
            text-transform: none;
            color: #888888;    
            background-color: #fff;
            padding: 10px 24px;
            border-radius: 30px;
            border: 1px solid #cdcdcd;
            font-size: 14px;
            margin-top: 20px;
            font-family: 'OpenSans' !important;
            height: 40px;
            box-sizing: border-box;

            &:hover {
                text-transform: none;
                color: #888888;    
                background-color: #fff;
                padding: 10px 24px;
                border-radius: 30px;
                border: 1px solid #cdcdcd;
            }
        }
    }
}

.invoices-addnewpopup {
    width: 80%;
    max-width: 580px;
    height: auto;
    max-height: 70vh;
    overflow-y: auto;
    background-color: white;
    border-radius: 8px;
    padding: 40px;
    box-sizing: border-box;

    &-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 0 10px 0;
        border-bottom: 1px solid #ECECEC;


        &-title {
            font-size: 20px;
            display: flex;
            align-items: center;
            gap: 5px;
            color: #51A3FF;
            font-family: 'OpenSans' !important;
        }
        
        &-btn {
            .MuiButtonBase-root {
                color: #51A3FF;
            }
        }
    }

    &-list {
        padding: 20px 0;
        display: flex;
        flex-direction: column;
        // gap: 20px;
    }

    &-item {
        padding-bottom: 20px;

        &.pb-0 {
            padding-bottom: 0;
        }

        &.flex {
            display: flex;
            align-items: center;
            justify-content: space-between;
        }

        &-title {
            font-size: 14px;
            color: #444;
            width: auto;
            font-family: 'OpenSans' !important;

        }

        .MuiFormControl-root {
            width: 80%;
            max-width: 380px;
        }

        input {
            height: 35px;
            box-sizing: border-box;
            font-family: 'OpenSans' !important;
            font-size: 14px;
            color: #444;
            border-radius: 8px;
        }

        &-input {

            & .MuiInputBase-root {
                width: 100%;
                border-radius: 8px;
                background-color: #F8FAFC;
    
                input {
                    padding: 10px;
                }
    
                .MuiOutlinedInput-notchedOutline {
                    border-radius: 8px;
                    border-color: #ECECEC;
                }
    
                &:hover .MuiOutlinedInput-notchedOutline {
                    border-color: #1976d2;
                }
            }
        }

        &-project_selection {
            width: 100%;
            text-align: right;
        }

        &-select {
            width: 100%;

            .MuiSelect-select {
                height: 35px !important;
                box-sizing: border-box;
                padding: 10px;
                background-color: #F8FAFC;
                line-height: 1;
                font-family: 'OpenSans' !important;
                font-size: 14px;
                color: #444;
                border-radius: 8px;
            }

            .MuiOutlinedInput-notchedOutline  {
                border-radius: 8px;
                border-color: #ECECEC;
            }

            .MuiSvgIcon-root {
                color: #ACACAC;
            }

            &.MuiInputBase-root:hover .MuiOutlinedInput-notchedOutline {
                border-color: #1976d2;
            }
        }

        &-date {
            width: 80%;
            max-width: 380px;

            .MuiFormControl-root {
                width: 100%;

                .MuiInputBase-input {
                    padding: 10px;
                    background-color: #F8FAFC;
                }

                .MuiOutlinedInput-notchedOutline {
                    border-radius: 8px;
                    border-color: #ECECEC;
                }

                .MuiInputBase-root:hover .MuiOutlinedInput-notchedOutline {
                    border-color: #1976d2;
                }
            }
        }

        &.switch {
            display: flex;
            align-items: center;
            justify-content: space-between;

            p {
                cursor: pointer;
            }
        }

        &.tags {
            display: flex;
            flex-direction: column;
            gap: 5px;

            .MuiInputBase-root {
                height: 35px !important;
                box-sizing: border-box;
                width: 100%;
                // max-width: 380px;

                &:hover .MuiOutlinedInput-notchedOutline {
                    border-color: #1976d2;
                }
            }

            .MuiSelect-select {
                background-color: #F8FAFC;
                padding: 10px;
                font-family: "OpenSans" !important;
                font-size: 14px;
                color: #444;
                height: 35px;
                box-sizing: border-box;
                line-height: 15px;
                border-radius: 8px;
            }

            .MuiOutlinedInput-notchedOutline {
                border-radius: 8px;
                border-color: #ECECEC;
            }
        }

        &.notes {
            display: flex;
            flex-direction: column;
            gap: 5px;

            .MuiFormControl-root {
                max-width: unset;
                width: 100%;
            }

            & .MuiInputBase-root {
                border-radius: 8px;
                background-color: #F8FAFC;
                width: 100%;
                min-height: 100px;
                align-items: start;
    
                input {
                    padding: 10px;
                }
    
                .MuiOutlinedInput-notchedOutline {
                    border-radius: 8px;
                    border-color: #ECECEC;
                }
    
                &:hover .MuiOutlinedInput-notchedOutline {
                    border-color: #1976d2;
                }
            }
        }

        &.terms {
            display: flex;
            flex-direction: column;
            gap: 5px;
            padding-bottom: 30px;

            .MuiFormControl-root {
                max-width: unset;
                width: 100%;
            }

            & .MuiInputBase-root {
                border-radius: 8px;
                background-color: #F8FAFC;
                width: 100%;
                min-height: 100px;
                align-items: start;
    
                input {
                    padding: 10px;
                }
    
                .MuiOutlinedInput-notchedOutline {
                    border-radius: 8px;
                    border-color: #ECECEC;
                }
    
                &:hover .MuiOutlinedInput-notchedOutline {
                    border-color: #1976d2;
                }
            }
        }

        .MuiPaper-root {
            font-size: 13px;
            width: 100%;
            padding: 8px;
            color: #93742A;
            background-color: #FCF6EC;
            border-color: #AB8C3F;
            border-radius: 8px;
            height: 50px;
            box-sizing: border-box;

            .MuiAlert-message {
                font-family: 'OpenSans' !important;
            }
        }

        .MuiToggleButtonGroup-root {
            justify-content: end;
            padding: 10px 0;

            .MuiButtonBase-root {
                background-color: transparent;
                border: unset;
                padding-top: 0;
                padding-bottom: 0;
                text-transform: none;
                font-size: 13px;
                color: #444;

                &:last-child {
                    border-left: 1px solid #51A3FF;
                    padding-right: 0;
                }

                &.Mui-selected {
                    color: #51A3FF;
                }

                &:hover {
                    background-color: unset;
                }
            }
        }
    }

    &-btn {
        display: flex;
        justify-content: end;
        gap: 10px;

        &-submit.MuiButtonBase-root {
            text-transform: none;
            color: #fff;
            background-color: #51A3FF;
            padding: 10px 24px;
            border-radius: 30px;
            font-size: 14px;
            margin-top: 20px;
            font-family: 'OpenSans' !important;
            height: 40px;
            box-sizing: border-box;

            &:hover {
                text-transform: none;
                color: #fff;
                background-color: #51A3FF;
                padding: 10px 24px;
                border-radius: 30px;
            }
        }

        
        &-reset.MuiButtonBase-root {
            text-transform: none;
            color: #888888;    
            background-color: #fff;
            padding: 10px 24px;
            border-radius: 30px;
            border: 1px solid #cdcdcd;
            font-size: 14px;
            margin-top: 20px;
            font-family: 'OpenSans' !important;
            height: 40px;
            box-sizing: border-box;

            &:hover {
                text-transform: none;
                color: #888888;    
                background-color: #fff;
                padding: 10px 24px;
                border-radius: 30px;
                border: 1px solid #cdcdcd;
            }
        }
    }
}

.invoices-deletepopup {
    width: 80%;
    max-width: 390px;
    height: auto;
    background-color: white;
    border-radius: 8px;
    padding: 30px;
    box-sizing: border-box;

    &-header {
        display: flex;
        flex-direction: column;
        text-align: center;
        padding: 0 0 10px 0;
        gap: 15px;
        // border-bottom: 1px solid #ECECEC;


        &-title {
            text-align: center;
            font-size: 20px;
            font-weight: 400;
            color: #444;
        }

        &-subtitle {
            font-size: 14px;
            text-align: center;
            color: #888888;
        }
        
        &-btn {
            .MuiButtonBase-root {
                color: #51A3FF;
            }
        }
    }

    &-content {
        display: flex;
        gap: 10px;
        justify-content: center;
        padding-top: 50px;

        &-btn {
            display: flex;
            justify-content: end;
            gap: 10px;

            &:first-child {
                .MuiButtonBase-root:first-child {
                    border: 1px solid #ECECEC;
                    color: #888888;
                    text-transform: none;
                    font-size: 14px;
                    padding: 8px 20px;
                    border-radius: 50px;
                }
            }

            &:last-child {
                .MuiButtonBase-root:first-child {
                    border: 1px solid #FD5162;
                    color: #FD5162;
                    text-transform: none;
                    font-size: 14px;
                    padding: 8px 20px;
                    border-radius: 50px;

                    &:hover {
                        color: #fff;
                        background-color: #FD5162;
                    }
                }
            }
        }
    }
}

.invoices-editinvoicepopup {
    width: 80%;
    max-width: 630px;
    height: auto;
    max-height: 70vh;
    overflow-y: auto;
    background-color: white;
    border-radius: 8px;
    padding: 40px;
    box-sizing: border-box;

    &-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 0 10px 0;
        border-bottom: 1px solid #ECECEC;


        &-title {
            font-size: 20px;
            display: flex;
            align-items: center;
            gap: 5px;
            color: #51A3FF;
            font-family: 'OpenSans' !important;
        }
        
        &-btn {
            .MuiButtonBase-root {
                color: #51A3FF;
            }
        }
    }

    &-info {
        padding: 20px 0;
        display: flex;
        justify-content: end;

        &-creator {
            padding-right: 10px;
            color: #888888;
            font-size: 12px;

            span {
                font-weight: 500;
            }
        }

        &-date {
            padding-left: 10px;
            color: #888888;
            font-size: 12px;
            border-left: 1px solid #888888;
        }
    }

    &-list {
        padding: 20px 0;
        display: flex;
        flex-direction: column;
        // gap: 20px;
    }

    &-item {
        padding-bottom: 20px;

        &.pb-0 {
            padding-bottom: 0;
        }

        &.flex {
            display: flex;
            align-items: center;
            justify-content: space-between;
        }

        &-title {
            font-size: 14px;
            color: #444;
            width: auto;
            font-family: 'OpenSans' !important;

        }

        .MuiFormControl-root {
            width: 80%;
            max-width: 380px;
        }

        input {
            height: 35px;
            box-sizing: border-box;
            font-family: 'OpenSans' !important;
            font-size: 14px;
            color: #444;
            border-radius: 8px;
        }

        &-input {

            & .MuiInputBase-root {
                width: 100%;
                border-radius: 8px;
                background-color: #F8FAFC;
    
                input {
                    padding: 10px;
                }
    
                .MuiOutlinedInput-notchedOutline {
                    border-radius: 8px;
                    border-color: #ECECEC;
                }
    
                &:hover .MuiOutlinedInput-notchedOutline {
                    border-color: #1976d2;
                }
            }
        }

        &-project_selection {
            width: 100%;
            text-align: right;
        }

        &-select {
            width: 100%;

            .MuiSelect-select {
                height: 35px !important;
                box-sizing: border-box;
                padding: 10px;
                background-color: #F8FAFC;
                line-height: 1;
                font-family: 'OpenSans' !important;
                font-size: 14px;
                color: #444;
                border-radius: 8px;
            }

            .MuiOutlinedInput-notchedOutline  {
                border-radius: 8px;
                border-color: #ECECEC;
            }

            .MuiSvgIcon-root {
                color: #ACACAC;
            }

            &.MuiInputBase-root:hover .MuiOutlinedInput-notchedOutline {
                border-color: #1976d2;
            }
        }

        &-date {
            width: 80%;
            max-width: 380px;

            .MuiFormControl-root {
                width: 100%;

                .MuiInputBase-input {
                    padding: 10px;
                    background-color: #F8FAFC;
                }

                .MuiOutlinedInput-notchedOutline {
                    border-radius: 8px;
                    border-color: #ECECEC;
                }

                .MuiInputBase-root:hover .MuiOutlinedInput-notchedOutline {
                    border-color: #1976d2;
                }
            }
        }

        &.switch {
            display: flex;
            align-items: center;
            justify-content: space-between;

            p {
                cursor: pointer;
            }
        }

        &.tags {
            display: flex;
            flex-direction: column;
            gap: 5px;

            .MuiInputBase-root {
                height: 35px !important;
                box-sizing: border-box;
                width: 100%;
                // max-width: 380px;

                &:hover .MuiOutlinedInput-notchedOutline {
                    border-color: #1976d2;
                }
            }

            .MuiSelect-select {
                background-color: #F8FAFC;
                padding: 10px;
                font-family: "OpenSans" !important;
                font-size: 14px;
                color: #444;
                height: 35px;
                box-sizing: border-box;
                border-radius: 8px;
            }

            .MuiOutlinedInput-notchedOutline {
                border-radius: 8px;
                border-color: #ECECEC;
            }
        }

        &.notes {
            display: flex;
            flex-direction: column;
            gap: 5px;

            .MuiFormControl-root {
                max-width: unset;
                width: 100%;
            }

            & .MuiInputBase-root {
                border-radius: 8px;
                background-color: #F8FAFC;
                width: 100%;
                min-height: 100px;
                align-items: start;
    
                input {
                    padding: 10px;
                }
    
                .MuiOutlinedInput-notchedOutline {
                    border-radius: 8px;
                    border-color: #ECECEC;
                }
    
                &:hover .MuiOutlinedInput-notchedOutline {
                    border-color: #1976d2;
                }
            }
        }

        &.terms {
            display: flex;
            flex-direction: column;
            gap: 5px;
            padding-bottom: 30px;

            .MuiFormControl-root {
                max-width: unset;
                width: 100%;
            }

            & .MuiInputBase-root {
                border-radius: 8px;
                background-color: #F8FAFC;
                width: 100%;
                min-height: 100px;
                align-items: start;
    
                input {
                    padding: 10px;
                }
    
                .MuiOutlinedInput-notchedOutline {
                    border-radius: 8px;
                    border-color: #ECECEC;
                }
    
                &:hover .MuiOutlinedInput-notchedOutline {
                    border-color: #1976d2;
                }
            }
        }

        .MuiPaper-root {
            font-size: 13px;
            width: 100%;
            padding: 8px;
            color: #93742A;
            background-color: #FCF6EC;
            border-color: #AB8C3F;
            border-radius: 8px;
            height: 50px;
            box-sizing: border-box;

            .MuiAlert-message {
                font-family: 'OpenSans' !important;
            }
        }

        .MuiToggleButtonGroup-root {
            justify-content: end;
            padding: 10px 0;

            .MuiButtonBase-root {
                background-color: transparent;
                border: unset;
                padding-top: 0;
                padding-bottom: 0;
                text-transform: none;
                font-size: 13px;
                color: #444;

                &:last-child {
                    border-left: 1px solid #51A3FF;
                    padding-right: 0;
                }

                &.Mui-selected {
                    color: #51A3FF;
                }

                &:hover {
                    background-color: unset;
                }
            }
        }
    }

    &-btn {
        display: flex;
        justify-content: end;
        gap: 10px;

        &-submit.MuiButtonBase-root {
            text-transform: none;
            color: #fff;
            background-color: #51A3FF;
            padding: 10px 24px;
            border-radius: 30px;
            font-size: 14px;
            margin-top: 20px;
            font-family: 'OpenSans' !important;
            height: 40px;
            box-sizing: border-box;

            &:hover {
                text-transform: none;
                color: #fff;
                background-color: #51A3FF;
                padding: 10px 24px;
                border-radius: 30px;
            }
        }

        
        &-reset.MuiButtonBase-root {
            text-transform: none;
            color: #888888;    
            background-color: #fff;
            padding: 10px 24px;
            border-radius: 30px;
            border: 1px solid #cdcdcd;
            font-size: 14px;
            margin-top: 20px;
            font-family: 'OpenSans' !important;
            height: 40px;
            box-sizing: border-box;

            &:hover {
                text-transform: none;
                color: #888888;    
                background-color: #fff;
                padding: 10px 24px;
                border-radius: 30px;
                border: 1px solid #cdcdcd;
            }
        }
    }
}

.invoices-sendemailpopup {
    width: 80%;
    max-width: 500px;
    height: auto;
    background-color: white;
    border-radius: 8px;
    padding: 30px;
    box-sizing: border-box;

    &-header {
        display: flex;
        flex-direction: column;
        text-align: center;
        padding: 0 0 10px 0;
        gap: 15px;
        // border-bottom: 1px solid #ECECEC;


        &-title {
            text-align: center;
            font-size: 20px;
            color: #444;
            font-weight: 400;
        }

        &-subtitle {
            font-size: 14px;
            line-height: 22px;
            text-align: center;
            color: #888888;
        }
        
        &-btn {
            .MuiButtonBase-root {
                color: #51A3FF;
            }
        }
    }

    &-content {
        display: flex;
        gap: 10px;
        justify-content: center;
        padding-top: 50px;

        &-btn {
            display: flex;
            justify-content: end;
            gap: 10px;

            &:first-child {
                .MuiButtonBase-root:first-child {
                    border: 1px solid #ECECEC;
                    color: #888888;
                    text-transform: none;
                    font-size: 14px;
                    padding: 8px 20px;
                    border-radius: 50px;
                    height: 40px;
                    box-sizing: border-box;
                    font-family: 'OpenSans' !important;
                }
            }

            &:last-child {
                .MuiButtonBase-root:first-child {
                    border: 1px solid #3AB29E;
                    color: #3AB29E;
                    text-transform: none;
                    font-size: 14px;
                    padding: 8px 20px;
                    border-radius: 50px;
                    height: 40px;
                    box-sizing: border-box;
                    font-family: 'OpenSans' !important;

                    &:hover {
                        color: #fff;
                        background-color: #3AB29E;
                    }
                }
            }
        }
    }
}

.invoices-addpaymentpopup {
    width: 80%;
    max-width: 630px;
    height: auto;
    max-height: 70vh;
    overflow-y: auto;
    background-color: white;
    border-radius: 8px;
    padding: 40px;
    box-sizing: border-box;

    &-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 0 10px 0;
        border-bottom: 1px solid #ECECEC;


        &-title {
            display: flex;
            align-items: center;
            gap: 5px;
            font-size: 20px;
            color: #51A3FF;

            p {
                font-family: 'OpenSans' !important;
            }
        }
        
        &-btn {
            .MuiButtonBase-root {
                color: #51A3FF;
            }
        }
    }

    &-list {
        padding: 20px 0;
        display: flex;
        flex-direction: column;
        // gap: 20px;
    }

    &-item {
        padding-bottom: 20px;

        &.pb-0 {
            padding-bottom: 0;
        }

        input::placeholder {
            color: #444;
            font-size: 14px;
        }

        &.flex {
            display: flex;
            align-items: center;
            justify-content: space-between;
        }

        &-title {
            width: auto;
            font-size: 14px;
            color: #444;
        }

        .MuiFormControl-root {
            width: 80%;
            max-width: 380px;
        }

        &-input {

            & .MuiInputBase-root {
                width: 100%;
                height: 35px;
                box-sizing: border-box;
                border-radius: 8px;
                background-color: #F8FAFC;
    
                input {
                    padding: 10px;
                    color: #444;
                    font-size: 14px;
                }
    
                .MuiOutlinedInput-notchedOutline {
                    border-radius: 8px;
                    border-color: #ECECEC;
                }
    
                &:hover .MuiOutlinedInput-notchedOutline {
                    border-color: #1976d2;
                }
            }
        }

        &-project_selection {
            width: 100%;
            text-align: right;
        }

        &-select {
            width: 100%;

            .MuiSelect-select {
                height: 35px !important;
                box-sizing: border-box;
                padding: 10px;
                background-color: #F8FAFC;
                font-size: 14px;
                line-height: 15px;
                color: #444;
                border-radius: 8px;
            }

            .MuiOutlinedInput-notchedOutline  {
                border-radius: 8px;
                border-color: #ECECEC;

            }

            .MuiSvgIcon-root {
                color: #ACACAC;
            }

            &.MuiInputBase-root:hover .MuiOutlinedInput-notchedOutline {
                border-color: #1976d2;
            }
        }

        &-date {
            width: 80%;
            max-width: 380px;

            .MuiFormControl-root {
                width: 100%;

                .MuiInputBase-input {
                    height: 35px;
                    box-sizing: border-box;
                    padding: 10px;
                    background-color: #F8FAFC;
                    font-size: 14px;
                    color: #444;
                    border-radius: 8px;
                }

                .MuiOutlinedInput-notchedOutline {
                    border-radius: 8px;
                    border-color: #ECECEC;

                }

                .MuiInputBase-root:hover .MuiOutlinedInput-notchedOutline {
                    border-color: #1976d2;
                }
            }
        }

        &.switch {
            display: flex;
            align-items: center;
            justify-content: space-between;

            p {
                cursor: pointer;
            }
        }

        &.tags {
            display: flex;
            align-items: center;

            .MuiInputBase-root {
                width: 80%;

                &:hover .MuiOutlinedInput-notchedOutline {
                    border-color: #1976d2;
                }
            }

            .MuiSelect-select {
                background-color: #F8FAFC;
                padding: 10px;
            }

            .MuiOutlinedInput-notchedOutline {
                border-radius: 8px;
                border-color: #ECECEC;

            }
        }

        &.notes {
            display: flex;
            align-items: top;
            justify-content: space-between;

            .MuiFormControl-root {
                width: 100%;
                max-width: unset;
            }

            .notif {
                color: #888;
                text-align: center;
                font-size: 13px;
                font-style: italic;
                font-weight: 400;
                line-height: 22px;
                font-family: 'OpenSans' !important;
            }

            & .MuiInputBase-root {
                border-radius: 8px;
                background-color: #F8FAFC;
                width: 100%;
                min-height: 100px;
                align-items: start;
    
                input {
                    padding: 10px;
                }
    
                .MuiOutlinedInput-notchedOutline {
                    border-radius: 8px;
                    border-color: #ECECEC;

                }
    
                &:hover .MuiOutlinedInput-notchedOutline {
                    border-color: #1976d2;
                }
            }
        }

        &-price {
            width: 80%;
            max-width: 380px;

            .MuiFormControl-root {
                width: 100%;
            }

            .MuiInputBase-adornedStart {
                padding-left: 10px;

                .MuiTypography-body1 {
                    font-size: 14px;
                    color: #ACACAC;
                }

                input {
                    padding-top: 0;
                    padding-bottom: 0;
                    height: 100%;
                    box-sizing: border-box;
                    border-left: 1px solid #ececec;
                }
            }


        } 

        .MuiPaper-root {
            width: 100%;
            color: #93742A;
            background-color: #FCF6EC;
            border-color: #93742A;
            border-radius: 8px;
        }

        .MuiToggleButtonGroup-root {
            justify-content: end;
            padding: 10px 0;

            .MuiButtonBase-root {
                background-color: transparent;
                border: unset;
                padding-top: 0;
                padding-bottom: 0;
                text-transform: none;
                font-size: 14px;

                &:last-child {
                    border-left: 1px solid #51A3FF;
                    padding-right: 0;
                }

                &.Mui-selected {
                    color: #51A3FF;
                }

                &:hover {
                    background-color: unset;
                }
            }
        }

        .MuiCheckbox-root.Mui-checked {
            color: #51A3FF;
        }

        .MuiFormControlLabel-root {
            .MuiTypography-root  {
                font-size: 13px;
                color: #888;
            }
        }
    }

    &-btn {
        display: flex;
        justify-content: end;
        gap: 10px;

        &-submit.MuiButtonBase-root {
            text-transform: none;
            color: #fff;
            background-color: #51A3FF;
            padding: 10px 24px;
            border-radius: 30px;
            font-size: 14px;
            margin-top: 20px;
            font-family: 'OpenSans' !important;
            height: 40px;
            box-sizing: border-box;

            &:hover {
                text-transform: none;
                color: #fff;
                background-color: #51A3FF;
                padding: 10px 24px;
                border-radius: 30px;
            }
        }

        
        &-reset.MuiButtonBase-root {
            text-transform: none;
            color: #888888;    
            background-color: #fff;
            padding: 10px 24px;
            border-radius: 30px;
            border: 1px solid #cdcdcd;
            font-size: 14px;
            margin-top: 20px;
            font-family: 'OpenSans' !important;
            height: 40px;
            box-sizing: border-box;

            &:hover {
                text-transform: none;
                color: #888888;    
                background-color: #fff;
                padding: 10px 24px;
                border-radius: 30px;
                border: 1px solid #cdcdcd;
            }
        }
    }
}

.invoices-cloneinvoicepopup {
    width: 80%;
    max-width: 630px;
    height: auto;
    max-height: 70vh;
    overflow-y: auto;
    background-color: white;
    border-radius: 8px;
    padding: 40px;
    box-sizing: border-box;

    &-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 0 10px 0;
        border-bottom: 1px solid #ECECEC;


        &-title {
            display: flex;
            align-items: center;
            gap: 5px;
            font-size: 20px;
            color: #51A3FF;

            p {
                font-family: 'OpenSans' !important;
            }
        }
        
        &-btn {
            .MuiButtonBase-root {
                color: #51A3FF;
            }
        }
    }

    &-list {
        padding: 20px 0;
        display: flex;
        flex-direction: column;
        // gap: 20px;
    }

    &-item {
        padding-bottom: 20px;

        &.pb-0 {
            padding-bottom: 0;
        }

        &.flex {
            display: flex;
            align-items: center;
            justify-content: space-between;
        }

        &-title {
            width: auto;
            font-size: 14px;
            color: #444;
            font-family: 'OpenSans' !important;
        }

        .MuiFormControl-root {
            width: 80%;
            max-width: 380px;
        }

        &-input {

            & .MuiInputBase-root {
                width: 100%;
                border-radius: 8px;
                background-color: #F8FAFC;
    
                input {
                    padding: 10px;
                }
    
                .MuiOutlinedInput-notchedOutline {
                    border-radius: 8px;
                    border-color: #ECECEC;

                }
    
                &:hover .MuiOutlinedInput-notchedOutline {
                    border-color: #1976d2;
                }
            }
        }

        &-project_selection {
            width: 100%;
            text-align: right;
        }

        &-select {
            width: 100%;

            .MuiSelect-select {
                height: 35px !important;
                box-sizing: border-box;
                padding: 10px;
                background-color: #F8FAFC;
                font-size: 14px;
                color: #444;
                line-height: 1;
                font-family: 'OpenSans' !important;
                border-radius: 8px;
            }

            .MuiOutlinedInput-notchedOutline  {
                border-radius: 8px;
                border-color: #ECECEC;

            }

            .MuiSvgIcon-root {
                color: #ACACAC;
            }

            &.MuiInputBase-root:hover .MuiOutlinedInput-notchedOutline {
                border-color: #1976d2;
            }
        }

        &-date {
            width: 80%;
            max-width: 380px;

            .MuiFormControl-root {
                width: 100%;

                .MuiInputBase-input {
                    height: 35px;
                    box-sizing: border-box;
                    padding: 10px;
                    background-color: #F8FAFC;
                    font-size: 14px;
                    color: #444;
                    font-family: 'OpenSans' !important;
                    border-radius: 8px;
                }

                .MuiOutlinedInput-notchedOutline {
                    border-radius: 8px;
                    border-color: #ECECEC;

                }

                .MuiInputBase-root:hover .MuiOutlinedInput-notchedOutline {
                    border-color: #1976d2;
                }
            }
        }

        &.switch {
            display: flex;
            align-items: center;
            justify-content: space-between;

            p {
                cursor: pointer;
            }
        }

        &.tags {
            display: flex;
            align-items: center;
            justify-content: space-between;

            .MuiInputBase-root {
                width: 80%;
                max-width: 380px;

                &:hover .MuiOutlinedInput-notchedOutline {
                    border-color: #1976d2;
                }
            }

            .MuiSelect-select {
                height: 35px !important;
                box-sizing: border-box;
                background-color: #F8FAFC;
                padding: 10px;
                font-size: 14px;
                color: #444;
                line-height: 1;
                font-family: 'OpenSans' !important;
                border-radius: 8px;

            }

            .MuiOutlinedInput-notchedOutline {
                border-radius: 8px;
                border-color: #ECECEC;

            }
        }

        &.notes {
            display: flex;
            align-items: top;

            & .MuiInputBase-root {
                border-radius: 8px;
                background-color: #F8FAFC;
                width: 100%;
                min-height: 100px;
                align-items: start;
    
                input {
                    padding: 10px;
                }
    
                .MuiOutlinedInput-notchedOutline {
                    border-radius: 8px;
                    border-color: #ECECEC;

                }
    
                &:hover .MuiOutlinedInput-notchedOutline {
                    border-color: #1976d2;
                }
            }
        }

        .MuiPaper-root {
            width: 100%;
            color: #93742A;
            background-color: #FCF6EC;
            border-color: #93742A;
            border-radius: 8px;
        }

        .MuiToggleButtonGroup-root {
            justify-content: end;
            padding: 10px 0;

            .MuiButtonBase-root {
                background-color: transparent;
                border: unset;
                padding-top: 0;
                padding-bottom: 0;
                text-transform: none;
                font-size: 14px;

                &:last-child {
                    border-left: 1px solid #51A3FF;
                    padding-right: 0;
                }

                &.Mui-selected {
                    color: #51A3FF;
                }

                &:hover {
                    background-color: unset;
                }
            }
        }
    }

    &-btn {
        display: flex;
        justify-content: end;
        gap: 10px;

        &-submit.MuiButtonBase-root {
            text-transform: none;
            color: #fff;
            background-color: #51A3FF;
            padding: 10px 24px;
            border-radius: 30px;
            font-size: 14px;
            margin-top: 20px;
            font-family: 'OpenSans' !important;
            height: 40px;
            box-sizing: border-box;

            &:hover {
                text-transform: none;
                color: #fff;
                background-color: #51A3FF;
                padding: 10px 24px;
                border-radius: 30px;
            }
        }
        
        &-reset.MuiButtonBase-root {
            text-transform: none;
            color: #888888;    
            background-color: #fff;
            padding: 10px 24px;
            border-radius: 30px;
            border: 1px solid #cdcdcd;
            font-size: 14px;
            margin-top: 20px;
            font-family: 'OpenSans' !important;
            height: 40px;
            box-sizing: border-box;

            &:hover {
                text-transform: none;
                color: #888888;    
                background-color: #fff;
                padding: 10px 24px;
                border-radius: 30px;
                border: 1px solid #cdcdcd;
            }
        }
    }
}

.invoices-changepopup {
    width: 80%;
    max-width: 580px;
    height: auto;
    max-height: 70vh;
    overflow-y: auto;
    background-color: white;
    border-radius: 8px;
    padding: 40px;
    box-sizing: border-box;

    &-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 0 10px 0;
        border-bottom: 1px solid #ECECEC;


        &-title {
            display: flex;
            align-items: center;
            gap: 5px;
            font-size: 20px;
            color: #51A3FF;

            p {
                font-family: 'OpenSans' !important;
            }
        }
        
        &-btn {
            .MuiButtonBase-root {
                color: #51A3FF;
            }
        }
    }

    &-list {
        padding: 20px 0;
        display: flex;
        flex-direction: column;
        // gap: 20px;
    }

    &-item {
        padding-bottom: 20px;

        &.pb-0 {
            padding-bottom: 0;
        }

        &.flex {
            display: flex;
            align-items: center;
            justify-content: space-between;
        }

        &-title {
            width: auto;
            font-size: 14px;
            color: #444;
            font-family: 'OpenSans' !important;
        }

        .MuiFormControl-root {
            width: 80%;
            max-width: 380px;
        }

        &-select {
            width: 100%;

            .MuiSelect-select {
                height: 35px !important;
                box-sizing: border-box;
                padding: 10px;
                background-color: #F8FAFC;
                font-size: 14px;
                color: #444;
                line-height: 1;
                font-family: 'OpenSans' !important;
            }

            .MuiOutlinedInput-notchedOutline  {
                border-radius: 8px;
                border-color: #ECECEC;

            }

            .MuiSvgIcon-root {
                color: #ACACAC;
            }

            &.MuiInputBase-root:hover .MuiOutlinedInput-notchedOutline {
                border-color: #1976d2;
            }
        }

        .MuiPaper-root {
            width: 100%;
            color: #93742A;
            background-color: #FCF6EC;
            border-color: #93742A;
            border-radius: 8px;
        }

        .MuiToggleButtonGroup-root {
            justify-content: end;
            padding: 10px 0;

            .MuiButtonBase-root {
                background-color: transparent;
                border: unset;
                padding-top: 0;
                padding-bottom: 0;
                text-transform: none;
                font-size: 14px;

                &:last-child {
                    border-left: 1px solid #51A3FF;
                    padding-right: 0;
                }

                &.Mui-selected {
                    color: #51A3FF;
                }

                &:hover {
                    background-color: unset;
                }
            }
        }
    }

    &-btn {
        display: flex;
        justify-content: end;
        gap: 10px;

        &-submit.MuiButtonBase-root {
            text-transform: none;
            color: #fff;
            background-color: #51A3FF;
            padding: 10px 24px;
            border-radius: 30px;
            font-size: 14px;
            margin-top: 20px;
            font-family: 'OpenSans' !important;
            height: 40px;
            box-sizing: border-box;

            &:hover {
                text-transform: none;
                color: #fff;
                background-color: #51A3FF;
                padding: 10px 24px;
                border-radius: 30px;
            }
        }
        
        &-reset.MuiButtonBase-root {
            text-transform: none;
            color: #888888;    
            background-color: #fff;
            padding: 10px 24px;
            border-radius: 30px;
            border: 1px solid #cdcdcd;
            font-size: 14px;
            margin-top: 20px;
            font-family: 'OpenSans' !important;
            height: 40px;
            box-sizing: border-box;

            &:hover {
                text-transform: none;
                color: #888888;    
                background-color: #fff;
                padding: 10px 24px;
                border-radius: 30px;
                border: 1px solid #cdcdcd;
            }
        }
    }
}

.invoices-attachpopup {
    width: 80%;
    max-width: 580px;
    height: auto;
    max-height: 70vh;
    overflow-y: auto;
    background-color: white;
    border-radius: 8px;
    padding: 40px;
    box-sizing: border-box;

    &-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 0 10px 0;
        border-bottom: 1px solid #ECECEC;


        &-title {
            display: flex;
            align-items: center;
            gap: 5px;
            font-size: 20px;
            color: #51A3FF;

            p {
                font-family: 'OpenSans' !important;
            }
        }
        
        &-btn {
            .MuiButtonBase-root {
                color: #51A3FF;
            }
        }
    }

    &-list {
        padding: 20px 0;
        display: flex;
        flex-direction: column;
        // gap: 20px;
    }

    &-item {
        padding-bottom: 20px;

        &.pb-0 {
            padding-bottom: 0;
        }

        &.flex {
            display: flex;
            align-items: center;
            justify-content: space-between;
        }

        &-title {
            width: auto;
            font-size: 14px;
            color: #444;
            font-family: 'OpenSans' !important;
        }

        .MuiFormControl-root {
            width: 80%;
            max-width: 380px;
        }


        &-select {
            width: 100%;

            .MuiSelect-select {
                height: 35px !important;
                box-sizing: border-box;
                padding: 10px;
                background-color: #F8FAFC;
                font-size: 14px;
                color: #444;
                line-height: 1;
                font-family: 'OpenSans' !important;
            }

            .MuiOutlinedInput-notchedOutline  {
                border-radius: 8px;
                border-color: #ECECEC;

            }

            .MuiSvgIcon-root {
                color: #ACACAC;
            }

            &.MuiInputBase-root:hover .MuiOutlinedInput-notchedOutline {
                border-color: #1976d2;
            }
        }

        .MuiPaper-root {
            width: 100%;
            color: #93742A;
            background-color: #FCF6EC;
            border-color: #93742A;
            border-radius: 8px;
        }

        .MuiToggleButtonGroup-root {
            justify-content: end;
            padding: 10px 0;

            .MuiButtonBase-root {
                background-color: transparent;
                border: unset;
                padding-top: 0;
                padding-bottom: 0;
                text-transform: none;
                font-size: 14px;

                &:last-child {
                    border-left: 1px solid #51A3FF;
                    padding-right: 0;
                }

                &.Mui-selected {
                    color: #51A3FF;
                }

                &:hover {
                    background-color: unset;
                }
            }
        }
    }

    &-btn {
        display: flex;
        justify-content: end;
        gap: 10px;

        &-submit.MuiButtonBase-root {
            text-transform: none;
            color: #fff;
            background-color: #51A3FF;
            padding: 10px 24px;
            border-radius: 30px;
            font-size: 14px;
            margin-top: 20px;
            font-family: 'OpenSans' !important;
            height: 40px;
            box-sizing: border-box;

            &:hover {
                text-transform: none;
                color: #fff;
                background-color: #51A3FF;
                padding: 10px 24px;
                border-radius: 30px;
            }
        }
        
        &-reset.MuiButtonBase-root {
            text-transform: none;
            color: #888888;    
            background-color: #fff;
            padding: 10px 24px;
            border-radius: 30px;
            border: 1px solid #cdcdcd;
            font-size: 14px;
            margin-top: 20px;
            font-family: 'OpenSans' !important;
            height: 40px;
            box-sizing: border-box;

            &:hover {
                text-transform: none;
                color: #888888;    
                background-color: #fff;
                padding: 10px 24px;
                border-radius: 30px;
                border: 1px solid #cdcdcd;
            }
        }
    }
}

.invoices-detachpopup {
    width: 80%;
    max-width: 390px;
    height: auto;
    background-color: white;
    border-radius: 8px;
    padding: 30px;
    box-sizing: border-box;

    &-header {
        display: flex;
        flex-direction: column;
        text-align: center;
        padding: 0 0 10px 0;
        gap: 15px;
        // border-bottom: 1px solid #ECECEC;


        &-title {
            text-align: center;
            font-size: 20px;
            font-weight: 400;
            color: #444;
        }

        &-subtitle {
            font-size: 14px;
            text-align: center;
            color: #888888;

            p {
                line-height: 22px;
            }
        }
        
        &-btn {
            .MuiButtonBase-root {
                color: #51A3FF;
            }
        }
    }

    &-content {
        display: flex;
        gap: 10px;
        justify-content: center;
        padding-top: 50px;

        &-btn {
            display: flex;
            justify-content: end;
            gap: 10px;

            &:first-child {
                .MuiButtonBase-root:first-child {
                    border: 1px solid #ECECEC;
                    color: #888888;
                    text-transform: none;
                    font-size: 14px;
                    padding: 8px 20px;
                    border-radius: 50px;
                    height: 40px;
                    line-height: 40px;
                    font-weight: 400 !important;
                    font-family: 'OpenSans' !important;
                }
            }

            &:last-child {
                .MuiButtonBase-root:first-child {
                    border: 1px solid #FD5162;
                    color: #FD5162;
                    text-transform: none;
                    font-size: 14px;
                    padding: 8px 20px;
                    border-radius: 50px;
                    height: 40px;
                    line-height: 40px;
                    font-weight: 600 !important;
                    font-family: 'OpenSans' !important;

                    &:hover {
                        color: #fff;
                        background-color: #FD5162;
                    }
                }
            }
        }
    }
}

.invoices-recurringsettingpopup {
    width: 80%;
    max-width: 580px;
    height: auto;
    max-height: 70vh;
    overflow-y: auto;
    background-color: white;
    border-radius: 8px;
    padding: 40px;
    box-sizing: border-box;

    &-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 0 10px 0;
        border-bottom: 1px solid #ECECEC;


        &-title {
            display: flex;
            align-items: center;
            gap: 5px;
            font-size: 18px;
            color: #51A3FF;

            p {
                font-family: 'OpenSans' !important;
            }
        }
        
        &-btn {
            .MuiButtonBase-root {
                color: #51A3FF;
            }
        }
    }

    &-list {
        padding: 20px 0;
        display: flex;
        flex-direction: column;
        // gap: 20px;
    }

    &-item {
        padding-bottom: 20px;
        display: flex;
        flex-direction: column;
        gap: 5px;

        &-title {
            font-size: 14px;
            color: #444;
            line-height: 22px;
            font-family: 'OpenSans' !important;
        }

        &-double {
            display: flex;
            gap: 20px;

            &-container {
                width: 50%;
            }
        }

        &-tooltip {
            color: #51A3FF !important;
        }

        &-alert {

            .MuiPaper-root {
                border-color: #51A3FF;
                border-radius: 8px;
                align-items: flex-start;
            }

            .MuiAlert-message {
                color: #2E6098;
                font-size: 13px;

                .MuiTypography-root {
                    color: #51A3FF;
                    font-size: 16px;
                    font-family: 'OpenSans' !important;
                    font-weight: 400;
                }
            }


            .MuiAlert-icon {
                color: #51A3FF !important;
            }

            .MuiAlert-action {
                .MuiButtonBase-root {
                    color: #51A3FF;
                }
            }
        }

        &.pb-0 {
            padding-bottom: 0;
        }

        .MuiFormControl-root {
            width: 100%;
        }

        &-select {
            width: 100%;

            .MuiSelect-select {
                padding: 10px;
                background-color: #F8FAFC;
                color: #444;
                font-size: 14px;
                font-family: 'OpenSans' !important;
                height: 35px !important;
                box-sizing: border-box;
                line-height: 15px;
                border-radius: 8px;
            }

            .MuiOutlinedInput-notchedOutline  {
                border-radius: 8px;
                border-color: #ECECEC;
            }

            .MuiSvgIcon-root {
                color: #ACACAC;
            }

            &.MuiInputBase-root:hover .MuiOutlinedInput-notchedOutline {
                border-color: #1976d2;
            }
        }

        &-date {
            width: 100%;

            .MuiFormControl-root {
                width: 100%;

                .MuiInputBase-input {
                    padding: 10px;
                    background-color: #F8FAFC;
                    font-size: 14px;
                    color: #444;
                    font-family: 'OpenSans' !important;
                    height: 35px;
                    box-sizing: border-box;
                    line-height: 15px;
                    border-radius: 8px;
                }

                .MuiOutlinedInput-notchedOutline {
                    border-radius: 8px;
                    border-color: #ECECEC;

                }

                .MuiInputBase-root:hover .MuiOutlinedInput-notchedOutline {
                    border-color: #1976d2;
                }
            }
        }

    }

    &-btn {
        display: flex;
        justify-content: end;
        gap: 10px;

        &-submit.MuiButtonBase-root {
            text-transform: none;
            color: #fff;
            background-color: #51A3FF;
            padding: 10px 24px;
            border-radius: 30px;
            font-size: 14px;
            margin-top: 20px;
            font-family: 'OpenSans' !important;
            height: 40px;
            box-sizing: border-box;

            &:hover {
                text-transform: none;
                color: #fff;
                background-color: #51A3FF;
                padding: 10px 24px;
                border-radius: 30px;
            }
        }

        
        &-reset.MuiButtonBase-root {
            text-transform: none;
            color: #888888;    
            background-color: #fff;
            padding: 10px 24px;
            border-radius: 30px;
            border: 1px solid #cdcdcd;
            font-size: 14px;
            margin-top: 20px;
            font-family: 'OpenSans' !important;
            height: 40px;
            box-sizing: border-box;

            &:hover {
                text-transform: none;
                color: #888888;    
                background-color: #fff;
                padding: 10px 24px;
                border-radius: 30px;
                border: 1px solid #cdcdcd;
            }
        }
    }
}

.invoices-invoicerecordpopup {
    width: 80%;
    max-width: 600px;
    height: auto;
    background-color: white;
    border-radius: 8px;
    padding: 30px;
    box-sizing: border-box;

    &-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 0 10px 0;
        border-bottom: 1px solid #ECECEC;


        &-title {
            display: flex;
            align-items: center;
            gap: 5px;
            font-size: 20px;
            color: #51A3FF;

            p {
                font-family: 'OpenSans' !important;
            }
        }
        
        &-btn {
            .MuiButtonBase-root {
                color: #51A3FF;
            }
        }
    }

    &-list {
        padding: 20px 0;
        display: flex;
        flex-direction: column;
        // gap: 20px;
    }

    &-table {
        box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.10);
        border-radius: 8px;
        

        &-body {
            &-tr {
                display: flex;

                &:not(:last-child) {
                    border-bottom: 1px solid #E8E7E4;
                }

                &-title {
                    width: 50%;
                    padding: 15px;
                    font-weight: 600;
                    color: #333;
                    font-size: 13px;
                }

                &-value {
                    width: 50%;
                    padding: 15px;
                    color: #444;
                    font-size: 13px;
                    border-left: 1px solid #E8E7E4;
                    text-transform: capitalize;
                }
            }
        }
    }

}