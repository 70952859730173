@import "/node_modules/react-grid-layout/css/styles.css";
@import "/node_modules/react-resizable/css/styles.css";
@import url("https://fonts.googleapis.com/css2?family=Anonymous+Pro&display=swap");

.my-services {

  &.backdrop {
    filter: blur(8px);
  }

  &__layout {
    justify-content: center;
    width: 100%;

    .react-grid-item {
      transition: none !important;
    }

    &.layoutXL {
      max-width: 1500px;
    }
    &.layoutLG {
      max-width: 1050px;
    }
    &.layoutMD {
      max-width: 996px;
    }
    &.layoutSM {
      max-width: 768px;
    }
    &.layoutXS {
      max-width: 480px;
    }
  }

  &__calendar {
    background-color: #ffffff;
    height: 100%;
    border-radius: 18px;
    padding: 15px 15px 0;
    box-sizing: border-box;
    position: relative;
    box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.15);
    transition: -webkit-transform .4s cubic-bezier(0,0,.4,1);
    transition: transform .4s cubic-bezier(0,0,.4,1);
    transition: transform .4s cubic-bezier(0,0,.4,1),-webkit-transform .4s cubic-bezier(0,0,.4,1);
    will-change: transform;

    & .fc.fc-media-screen {
      height: 100%;

      .fc-header-toolbar {
        // background-color: rgba(248, 250, 252, 0.5019607843);
        flex-direction: column;
        align-items: start;
        margin-bottom: 5px;

        .fc-button {
          font-family: 'OpenSans' !important;
          background-color: #F8FAFC;
          border: unset;
          color: #828282;
          border-radius: 8px;
          font-size: 13px;
          padding: 0 14px;
        }

        .fc-toolbar-chunk {
          width: 100%;
        }

        .fc-toolbar-chunk:first-child {
          .fc-button-group {
            background-color: rgba(248, 250, 252, 0.5019607843);
            height: 27px;
          }
        }

        .fc-toolbar-chunk:last-child {
          display: flex;
          justify-content: space-between;
          align-items: center;
          gap: 15px;
          margin-top: 20px;
          padding: 0 10px;


          .fc-button {
            font-size: 11px;
            background-color: transparent;
            padding: 0;
          }

          .fc-toolbar-title {
            flex: 1;
            font-family: "OpenSans" !important;
            color: #000;
            font-size: 15px;
            font-weight: 600;
          }

          .fc-dayGridMonth-view {
            padding-right: 10px;
            padding-left: 10px;
          }
        }

        .fc-button-active {
          background-color: hsla(240,4%,48%,.122);
        }

        .fc-button-primary:not(:disabled).fc-button-active:focus, .fc-button-primary:not(:disabled):active:focus, .fc-button:focus {
          box-shadow: unset;
        }
      }


      .fc-view-harness {
        .fc-timeGridDay-view {
          .fc-scrollgrid {
            border: unset;

            thead{
              th {
                border: unset;

                &.fc-timegrid-axis {
                  display: none;
                }

                .fc-scrollgrid-sync-inner {
                  // margin-left: -23px;
                  font-size: 12px;
                  font-style: normal;
                  font-weight: 500;
                  color: #4382C4;

                  .fc-col-header-cell-cushion {
                    font-family: 'OpenSans' !important;
                    color: #4382C4;
                    // text-transform: uppercase;
                    font-size: 12px;
                    font-weight: 600;
                    font-family: "OpenSans" !important;
                    line-height: 16px;

                    span {
                      // text-transform: uppercase;
                      font-size: 12px;
                      font-weight: 600;
                      font-family: "OpenSans" !important;
                      line-height: 16px;
                    }
                  }
                }
              }
            }

            tbody {

              tr {
                background-color: #ffffff;
                td {
                  // border-left: unset;
                  // border-right: unset;
                  // border: unset;

                  .fc-timegrid-slot-label-cushion {
                    position: relative;
                    top: 32px;
                    font-family: 'OpenSans' !important;
                    color: #999999;
                    font-size: 13px;
                  }
                }


              }

              & > tr:nth-child(odd){
                // background-color: red;

                & > td:nth-child(even) {
                  border-top: 1px solid #ddd;
                }
                // border-bottom: 1px solid #ddd;
                td {
                  border: initial;
                }
              }
            }
          }

          .fc-timegrid-now-indicator-arrow {
            border-left-color: #4382C4;
            border-right-color: #4382C4;
          }

          .fc-timegrid-now-indicator-line {
            border-color: #4382C4;
          }

          .fc-timegrid-event-harness {
            .fc-event {
              border-color: transparent !important;
              border: unset;
              border-left: 3px solid;
              border-radius: 8px;
              padding: 8px 5px;
              box-shadow: unset;

              .fc-event-main {
                font-weight: bold;

                .fc-event-time {
                  font-weight: bold;
                }

                .fc-event-title {
                  font-family: 'OpenSans' !important;
                  white-space: nowrap;
                  overflow: hidden;
                  text-overflow: ellipsis;
                  // color: #414141;
                  font-size: 14px;
                  font-weight: 600;
                  line-height: 22px;
                }

                .fc-event-title-container {
                  white-space: nowrap;
                  overflow: hidden;
                  text-overflow: ellipsis;
                }
              }
            }
          }
        }

        .fc-listWeek-view {
          border: unset;
        }

        .fc-dayGridMonth-view {

          margin: 8px 12px 10px 12px;

          td, th {
            border: unset;
          }
          .fc-scrollgrid {
            border: unset;

            .fc-scroller {
              overflow: hidden !important;

              thead {
                .fc-col-header-cell {
                  border: unset;

                  .fc-col-header-cell-cushion {
                    font-size: 14px;
                    color: #BDBDBD;
                    font-family: "OpenSans" !important;
                    font-weight: 600;
                    line-height: 22px;
                  }
                }
              }

              tbody {
                td {
                  border: unset;
                  // height: 30px;
                  // min-height: unset;
                  box-sizing: border-box;


                  .fc-daygrid-day-frame {
                    position: relative;
                    height: 30px;
                    min-height: unset;
                  }
                }

                .fc-day {
                  .fc-daygrid-day-frame {
                    .fc-daygrid-day-top {
                      justify-content: center;

                      a {
                        font-family: "OpenSans" !important;
                        color: #444444;
                        display: inline-block;
                        border-radius: 50px;
                        padding: 6px 0;
                        font-size: 14px;
                        font-weight: 600;
                        width: 26px;
                        height: 26px;
                        text-align: center;
                      }
                    }

                    .fc-daygrid-day-events {
                      display: none;
                    }
                  }
                }

                .fc-day-today {
                  background-color: unset;

                  .fc-daygrid-day-top {
                    a {
                      color: #ffffff !important;
                      background-color: #4382C4;
                      display: inline-block;
                      border-radius: 50px;
                      padding: 7px;
                      font-size: 13px;
                    }
                  }
                }

                // .fc-day-other {
                //   a {
                //     color: #444444;
                //   }
                // }
              }

              .fc-daygrid-body {
                margin-top: 10px;
                height: 180px;

                .fc-scrollgrid-sync-table {
                  height: 180px;
                  max-height: 180px;
                }

                .fc-day-other {
                  .fc-daygrid-day-top {
                    opacity: 1;

                    .fc-daygrid-day-number {
                      font-weight: 400;
                      opacity: 1;
                    }
                  }
                }

                .fc-day-past {
                  .fc-daygrid-day-top {
                    .fc-daygrid-day-number {
                      color: #BDBDBD;
                    }
                  }
                }
              }
            }
          }

        }
      }

      thead .fc-scroller-harness {
        .fc-scroller {
          overflow: hidden !important;
        }
      }

      .fc-list-empty {
        background-color: #ffffff;

        .fc-list-empty-cushion {
          margin: unset;
          width: 100%;
          height: 100%;
        }

        .calendar-widget__emptyevent {
          width: 100%;
          height: 90%;
          display: flex;
          justify-content: center;
          flex-direction: column;
          align-items: center;

          &-img {
            width: auto;
            height: 85px;

            img {
              width: inherit;
              height: inherit;
            }
          }

          &-text {
            width: 210px;
            color: #828282;
            text-align: center;
            font-family: "OpenSans" !important;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
          }

        }
      }

      .fc-list-table {
        .fc-list-day {
          th {
            border: unset;

            .fc-list-day-cushion {
              background-color: unset;
              padding-left: 0;
              text-align: left;
              padding: 14px 0 8px 0;

              a {
                color: #999999;
                font-size: 12px;
                font-weight: 600;
                font-family: 'OpenSans' !important;
                line-height: 16px;

                span {
                  text-transform: uppercase;
                  color: #999999;
                  font-size: 12px;
                  font-weight: 600;
                  font-family: 'OpenSans' !important;
                }
              }

              a.today {
                color: #51A3FF;

                span {
                  color: #51A3FF;
                }
              }
            }

            .fc-list-day-text, .fc-list-day-side-text {
              color: #51A3FF;
            }
          }

          &:first-child {
            th {
              .fc-list-day-cushion {
                padding-top: 0;
              }
            }
          }
        }

        .fc-list-day:not(:first-child) {
          th {
            padding-top: 9px;

            .fc-list-day-cushion {
              border-top: 1px dashed #CACACA;
            }
          }
        }

        .fc-event {
          // display: flex;
          display: grid;
          // grid-template-columns: 6% 65% 29%;
          grid-template-columns: 12px calc(100% - 98px) 86px;
          align-items: center;

          &:hover {
            td {
              background-color: unset;
            }
          }

          td {
            border: unset;
            // padding: 8px 6px 8px 0;
            padding: 2px 6px 2px 0;
          }

          .fc-list-event-dot {
            height: 12px;
            width: 0px;
            border: 2px solid #51A3FF;
            border-radius: 2px;
          }

          .fc-list-event-time {
            color: #999999;
            padding-left: 0;
            padding-right: 0;
            // padding-top: 9px;
            width: 100%;
            font-size: 14px;
            // font-family: "OpenSans" !important;
            order: 3;
            font-weight: 400;
            line-height: 22px;
          }

          .fc-list-event-graphic {
            // width: 20px;
            width: 100%;
            order: 1;
          }

          .fc-list-event-title {
            order: 2;
            color: #414141;
            // flex: 0 0 53%;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            width: 100%;

            a {
              color: #444;
              font-size: 14px;
              font-style: normal;
              font-weight: 600;
              font-family: "OpenSans" !important;
              line-height: 22px;
            }
          }
        }
      }
    }

    .react-grid-dragHandleArea {
      height: 30px;
      position: absolute;
      width: 30px;
      top: 15px;
      right: 85px;
    }

    &_more-btn {
      position: absolute;
      top: 15px;
      right: 15px;
      display: flex;
      gap: 10px;

      .MuiButtonBase-root {
        padding: 5px;
        color: $blue;
      }
    }
  }

  &__guide {
    width: 100%;
    height: 380px;

    background-image: url("../../../assets/images/my-services/guide/guide-back.jpg");
    background-size: cover;
    background-position: center;
    border-radius: $border-radius-big;

    &-container {
      padding: 50px 30px;
    }

    &_header {
      &-title {
        font-size: 32px;
        color: $gray-text;
        font-weight: normal;
      }
    }
  }

  &__connect-other {
    width: 100%;
    border: 2px dashed $delimiter;
    text-align: center;
    color: $gray;
    margin-top: 2 * $space;
    font-size: 16px;
    padding: $space;
    box-sizing: border-box;
    cursor: pointer;
    &:before {
      content: "+";
      color: $gray;
      display: block;
      font-size: 28px;
    }
  }

  &__crm {
    &_container {
      display: flex;
      justify-content: space-between;
    }
    &_col {
      width: 30%;
      text-align: center;
      padding-top: $space;
      padding-bottom: $space;
    }
    &_col-number {
      font-size: 16px;
      margin-bottom: 5px;
    }
    &_col-text {
      font-size: 14px;
    }
  }

  &__online-consultant {
    &_dialog {
      padding: 10px 0;
    }
    &_dialog-header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding-bottom: 5px;
      font-size: 13px;
    }
    &_dialog-date {
      font-size: 10px;
      color: $gray;
    }
    &_separator {
      height: 1px;
      background-color: $delimiter;
    }
  }

  &__domains {
    &-page {
      &_header {
        justify-content: space-between;
        padding-left: 2 * $space;
        padding-right: 2 * $space;
      }
      &_header-col {
        display: flex;
        flex-wrap: nowrap;
        align-items: center;
      }
      &_row {
        display: flex;
        flex-wrap: nowrap;
        padding: $space;
        border-top: 1px solid $delimiter;
        &:first-of-type {
          border-top: none;
          padding-top: 0;
        }
      }
      &_col {
        width: 22%;
        &:last-of-type {
          width: 12%;
        }
      }
    }
    &_container {
      text-align: center;
      padding: 4 * $space 2 * $space;
    }
    &_header {
      text-align: left;
    }

    &_items {
      & .MuiListItem-root {
        margin-top: 25px;
        padding: 0;
        align-items: center;
      }

      & .MuiListItemIcon-root {
        min-width: unset;
        margin-right: 10px;
      }

      & .MuiListItemText-root {
        margin: 0;
      }

      & .MuiSvgIcon-root {
        font-size: 15px;
        color: #74ba6a;
      }
    }
  }

  &__notes {
    background-color: #ffffff;
    height: 100%;
    width: 100%;
    border-radius: 18px;
    padding: 10px;
    padding-bottom: 0;
    box-sizing: border-box;
    box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.15);
    transition: -webkit-transform .4s cubic-bezier(0,0,.4,1);
    transition: transform .4s cubic-bezier(0,0,.4,1);
    transition: transform .4s cubic-bezier(0,0,.4,1),-webkit-transform .4s cubic-bezier(0,0,.4,1);
    will-change: transform;

    // &.pr-0 {
    //   padding-right: 0;
    // }

    &_header {
      display: flex;
      justify-content: space-between;
      height: 40px;
      padding: 5px 5px 0 5px;

      &-view {
        .MuiToggleButtonGroup-root {
          background-color: transparent;
          border-radius: 8px !important;

          &:hover {
            background-color: #F8FAFC;
          }

          .MuiButtonBase-root {
            background-color: transparent;
            border: unset;
            border-radius: 8px !important;
            color: #9C9C9C;
            padding: 0;
            width: 30px;
            height: 26px;

            &.Mui-selected {
              background-color: #7676801F;
            }

            img {
              width: 20px;
              height: 20px;
            }
          }
        }
      }

      &-more {
        display: flex;
        gap: 15px;
        align-items: start;

        .MuiButtonBase-root {
            color: $blue;
            padding: 5px;
        }
      }
    }

    &_content {
      height: calc(100% - 55px);
      padding-top: 15px;
      box-sizing: border-box;

      &_list {
        height: 100%;
        box-sizing: border-box;
        overflow-y: auto;
        overflow-x: hidden;
        list-style: none;
        padding: 0 5px 0 5px;
        // display: flex;
        // flex-direction: column-reverse;

        &-item {
          display: flex;
          flex-direction: column;
          gap: 3px;
          padding-bottom: 10px;
          padding-top: 8px;
          border-bottom: 1px solid #E8E7E4;
          font-size: 14px;
          cursor: pointer;

          &-title {
            color: #414141;
            font-size: 14px;
            font-family: 'OpenSans' !important;
            font-weight: 600;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            line-height: normal;
          }

          &-details {
            display: flex;
            gap: 5px;
            line-height: normal;
            font-family: 'OpenSans' !important;

            &-time {
              color: #333333;
              font-size: 14px;
              line-height: normal;
              font-family: 'OpenSans' !important;
            }

            &-desctiprion {
              font-family: 'OpenSans' !important;
              color: #989898;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
              font-size: 14px;
            }
          }
        }

        &.grid {
          width: 100%;
          display: grid;
          grid-template-columns: 50% 50%;
          gap: 8px;
          padding-right: 12px;
          gap: 6px;

          .my-services__notes_content_list-item {
            padding: 8px;
            border-radius: 10px;
            box-shadow: 0px 2px 3px 0px rgba(0, 0, 0, 0.08);
            box-sizing: border-box;
            height: 60px;
            justify-content: space-around;
          }

          .my-services__notes_content_list-item-details-time, .my-services__notes_content_list-item-details-desctiprion {
            font-size: 13px;
          }
        }
      }
    }
  }

  &__invoice-manager {
    &_item {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      margin-bottom: $space;
      line-height: 1.2;
      font-size: 12px;
      &:last-of-type {
        margin-bottom: 0;
      }
    }
    &_item-text-gray {
      color: $gray;
    }
    &_item-col-1 {
      min-width: 140px;
    }
    &_item-col-2 {
      width: 100%;
    }
    &_item-status {
      &--draft {
        color: $light-gray;
      }
      &--sent {
        color: $orange;
      }
      &--viewed {
        color: $blue;
      }
    }
  }

  &__cloud-hosting {
    height: 100%;

    &_container {
      height: 100%;
      display: flex;
      flex-direction: column;
      padding: 2 * $space 2 * $space;
    }

    &_storage {
      &-heading {
        font-weight: 500;
        color: #000;
        margin-bottom: $space;
      }

      &_ring {
        width: 137px;
        height: 137px;
        margin: 0 auto;
        &-title {
          font-size: 15px;
          font-weight: 500;
          color: #000;
          margin-bottom: 5px;
        }
        &-subtitle {
          font-size: 12px;
          font-weight: 400;
          color: #9b9b9b;
        }
      }
    }

    &_site {
      &-heading {
        font-weight: 500;
        color: #000;
        margin-top: $space;
      }

      & .MuiListItemText-secondary {
        text-transform: none;
      }
    }

  }

  &__tasks {
    background-color: #ffffff;
    height: 100%;
    width: 100%;
    border-radius: 18px;
    padding: 15px;
    padding-bottom: 0;
    box-sizing: border-box;
    box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.15);
    transition: -webkit-transform .4s cubic-bezier(0,0,.4,1);
    transition: transform .4s cubic-bezier(0,0,.4,1);
    transition: transform .4s cubic-bezier(0,0,.4,1),-webkit-transform .4s cubic-bezier(0,0,.4,1);
    will-change: transform;

    &_header {
      height: 31px;
      width: 100%;
      display: flex;
      justify-content: space-between;

      &-view {
        &_tab {
          min-height: unset !important;
          height: 25px;
          align-items: end;

          .MuiTabs-flexContainer {
            gap: 20px;
            height: 25px;
          }

          .MuiButtonBase-root {
            padding: 0;
            min-width: unset;
            width: auto;
            color: #444;
            font-size: 14px;
            font-weight: 400 !important;
            text-transform: capitalize;
            font-family: 'OpenSans' !important;
            height: 22px;
            min-height: unset;

            &.Mui-selected {
              color: #51A3FF;
              font-weight: 600 !important;
            }
          }

          .MuiTabs-indicator {
            // bottom: 8px;
            background-color: #51A3FF;
            height: 1.5px;
          }
        }
      }

      &-more {
        display: flex;
        gap: 15px;
        align-items: start;
        justify-content: end;
        // flex: 1 1;

        .MuiButtonBase-root {
            color: $blue;
            padding: 5px;
        }
      }
    }


    &_content {
      height: calc(100% - 40px);
      padding-top: 10px;
      box-sizing: border-box;
      overflow-y: auto;

      &-list {
        display: flex;
        flex-direction: column;
        gap: 10px;
        position: relative;
      }

      &-item {
        border-radius: 12px;
        border: 1px solid #F7F7F7;
        background: #FFF;
        box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.05);
        display: flex;
        width: 100%;
        display: flex;
        padding: 10px 18px;
        justify-content: space-between;
        gap: 10px;
        box-sizing: border-box;
        height: 65px;

        &-info {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;

          &-title {
            font-family: "OpenSans" !important;
            color: #414141;
            font-size: 14px;
            font-weight: 600;
            line-height: 22px;
          }

          &-date {
            color: #653EB9;
            font-size: 13px;
            font-weight: 400;
            display: flex;
            align-items: center;
            gap: 5px;
            line-height: 22px;
            padding-top: 2px;

            span {
              font-family: 'OpenSans' !important;
            }
          }
        }

        &-details {
          display: flex;
          align-items: center;
          gap: 15px;

          &-members {
            .MuiAvatarGroup-root {

            }

            .MuiAvatar-root {
              width: 30px;
              height: 30px;
              font-size: 13px;
              box-sizing: border-box !important;
            }
          }

          &-tags {
            display: flex;
            gap: 6px;

            &-tag {
              font-size: 12px;
              padding: 10px;
              display: inline-block;
              color: #727272;
              border-radius: 6px;
              height: 26px;
              box-sizing: border-box;
              line-height: 7px;
            }
          }

          &-comments {
            color: #888;
            font-size: 13px;

            .MuiButtonBase-root {
              padding: 5px;

              img {
                width: 20px;
                height: 20px;
              }
            }
          }
        }
      }

    }

    .tasks-widget__emptyevent {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: center;
      transform: translateY(50px);

      &-img {
        width: auto;
        height: 85px;

        img {
          width: inherit;
          height: inherit;
        }
      }

      &-text {
        width: 210px;
        color: #828282;
        text-align: center;
        font-family: "OpenSans" !important;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }
    }
  }

  &__emails {
    background-color: #ffffff;
    height: 100%;
    width: 100%;
    border-radius: 18px;
    padding: 15px 10px;
    padding-bottom: 0;
    box-sizing: border-box;
    box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.15);
    transition: -webkit-transform .4s cubic-bezier(0,0,.4,1);
    transition: transform .4s cubic-bezier(0,0,.4,1);
    transition: transform .4s cubic-bezier(0,0,.4,1),-webkit-transform .4s cubic-bezier(0,0,.4,1);
    will-change: transform;

    &_header {
      height: 25px;
      width: 100%;
      padding: 0 10px;
      display: flex;
      justify-content: space-between;
      box-sizing: border-box;

      &-view {
        &_tab {
          min-height: unset !important;
          height: 25px;
          align-items: end;

          .MuiTabs-flexContainer {
            gap: 20px;
            height: 25px;
          }

          .MuiButtonBase-root {
            padding: 0;
            min-width: unset;
            width: auto;
            color: #444;
            font-size: 14px;
            font-weight: 400 !important;
            text-transform: capitalize;
            font-family: 'OpenSans' !important;
            height: 22px;
            min-height: unset;

            p {
              font-size: 14px;
              font-weight: 400;
              text-transform: capitalize;
              font-family: "OpenSans" !important;

              .unread-num {
                background-color: #51A3FF;
                width: 18px;
                height: 18px;
                display: inline-block;
                color: #FFF;
                font-family: 'OpenSans' !important;
                font-weight: 400;
                font-size: 12px;
                line-height: normal;
                letter-spacing: 0.12px;
                border-radius: 5px;
              }
            }

            &.Mui-selected {
              color: #51A3FF;
              font-weight: 600 !important;

              p {
                font-weight: 600;

                .unread-num {
                  font-weight: 600;
                }
              }
            }
          }

          .MuiTabs-indicator {
            // bottom: 8px;
            background-color: #51A3FF;
            height: 1.5px;
          }
        }
      }

      &-more {
        display: flex;
        gap: 15px;
        align-items: start;
        justify-content: end;
        // flex: 1 1;

        .MuiButtonBase-root {
            color: $blue;
            padding: 0;
            width: 25px;
            height: 25px;
        }
      }
    }

    &_content {
      height: calc(100% - 50px);
      box-sizing: border-box;
      overflow-y: auto;
      // padding: 0 5px;
      margin-top: 20px;


      &-list {
        display: flex;
        flex-direction: column;
      }

      &-item {
        width: 100%;
        height: 65px;
        padding: 0 10px;
        display: flex;
        box-sizing: border-box;
        gap: 10px;
        align-items: center;
        border-bottom: 1px solid #F1F1F1;

        &-icon {
          width: 35px;

          .MuiAvatar-root {
            width: 35px;
            height: 35px;
            font-size: 16px;
            background-color: #d1d1d1;
          }

          img {
            width: 35px;
            height: 35px;
          }
        }


        &-info {
          display: flex;
          flex: 0 1 290px;
          flex-direction: column;

          @media (min-width:1600px) {
            flex: 0 1 345px;
          }

          &-title {
            color: #444;
            font-family: 'OpenSans' !important;
            font-size: 14px;
            font-style: normal;
            font-weight: 600;
            line-height: 19px;
          }

          &-subtitle {
            color: #444;
            font-family: 'OpenSans' !important;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 19px;
          }
        }

        &-attach {
          width: 15px;

          img {
            width: 15px;
            height: 15px;
          }
        }

        &-date {
          width: 100px;
          color: #414141;
          font-family: 'OpenSans' !important;
          font-size: 13px;
          text-align: center;
          font-style: normal;
          font-weight: 600;
          line-height: 19px;
        }

        &-actions {
          width: 130px;
          display: flex;
          justify-content: end;
          gap: 15px;

          button.MuiButtonBase-root {
            width: 16px;
            padding: 0;

            &:hover {
              background-color: unset;
            }
          }
        }
      }

      &-item.trash {
        .my-services__emails_content-item-info {
          flex: 0 1 430px;

          @media (min-width:1600px) {
            flex: 0 1 485px;
          }
        }

        .my-services__emails_content-item-actions {
          display: none;
        }
      }

    }
  }

  &__ai {
    height: 100%;
    &_container {
      padding: 0 2 * $space;
      height: 100%;
    }

    &_tab {
      min-height: unset !important;
      & .MuiButtonBase-root {
        text-transform: none;
        color: #000;
        font-size: 15px;
        font-weight: 500;
        min-width: unset !important;
        min-height: unset;
      }
      & .MuiButtonBase-root.MuiTab-root.Mui-selected {
        color: #51a3ff;
      }
      & .MuiTabs-indicator {
        background-color: #51a3ff;
      }
      & .MuiTabs-scrollButtons.Mui-disabled {
        opacity: 0.3;
      }
    }

    &_list {
      display: flex;
      flex-direction: column;
      height: 190px;
      overflow-y: auto;
      background-color: #f8fafc;
      border: 1px solid #eeeeee;
      padding: 0 0 0 10px !important;
      margin-top: 15px !important;
      border-radius: 15px;
    }

    &_item {
      margin: 0 !important;
      padding: 0 !important;
      display: flex;
      border-bottom: 1px solid #f2f2f2;

      &-time {
        width: 172px;
        padding: 12px 0 !important;
        border-right: 1px solid #f2f2f2;
        margin-right: 6px;

        P {
          font-family: "Anonymous Pro", monospace;
          font-size: 14px;
        }
      }

      &-log {
        width: calc(100% - 172px);
        padding: 0 !important;
        P {
          font-family: "Anonymous Pro", monospace;
          font-size: 14px;
        }
      }
    }
  }

  &__work-drive {
    background-color: #ffffff;
    height: 100%;
    width: 100%;
    border-radius: 18px;
    padding: 15px 20px;
    padding-bottom: 0;
    box-sizing: border-box;
    box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.15);
    transition: -webkit-transform .4s cubic-bezier(0,0,.4,1);
    transition: transform .4s cubic-bezier(0,0,.4,1);
    transition: transform .4s cubic-bezier(0,0,.4,1),-webkit-transform .4s cubic-bezier(0,0,.4,1);
    will-change: transform;


    &_header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 34px;

      &-view {
        display: flex;
        gap: 20px;
        height: 26px;

        &-back.MuiButtonBase-root {
          color: #7F7F7F;
          font-family: 'OpenSans' !important;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          text-transform: capitalize;
          min-height: unset;
          min-width: unset;
          padding: 0;
          height: 26px;

          &:hover {
            background-color: unset;
          }
        }

        .MuiToggleButtonGroup-root {
          background-color: #F8FAFC;
          border-radius: 12px !important;

          .MuiButtonBase-root {
            background-color: transparent;
            border: unset;
            border-radius: 8px !important;
            color: #9C9C9C;
            padding: 0;
            width: 30px;
            height: 26px;

            &.Mui-selected {
              background-color: #7676801F;
            }

            img {
              width: 20px;
              height: 20px;
            }
          }
        }
      }

      &-more {
        display: flex;
        gap: 15px;
        align-items: start;

        .MuiButtonBase-root {
            color: $blue;
            padding: 5px;
        }

        &_share-popup {
          .MuiPaper-root.MuiMenu-paper.MuiPopover-paper {
            border-radius: 8px;
            background: #FFF;
            box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.10);
            width: 377px;
            box-sizing: border-box;

            .MuiList-root {
              padding: 0;
            }
          }
          &-container {
            padding: 20px;
            display: flex;
            flex-direction: column;
            gap: 15px;
          }

          &-name {
            color: #444;
            font-family: 'OpenSans' !important;
            font-size: 16px;
            font-weight: 600;
            line-height: 11px;
          }

          &-access {
            display: flex;
            gap: 5px;

            p {
              color: #444;
              font-family: 'OpenSans' !important;
              font-size: 14px;
              font-weight: 400;
              line-height: 22px;
            }

            button.MuiButtonBase-root  {
              height: 25px;
              color: #444;
              font-family: 'OpenSans' !important;
              font-size: 14px;
              font-weight: 600 !important;
              line-height: 22px;
              min-height: unset;
              padding: 0;
              text-transform: capitalize;
              border-bottom: 0.5px solid #ACACAC;
              border-radius: 0;

              &:hover {
                background-color: unset;
              }

              img {
                width: 16px;
                height: 16px;
                margin-left: 5px;
              }
            }

            &-popup {
              .MuiPaper-root.MuiMenu-paper.MuiPopover-paper {
                width: 127px;
                border-radius: 8px;
                background: #FFF;
                box-shadow: 0px 3px 10px 0px rgba(0, 0, 0, 0.15);

                .MuiList-root {
                  padding: 14px;
                  display: flex;
                  flex-direction: column;
                  gap: 15px;

                  .MuiButtonBase-root {
                    display: flex;
                    align-items: center;
                    gap: 10px;
                    padding: 0;

                    & span:nth-child(1) {
                      width: 18px;

                      img {
                        width: 18px;
                        height: 18px;
                        vertical-align: middle;
                      }
                    }

                    & span:nth-child(2) {
                      color: #414141;
                      text-align: right;
                      font-family: 'OpenSans' !important;
                      font-size: 14px;
                      font-weight: 400;
                      line-height: 19px;
                    }

                    &.selected {

                      & span:nth-child(2) {
                        font-weight: 600;
                        color: #51A3FF;
                      }
                    }

                    &:hover {
                      background-color: unset;
                    }
                  }
                }
              }
            }
          }

          &-search {
            .MuiFormControl-root {
              min-height: 40px;
              box-sizing: border-box;
            }


            .MuiFormLabel-root {
              color: #888;
              font-family: 'OpenSans' !important;
              font-size: 14px;
              font-weight: 400;
              transform: unset;
              top: 10px;
              left: 10px;

              &.Mui-focused {
                display: none;
              }
            }

            .MuiInputBase-root {
              min-height: 40px;
              padding: 5px 5px !important;
              background: #F8FAFC;
              border-radius: 8px;

              .MuiInputBase-input {
                padding: 0 5px !important;
              }

              .MuiOutlinedInput-notchedOutline {
                border-color: #ECECEC;
                min-height: 40px;
                box-sizing: border-box;
              }

              &:hover {
                .MuiOutlinedInput-notchedOutline {
                  border-color: #51A3FF;
                }
              }

              &.Mui-focused {
                .MuiOutlinedInput-notchedOutline {
                  border-color: #51A3FF;
                  top: 0;
                }

                legend {
                  display: none;
                }
              }

              // .MuiChip-root {
              //   margin: 5px;
              //   height: 32px;
              //   border-radius: 30px;
              //   border: 1px solid #BDBDBD;
              //   background: #F8F9FD;

              //   .MuiChip-label {
              //     padding-left: 6px;
              //     padding-right: 10px;
              //   }
              // }


              .selected-item {
                height: 100%;
                display: flex;
                align-items: center;
                gap: 6px;
                margin: 5px;
                height: 32px;
                border-radius: 30px;
                border: 1px solid #BDBDBD;
                background: #F8F9FD;
                padding-left: 6px;
                padding-right: 10px;

                &-icon {
                  width: 24px;
                  height: 24px;

                  img {
                    width: 100%;
                    height: 100%;
                  }
                }

                &-info {
                  color: #000;
                  font-family: 'OpenSans' !important;
                  font-size: 14px;
                  font-weight: 400;
                }

                &-delete {
                  width: 18px;
                  height: 18px;
                  vertical-align: middle;
                  cursor: pointer;
                }
              }

              .MuiAutocomplete-endAdornment {
                display: none;
              }
            }

            &-list {

              &.MuiAutocomplete-hasClearIcon {
                .MuiFormLabel-root {
                  display: none;
                }
              }
            }

            &-item {
              display: flex;
              gap: 15px;
              cursor: pointer;

              &-icon {
                width: 32px;
                height: 32px;

                img {
                  width: 100%;
                  height: 100%;
                }
              }

              &-info {
                display: flex;
                flex-direction: column;
                justify-content: space-between;

                &-name {
                  color: #444;
                  font-family: 'OpenSans' !important;
                  font-size: 14px;
                  line-height: 1;
                }

                &-email {
                  color: #888;
                  font-size: 13px;
                  font-weight: 400;
                  line-height: 1;
                }
              }
            }
          }

          &-footer {
            display: flex;
            align-items: center;
            width: 100%;
            justify-content: space-between;

            &-copy {

              &-link {
                display: flex;
                align-items: center;
                gap: 6px;
                cursor: pointer;
                color: #51A3FF;
                font-family: 'OpenSans' !important;
                font-size: 14px;
                font-weight: 600;

                img {
                  width: 20px;
                  height: 20px;
                }
              }
            }

            &-share {
              .MuiButtonBase-root {
                text-transform: none;
                color: #fff;
                background-color: #51A3FF;
                padding: 10px 14px;
                border-radius: 30px;
                font-size: 14px;
                font-weight: 600;
                font-family: "OpenSans" !important;
                height: 33px;
                box-sizing: border-box;

                &:hover {
                  color: #fff;
                  background-color: #51A3FF;
                }
              }
            }
          }
        }
      }
    }

    &_content {
      // height: calc(100% - 70px);
      height: 220px;
      // padding-top: 15px;
      box-sizing: border-box;

      &_list {
        height: 100%;
        box-sizing: border-box;
        overflow-y: auto;
        overflow-x: hidden;
        list-style: none;

        &-head {
          // display: grid;
          // grid-template-columns: 55px 150px 150px 150px 70px;
          display: flex;
          padding: 0 15px;
          height: 40px;
          line-height: 40px;
          box-sizing: border-box;

          &-icon {
            width: 60px;
            height: 40px;
            box-sizing: border-box;
          }

          &-name {
            flex: 1 1 150px;
            height: 40px;
            box-sizing: border-box;

            p {
              color: #444;
              font-family: 'OpenSans' !important;
              font-size: 14px;
              font-weight: 600;
            }
          }

          &-date {
            flex: 1 1 150px;
            height: 40px;
            box-sizing: border-box;

            p {
              color: #444;
              font-family: 'OpenSans' !important;
              font-size: 14px;
              font-weight: 600;
            }
          }

          &-size {
            flex: 1 1 150px;
            height: 40px;
            box-sizing: border-box;

            p {
              color: #444;
              font-family: 'OpenSans' !important;
              font-size: 14px;
              font-weight: 600;
            }
          }

          &-type {
            flex: 1 1 70px;
            height: 40px;
            box-sizing: border-box;

            p {
              color: #444;
              font-family: 'OpenSans' !important;
              font-size: 14px;
              font-weight: 600;
            }
          }
        }

        &-body {
          height: 100%;
          overflow-y: auto;

          &-item {
            display: flex;
            padding: 0 15px;
            height: 45px;
            line-height: 45px;
            box-sizing: border-box;
            border-top: 1px solid #F1F1F1;
            cursor: pointer;
            border-radius: 14px;

            &.selected {
              background-color: rgba(118, 118, 118, 0.05);
            }

            &:hover {
              background-color: rgba(118, 118, 118, 0.05);
            }

            &-icon {
              width: 60px;
              height: 45px;
              box-sizing: border-box;
              display: flex;
              justify-content: start;
              align-items: center;

              img {
                width: 24px;
                height: 25px;
              }
            }

            &-name {
              flex: 1 1 150px;
              height: 45px;
              box-sizing: border-box;

              p {
                color: #444;
                font-family: 'OpenSans' !important;
                font-size: 14px;
                font-weight: 400;
              }
            }

            &-date {
              flex: 1 1 150px;
              height: 45px;
              box-sizing: border-box;

              p {
                color: #444;
                font-family: 'OpenSans' !important;
                font-size: 14px;
                font-weight: 400;
              }
            }

            &-size {
              flex: 1 1 150px;
              height: 45px;
              box-sizing: border-box;

              p {
                color: #444;
                font-family: 'OpenSans' !important;
                font-size: 14px;
                font-weight: 400;
              }
            }

            &-type {
              flex: 1 1 70px;
              height: 45px;
              box-sizing: border-box;

              p {
                color: #444;
                font-family: 'OpenSans' !important;
                font-size: 14px;
                font-weight: 400;
              }
            }
          }
        }
      }

      &_list.grid {
        .my-services__work-drive_content_list-head {
          display: none;
        }

        .my-services__work-drive_content_list-body {
          height: 100%;
          display: grid;
          grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
          row-gap: 5px;

          &-item {
            flex-direction: column;
            gap: 6px;
            width: 94px;
            height: 102px;
            padding: 15px;
            box-sizing: border-box;
            border: unset;
            line-height: unset;

            &-icon {
              width: 100%;
              height: 50px;
              display: flex;
              justify-content: center;

              img {
                width: 50px;
                height: 50px;
              }
            }

            &-name {
              p {
                width: 100%;
                text-align: center;
                color: #333;
                font-size: 12px;
                font-weight: 400;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                line-height: normal;
              }
            }

            &-date, &-size, &-type {
              display: none;
            }
          }
        }
      }

      &_upload-mode {
        display: flex;
        align-items: center;
        height: 100%;
        gap: 15px;
        box-sizing: border-box;


        &_files {
          flex: 0 0 300px;
          max-width: 50%;
          height: 100%;

          &-header {
            height: 40px;
            line-height: 40px;
            display: flex;
            padding: 0 15px;
            gap: 15px;
            border-bottom: 1px solid #F1F1F1;
            box-sizing: border-box;

            &-icon {
              width: 30px;
            }

            &-name {
              width: 100%;
              color: #444;
              font-family: 'OpenSans' !important;
              font-size: 14px;
              font-style: normal;
              font-weight: 600;
            }
          }

          &-list {
            overflow: auto;
            height: calc(100% - 40px);

            &-item {
              height: 45px;
              line-height: 45px;
              display: flex;
              padding: 0 15px;
              gap: 15px;
              border-bottom: 1px solid #F1F1F1;
              box-sizing: border-box;

              &-icon {
                width: 30px;
                height: 45px;
                display: flex;
                align-items: center;
                justify-content: center;

                img {
                  width: 25px;
                  height: 25px;
                }
              }

              &-name {
                width: 100%;
                color: #444;
                font-family: 'OpenSans' !important;
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
              }
            }
          }
        }

        &_input {
          flex: 1 1 295px;

          &-attach {
            border-radius: 20px !important;
            border: unset !important;
            border-image-source: url("../../../assets/images/my-services/workdrive/line.png") !important;
            border-image-slice: 30 !important;
            border-image-width: 8px !important;

            min-height: 220px !important;
            align-items: center;
            display: flex;
            justify-content: center;

            &.selected {
              min-height: 200px !important;
            }

            .MuiTypography-root {
              div {
                display: flex;
                flex-direction: column;
                gap: 3px;

                .title {
                  color: #444;
                  font-family: 'OpenSans' !important;
                  font-size: 14px;
                  font-weight: 600;
                  line-height: normal;

                  .blue {
                    color: #4382C4;
                    font-family: 'OpenSans' !important;
                    font-size: 14px;
                    font-weight: 600;
                    line-height: normal;
                  }
                }

                .subtitle {
                  color: #B4B4B4;
                  font-family: 'OpenSans' !important;
                  font-size: 12px;
                  font-weight: 400;
                  line-height: normal;
                }
              }
            }

            .MuiDropzoneArea-textContainer {
                display: flex;
                flex-direction: column-reverse;
                justify-content: center;

                .MuiTypography-root.MuiDropzoneArea-text {
                    color: #888888;
                    font-size: 13px;
                    margin-top: 12px;
                    margin-bottom: 12px;
                    font-family: 'OpenSans' !important;
                }

                img {
                    width: 41px;
                    height: 41px;
                    margin: 0 auto;
                }
            }
          }
        }

        &_footer {
          width: 100%;
        }
      }

      &_upload-mode.grid {

        .my-services__work-drive_content_upload-mode_files-header {
          display: none;
        }

        .my-services__work-drive_content_upload-mode_files-list {
          height: 100%;
          display: grid;
          grid-template-columns: 1fr 1fr 1fr;
          row-gap: 4px;

          &-item {
            flex-direction: column;
            gap: 6px;
            width: 94px;
            height: 102px;
            padding: 15px;
            box-sizing: border-box;
            border: unset;
            line-height: unset;

            &-icon {
              width: 100%;
              height: 50px;
              display: flex;
              justify-content: center;

              img {
                width: 50px;
                height: 50px;
              }
            }

            &-name {
                width: 100%;
                text-align: center;
                color: #333;
                font-size: 12px;
                font-weight: 400;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                line-height: normal;
            }

            &-date, &-size, &-type {
              display: none;
            }
          }
        }
      }
    }

    &_footer {
      display: flex;
      height: 45px;
      box-sizing: border-box;
      display: flex;
      justify-content: center;
      align-items: center;
      padding-top: 10px;

      p {
        width: 100%;
        text-align: center;
        color: #959595;
        font-family: 'OpenSans' !important;
        font-size: 11px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        letter-spacing: 0.11px;
      }
    }


  }

  &__todos {
    background-color: #ffffff;
    height: 100%;
    width: 100%;
    border-radius: 18px;
    padding: 15px 20px;
    padding-bottom: 0;
    box-sizing: border-box;
    box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.15);
    transition: -webkit-transform .4s cubic-bezier(0,0,.4,1);
    transition: transform .4s cubic-bezier(0,0,.4,1);
    transition: transform .4s cubic-bezier(0,0,.4,1),-webkit-transform .4s cubic-bezier(0,0,.4,1);
    will-change: transform;


    &_header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 31px;

      &-view {
        display: flex;
        gap: 20px;
        height: 26px;

        & button.MuiButtonBase-root {
          color: #333;
          font-size: 13px;
          text-transform: capitalize;
          font-family: 'OpenSans' !important;
          font-weight: 400 !important;
          padding: 0;

          &:hover {
              background-color: unset;
          }

          img {
              width: 18px;
              height: 18px;
              margin-left: 8px;
          }
        }

        .MuiToggleButtonGroup-root {
          background-color: #F8FAFC;
          border-radius: 12px !important;

          .MuiButtonBase-root {
            background-color: transparent;
            border: unset;
            border-radius: 8px !important;
            color: #9C9C9C;
            padding: 0;
            width: 30px;
            height: 26px;

            &.Mui-selected {
              background-color: #7676801F;
            }

            img {
              width: 20px;
              height: 20px;
            }
          }
        }
      }

      &-more {
        display: flex;
        gap: 15px;
        align-items: start;

        .MuiButtonBase-root {
            color: $blue;
            padding: 5px;
        }

        &_share-popup {
          .MuiPaper-root.MuiMenu-paper.MuiPopover-paper {
            border-radius: 8px;
            background: #FFF;
            box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.10);
            width: 377px;
            box-sizing: border-box;

            .MuiList-root {
              padding: 0;
            }
          }
          &-container {
            padding: 20px;
            display: flex;
            flex-direction: column;
            gap: 15px;
          }

          &-name {
            color: #444;
            font-family: 'OpenSans' !important;
            font-size: 16px;
            font-weight: 600;
            line-height: 11px;
          }

          &-access {
            display: flex;
            gap: 5px;

            p {
              color: #444;
              font-family: 'OpenSans' !important;
              font-size: 14px;
              font-weight: 400;
              line-height: 22px;
            }

            button.MuiButtonBase-root  {
              height: 25px;
              color: #444;
              font-family: 'OpenSans' !important;
              font-size: 14px;
              font-weight: 600 !important;
              line-height: 22px;
              min-height: unset;
              padding: 0;
              text-transform: capitalize;
              border-bottom: 0.5px solid #ACACAC;
              border-radius: 0;

              &:hover {
                background-color: unset;
              }

              img {
                width: 16px;
                height: 16px;
                margin-left: 5px;
              }
            }

            &-popup {
              .MuiPaper-root.MuiMenu-paper.MuiPopover-paper {
                width: 127px;
                border-radius: 8px;
                background: #FFF;
                box-shadow: 0px 3px 10px 0px rgba(0, 0, 0, 0.15);

                .MuiList-root {
                  padding: 14px;
                  display: flex;
                  flex-direction: column;
                  gap: 15px;

                  .MuiButtonBase-root {
                    display: flex;
                    align-items: center;
                    gap: 10px;
                    padding: 0;

                    & span:nth-child(1) {
                      width: 18px;

                      img {
                        width: 18px;
                        height: 18px;
                        vertical-align: middle;
                      }
                    }

                    & span:nth-child(2) {
                      color: #414141;
                      text-align: right;
                      font-family: 'OpenSans' !important;
                      font-size: 14px;
                      font-weight: 400;
                      line-height: 19px;
                    }

                    &.selected {

                      & span:nth-child(2) {
                        font-weight: 600;
                        color: #51A3FF;
                      }
                    }

                    &:hover {
                      background-color: unset;
                    }
                  }
                }
              }
            }
          }

          &-search {
            .MuiFormControl-root {
              min-height: 40px;
              box-sizing: border-box;
            }


            .MuiFormLabel-root {
              color: #888;
              font-family: 'OpenSans' !important;
              font-size: 14px;
              font-weight: 400;
              transform: unset;
              top: 10px;
              left: 10px;

              &.Mui-focused {
                display: none;
              }
            }

            .MuiInputBase-root {
              min-height: 40px;
              padding: 5px 5px !important;
              background: #F8FAFC;
              border-radius: 8px;

              .MuiInputBase-input {
                padding: 0 5px !important;
              }

              .MuiOutlinedInput-notchedOutline {
                border-color: #ECECEC;
                min-height: 40px;
                box-sizing: border-box;
              }

              &:hover {
                .MuiOutlinedInput-notchedOutline {
                  border-color: #51A3FF;
                }
              }

              &.Mui-focused {
                .MuiOutlinedInput-notchedOutline {
                  border-color: #51A3FF;
                  top: 0;
                }

                legend {
                  display: none;
                }
              }

              // .MuiChip-root {
              //   margin: 5px;
              //   height: 32px;
              //   border-radius: 30px;
              //   border: 1px solid #BDBDBD;
              //   background: #F8F9FD;

              //   .MuiChip-label {
              //     padding-left: 6px;
              //     padding-right: 10px;
              //   }
              // }


              .selected-item {
                height: 100%;
                display: flex;
                align-items: center;
                gap: 6px;
                margin: 5px;
                height: 32px;
                border-radius: 30px;
                border: 1px solid #BDBDBD;
                background: #F8F9FD;
                padding-left: 6px;
                padding-right: 10px;

                &-icon {
                  width: 24px;
                  height: 24px;

                  img {
                    width: 100%;
                    height: 100%;
                  }
                }

                &-info {
                  color: #000;
                  font-family: 'OpenSans' !important;
                  font-size: 14px;
                  font-weight: 400;
                }

                &-delete {
                  width: 18px;
                  height: 18px;
                  vertical-align: middle;
                  cursor: pointer;
                }
              }

              .MuiAutocomplete-endAdornment {
                display: none;
              }
            }

            &-list {

              &.MuiAutocomplete-hasClearIcon {
                .MuiFormLabel-root {
                  display: none;
                }
              }
            }

            &-item {
              display: flex;
              gap: 15px;
              cursor: pointer;

              &-icon {
                width: 32px;
                height: 32px;

                img {
                  width: 100%;
                  height: 100%;
                }
              }

              &-info {
                display: flex;
                flex-direction: column;
                justify-content: space-between;

                &-name {
                  color: #444;
                  font-family: 'OpenSans' !important;
                  font-size: 14px;
                  line-height: 1;
                }

                &-email {
                  color: #888;
                  font-size: 13px;
                  font-weight: 400;
                  line-height: 1;
                }
              }
            }
          }

          &-footer {
            display: flex;
            align-items: center;
            width: 100%;
            justify-content: space-between;

            &-copy {

              &-link {
                display: flex;
                align-items: center;
                gap: 6px;
                cursor: pointer;
                color: #51A3FF;
                font-family: 'OpenSans' !important;
                font-size: 14px;
                font-weight: 600;

                img {
                  width: 20px;
                  height: 20px;
                }
              }
            }

            &-share {
              .MuiButtonBase-root {
                text-transform: none;
                color: #fff;
                background-color: #51A3FF;
                padding: 10px 14px;
                border-radius: 30px;
                font-size: 14px;
                font-weight: 600;
                font-family: "OpenSans" !important;
                height: 33px;
                box-sizing: border-box;

                &:hover {
                  color: #fff;
                  background-color: #51A3FF;
                }
              }
            }
          }
        }
      }
    }

    &_filter {
      padding: 0 14px;
      margin-top: 10px;
      display: flex;
      justify-content: space-between;

      &-add {
          &-btn.MuiButtonBase-root  {
              border-radius: 6px;
              color: #51A3FF;
              font-size: 14px;
              font-family: 'OpenSans' !important;
              text-transform: capitalize;
              height: 28px;
              padding: 0;

              &:hover {
                  background-color: unset;
              }

              img {
                  width: 16px;
                  height: 16px;
                  margin-right: 9px;
              }
          }
      }

      &-sort {
          &-btn.MuiButtonBase-root  {
              color: #444;
              font-size: 13px;
              text-transform: capitalize;
              height: 28px;
              padding: 0 8px;
              font-family: 'OpenSans' !important;

              &:hover {
                  background-color: unset;
              }

              img {
                  width: 18px;
                  height: 18px;
              }
          }

          &-item.MuiButtonBase-root {
              color: #333;
              font-size: 13px;
              text-transform: capitalize;
              font-weight: 400 !important;
              padding: 0;
              font-family: 'OpenSans' !important;

              &:hover {
                  background-color: unset;
              }

              img {
                  width: 20px;
                  height: 20px;
                  margin-left: 8px;
              }
          }
      }
    }



    &_content {
      height: 220px;
      box-sizing: border-box;
      display: flex;
      flex-direction: column;

      &-input {
        margin-top: 5px;
        height: 40px;

        .MuiFormControl-root {
            width: 100%;
            background-color: #F8FAFC;
            height: 40px;
            box-sizing: border-box;

            .MuiInputBase-root {
                height: 40px;
                box-sizing: border-box;

                &:hover {
                    .MuiOutlinedInput-notchedOutline {
                        border-color: #51A3FF;
                    }
                }

                &.Mui-focused {
                    .MuiOutlinedInput-notchedOutline {
                        border-color: #51A3FF;
                    }
                }

                .MuiInputBase-input {
                    padding: 0;
                    color: #333;
                    font-size: 14px;

                    &::placeholder {
                        color: #ACACAC;
                        font-size: 14px;
                        opacity: 1;
                    }
                }
            }

            .MuiOutlinedInput-notchedOutline {
                border-radius: 8px;
                border: 1px solid #ECECEC;
                height: 45px;
                box-sizing: border-box;
            }
        }
      }

      &-list {
        width: 100%;
        display: flex;
        flex-direction: column;
        height: 100%;
        overflow: auto;

        &-task {
            width: 100%;
            display: flex;
            flex-direction: column;
            // min-height: 50px;
            box-sizing: border-box;
            border-top: 1px solid #E8E7E4;
            padding: 10px 14px;
            box-sizing: border-box;
            justify-content: center;

            &-info {
                width: 100%;
                display: flex;
                align-items: center;

                &-checkbox {
                    width: 25px;
                    margin-right: 10px !important;
                    padding: 0 !important;

                    img {
                        width: 18px;
                        height: 18px;
                    }
                }

                &-title {
                    flex: 1 1 100%;
                    overflow: hidden;
                    display: flex;
                    flex-direction: column;
                    gap: 8px;

                    p {
                        width: 100%;
                        white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        color: #333;
                        font-size: 14px;
                        font-family: 'OpenSans' !important;

                        &.done {
                            color: #ADABA8;
                            text-decoration: line-through;
                        }
                    }

                    &-date {
                        display: flex;
                        gap: 5px;
                        align-items: center;
                        color: #51A3FF;
                        font-family: 'OpenSans' !important;
                        font-size: 13px;

                        img {
                            width: 16px;
                            height: 16px;
                        }
                    }
                }

                &-members {
                    margin-right: 18px;

                    .MuiAvatarGroup-root {
                        .MuiAvatar-root {
                            width: 30px;
                            height: 30px;
                            font-size: 13px;
                            box-sizing: border-box !important;
                        }
                    }
                }

                &-numbers {
                    margin-right: 20px;
                    color: #ADABA8;
                    font-size: 14px;
                    text-wrap: nowrap;
                    white-space: nowrap;
                }

                &-action {
                    width: 18px;
                    box-sizing: border-box;
                    margin-right: 5px;

                    button.MuiButtonBase-root {
                        padding: 5px;
                    }

                    &-item.MuiButtonBase-root {
                        color: #333;
                        font-size: 13px;
                        text-transform: capitalize;
                        font-weight: 400 !important;
                        padding: 0;

                        &:hover {
                            background-color: unset;
                        }

                        img {
                            width: 18px;
                            height: 18px;
                            margin-left: 8px;
                        }
                    }
                }
            }

            &-input {
                margin-top: 10px;
                padding-left: 24px;

                .MuiFormControl-root {
                    width: 100%;
                    background-color: #F8FAFC;
                    height: 40px;
                    box-sizing: border-box;

                    .MuiInputBase-root {
                        height: 40px;
                        box-sizing: border-box;

                        &:hover {
                            .MuiOutlinedInput-notchedOutline {
                                border-color: #51A3FF;
                            }
                        }

                        &.Mui-focused {
                            .MuiOutlinedInput-notchedOutline {
                                border-color: #51A3FF;
                            }
                        }

                        .MuiInputBase-input {
                            padding: 0;
                            color: #333;
                            font-size: 14px;

                            &::placeholder {
                                color: #ACACAC;
                                font-size: 14px;
                                opacity: 1;
                            }
                        }
                    }

                    .MuiOutlinedInput-notchedOutline {
                        border-radius: 8px;
                        border: 1px solid #ECECEC;
                        height: 45px;
                        box-sizing: border-box;
                    }
                }
            }


        }

        &-action-item span {
          font-family: 'OpenSans' !important;

          img {
            width: 20px;
            height: 20px;
          }
        }

        & .accordion {
            box-shadow: unset;
            border: unset;
            background-color: unset;
            box-sizing: border-box;

            &.Mui-expanded {
                margin: 0;

                .accordion-summary {
                    min-height: unset;
                }
            }

            &::before {
                content: unset;
            }

            &-summary {
                padding: 0;
                flex-direction: row-reverse;
                // border-top: 1px solid #E8E7E4;
                min-height: 50px;
                box-sizing: border-box;

                &.Mui-focusVisible {
                    background-color: unset;
                }

                .MuiAccordionSummary-content {
                    margin: 0;
                    overflow: hidden;

                    .todos-page-main_list-task-info {
                        border-top: unset;
                    }
                }

                .MuiAccordionSummary-expandIconWrapper {
                    position: absolute;
                    left: -3px;

                    &.Mui-expanded {
                        transform: rotate(90deg);
                    }
                }
            }

            &-details {
                padding: 0 15px;
            }
        }
      }
    }

  }

  &__bank {
    width: 100%;
    height: 100%;

    &_container {
      padding: $space 2 * $space;
      box-sizing: border-box;

      width: inherit;
      height: inherit;
    }

    &_list {
      & .MuiListItem-root {
        padding: 10px 0;
      }
    }

    &_item {
      &-currency {
        width: 50px;
        height: 50px;
        border-radius: 12px;
        color: #fff;
        display: flex;
        justify-content: center;
        align-items: center;
      }

      &-currency-eur {
        background-color: #edc7a4;
      }

      &-currency-usd {
        background-color: #a8dfe2;
      }

      &-currency-other {
        background-color: #b596d5;
      }

      &-balance-info {
        margin-left: 10px;

        & .MuiListItemText-primary {
          font-size: 12px;
        }
        & .MuiListItemText-secondary {
          font-size: 18px;
          font-weight: 500;
          color: #000;
        }
      }

      &-balance-card {
        text-align: right;
        margin-right: 40px;
      }
    }

    &_item:not(:last-child) {
      border-bottom: 1px solid #f2f2f2;
    }
  }

  &__logo-builder {
    width: 100%;
    height: 100%;

    &_container {
      width: inherit;
      height: inherit;
      padding: 2 * $space 2 * $space;
      box-sizing: border-box;

      display: flex;
      flex-direction: column;
    }

    &_form-wrapper {
      margin-top: 50px;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }

    &_form {
      &_title {
        color: #000;
      }
      &_input {
        margin-top: 20px !important;
        font-size: 15px;
        color: #363c65;
        border-radius: 8px;
        outline: none;
        width: 100%;
        box-sizing: border-box;
        background-color: #f8fafc;
        transition: 0.2s;
        border: 1px solid #ececec;
        padding: 10px 8px;
        font-size: 15px;
        background-color: #f8fafc;
        & label {
          color: #acacac;
        }
        & .MuiInputBase-root {
          border-radius: 8px;
        }
        & .MuiInput-underline:after {
          border-bottom-color: green;
        }
        & .MuiOutlinedInput-root {
          & fieldset {
            border-color: #ececec;
          }
          &:hover fieldset {
            border-color: $blue;
          }
          &.Mui-focused fieldset {
            border-color: $blue;
          }
        }

        &::placeholder {
          color: #acacac;
        }
      }

      &_btn {
        margin-top: 20px;
        text-align: right;
      }
    }
  }

  &__sites-copying {
    width: 100%;
    height: 100%;

    &_container {
      width: inherit;
      height: inherit;
      padding: 2 * $space 2 * $space;
      box-sizing: border-box;

      display: flex;
      flex-direction: column;
    }

    &_form-wrapper {
      margin-top: 50px;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }

    &_form {
      &_title {
        color: #000;
      }
      &_input {
        margin-top: 20px !important;
        font-size: 15px;
        color: #363c65;
        border-radius: 8px;
        outline: none;
        width: 100%;
        box-sizing: border-box;
        background-color: #f8fafc;
        transition: 0.2s;
        border: 1px solid #ececec;
        padding: 10px 8px;
        font-size: 15px;
        background-color: #f8fafc;
        & label {
          color: #acacac;
        }
        & .MuiInputBase-root {
          border-radius: 8px;
        }
        & .MuiInput-underline:after {
          border-bottom-color: green;
        }
        & .MuiOutlinedInput-root {
          & fieldset {
            border-color: #ececec;
          }
          &:hover fieldset {
            border-color: $blue;
          }
          &.Mui-focused fieldset {
            border-color: $blue;
          }
        }

        &::placeholder {
          color: #acacac;
        }
      }

      &_btn {
        margin-top: 20px;
        text-align: right;
      }
    }
  }

  &__website-builder {
    width: 100%;
    height: 100%;

    &_container {
      width: inherit;
      height: inherit;
      padding: 2 * $space 2 * $space;
      box-sizing: border-box;

      display: flex;
      flex-direction: column;
    }

    &_form-wrapper {
      margin-top: 40px;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }

    &_form {
      &_title {
        color: #000;
      }
      &_input {
        margin-top: 20px !important;
        font-size: 15px;
        color: #363c65;
        border-radius: 8px;
        outline: none;
        width: 100%;
        box-sizing: border-box;
        background-color: #f8fafc;
        transition: 0.2s;
        border: 1px solid #ececec;
        padding: 10px 8px;
        font-size: 15px;
        background-color: #f8fafc;
        & label {
          color: #acacac;
        }
        & .MuiInputBase-root {
          border-radius: 8px;
        }
        & .MuiInput-underline:after {
          border-bottom-color: green;
        }
        & .MuiOutlinedInput-root {
          & fieldset {
            border-color: #ececec;
          }
          &:hover fieldset {
            border-color: $blue;
          }
          &.Mui-focused fieldset {
            border-color: $blue;
          }
        }

        &::placeholder {
          color: #acacac;
        }
      }

      &_btn {
        margin-top: 20px;
        text-align: right;
      }
    }
  }

  &__disc {
    width: 100%;
    height: 100%;

    &_container {
      width: inherit;
      height: inherit;
      padding: 2 * $space 2 * $space;
      box-sizing: border-box;

      display: flex;
      flex-direction: column;
      justify-content: center;
    }

    &-wrapper {
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }

    &_title {
      color: #000;
      margin-bottom: 20px;
    }

    &_info {
      &-progress {
        height: 15px !important;
      }

      &-text {
        margin-top: $space;
        font-size: 12px;
      }
    }

    &_btn {
      margin-top: 20px;
      text-align: right;
    }
  }

  &__video-conference {
    background-color: #ffffff;
    height: 100%;
    width: 100%;
    border-radius: 18px;
    padding: 15px 10px;
    padding-bottom: 0;
    box-sizing: border-box;
    box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.15);
    transition: -webkit-transform .4s cubic-bezier(0,0,.4,1);
    transition: transform .4s cubic-bezier(0,0,.4,1);
    transition: transform .4s cubic-bezier(0,0,.4,1),-webkit-transform .4s cubic-bezier(0,0,.4,1);
    will-change: transform;

    &_header {
      height: 25px;
      width: 100%;
      padding: 0 10px;
      display: flex;
      justify-content: space-between;
      box-sizing: border-box;

      &-view {
        &_tab {
          min-height: unset !important;
          height: 28px;
          align-items: end;

          .MuiTabs-flexContainer {
            gap: 20px;
            height: 28px;
          }

          .MuiButtonBase-root {
            padding: 6px 15px;
            min-width: unset;
            width: auto;
            color: #444;
            font-size: 13px;
            font-weight: 400 !important;
            text-transform: none;
            font-family: 'OpenSans' !important;
            height: 28px;
            min-height: unset;

            p {
              font-size: 13px;
              font-weight: 400;
              text-transform: none;
              font-family: "OpenSans" !important;
              display: flex;
              gap: 6px;

              .unread-num {
                background-color: #4382C4;
                width: 16px;
                height: 16px;
                display: inline-block;
                color: #FFF;
                font-family: 'OpenSans' !important;
                font-weight: 600;
                font-size: 11px;
                line-height: normal;
                border-radius: 50%;
              }
            }

            &.Mui-selected {
              color: #4F4F4F;
              font-family: "OpenSans" !important;
              font-weight: 600 !important;
              font-size: 13px;
              border-radius: 8px;
              // opacity: 0.12;
              background: #82828226;

              p {
                font-weight: 600;

                .unread-num {
                  font-weight: 600;
                }
              }
            }
          }

          .MuiTabs-indicator {
            // bottom: 8px;
            background-color: #51A3FF;
            height: 1.5px;
          }
        }
      }

      &-more {
        display: flex;
        gap: 15px;
        align-items: start;
        justify-content: end;
        // flex: 1 1;

        .MuiButtonBase-root {
            color: $blue;
            padding: 0;
            width: 25px;
            height: 25px;
        }
      }
    }

    &_content {
      height: calc(100% - 50px);
      padding: 0 10px;
      box-sizing: border-box;
      overflow-y: auto;
      // padding: 0 5px;
      margin-top: 20px;


      &-list {
        display: flex;
        flex-direction: column;
        gap: 6px;
        width: 100%;
        height: 100%;
        box-sizing: border-box;
      }

      &-item {
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 10px;

        border-radius: 12px;
        border: 1px solid #F7F7F7;
        background: #FFF;
        box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.05);
        padding: 10px 14px;
        height: 65px;
        box-sizing: border-box;

        &:hover {
          border: 1.5px solid#4382C4;

          .my-services__video-conference_content-item_meet {
            display: inline-block;
          }
        }

        &_icon {
            width: 38px;
            height: 38px;

            .MuiAvatar-root {
                width: inherit;
                height: inherit;
                box-sizing: border-box;
                padding: 5px;
                font-size: 15px;
                background-color: #DCD6E8;
                color: #653EB9;
            }
        }

        &_details {
            display: flex;
            flex-direction: column;
            flex: 1;
            height: 100%;
            justify-content: space-between;
            overflow: hidden;

            &-name {
                color: #333;
                font-family: "OpenSans" !important;
                font-size: 14px;
                font-style: normal;
                font-weight: 600;
                line-height: 22px;
            }

            &-info {
              display: flex;
              gap: 5px;
              align-items: center;

              &-date {
                color: #4F4F4F;
                font-family: "OpenSans" !important;
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: 22px;
              }

              &-description {
                color: #828282;
                font-family: "OpenSans" !important;
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: 22px;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                flex: 1;
              }
            }


        }

        &_meet {
          width: 100px;
          display: none;

          &-btn.MuiButtonBase-root {
            padding: 10px 14px;
            height: 35px;
            color: #FFF;
            text-transform: none;
            font-family: "OpenSans" !important;
            font-size: 13px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
            border-radius: 30px;
            background-color: #4382C4;

            &:hover {
              background-color: #4382C4;
              color: #fff;
            }
          }
        }

        &_actions {
          width: auto;
          text-align: right;
          height: 100%;
          display: flex;
          align-items: center;

          .MuiSvgIcon-root {
              font-size: 20px;
          }
        }

      }

      &-emptylist {
        width: 100%;
        height: 90%;
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;

        &-text {
          width: 210px;
          color: #828282;
          text-align: center;
          font-family: "OpenSans" !important;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
        }
      }

    }
  }

  &__services {
    width: 100%;
    height: 100%;

    background: rgba(220, 220, 220, 0.5);
    backdrop-filter: blur(10px);
    border-radius: 18px;

    &_container {
      padding: 3 * $space;
      width: 100%;
      height: 100%;
      box-sizing: border-box;
    }

    &_title {
      font-size: 22px;
      color: #000;
      font-weight: 400;
    }

    &_list {
      margin-top: 30px;
      display: grid;
      grid-template-columns: 1fr 1fr 1fr 1fr;
      gap: 18px;
    }

    &_item {
      justify-self: center;

      &-icon {
        display: inline-block;
        width: 55px;
        height: 55px;

        & img {
          width: inherit;
          height: inherit;
        }
      }
    }
  }
}

.layoutLG {
  .my-services {
    &__ai {

      &_tab {
        & .MuiButtonBase-root {
          font-size: 13px;
        }
      }

      &_list {
        height: 170px;
        margin-top: 10px !important;
      }

      &_item {
        &-time {
          width: 163px;
          P {
            font-size: 13px;
          }
        }

        &-log {
          width: calc(100% - 163px);
          P {
            font-size: 13px;
          }
        }
      }
    }

    &__tasks {
      &_tab {
        min-height: unset;
      }
      &_item {
        &.MuiListItem-root {
          padding: 9px 0 !important;
        }

        & .MuiTypography-body1 {
          font-size: 14px;
        }

        & .MuiTypography-body2 {
          font-size: 14px;
        }
      }
    }

    &__domains {

      &-page {
        &_header {
          justify-content: space-between;
          padding-left: 2 * $space;
          padding-right: 2 * $space;
        }
        &_header-col {
          display: flex;
          flex-wrap: nowrap;
          align-items: center;
        }
        &_row {
          display: flex;
          flex-wrap: nowrap;
          padding: $space;
          border-top: 1px solid $delimiter;
          &:first-of-type {
            border-top: none;
            padding-top: 0;
          }
        }
        &_col {
          width: 22%;
          &:last-of-type {
            width: 12%;
          }
        }
      }

      &_container {
        padding: 15px 20px;
      }

      &_header {
        font-size: 14px;
      }

      &_items {
        & .MuiListItemText-root {
          & .MuiListItemText-primary {
            font-size: 14px;
          }

          & .MuiListItemText-secondary {
            font-size: 13px;
          }
        }
      }
    }

    &__cloud-hosting {
      &_container {
        padding: 10px 20px;
      }

      &_storage {
        &-heading {
          font-size: 14px;
        }

        &_ring {
          width: 120px;
          height: 120px;
          &-title {
            font-size: 14px;
          }
        }
      }

      &_site {
        &-heading {
          font-size: 14px;
          margin-top: 5px;
        }


        &-list {
          & .MuiListItemText-primary {
            font-size: 14px;
          }

          & .MuiListItemText-secondary {
            font-size: 13px;

          }
        }
      }

    }

    &__work-drive {
      &_upload-text {
        h3 {
          font-size: 14px;
        }
      }
    }

    &__bank {

      &_container {
        padding: 5px 20px;
      }

      &_list {
        & .MuiListItem-root {
          padding: 8px 0;
        }
      }

      &_item {
        &-currency {
          width: 45px;
          height: 45px;
        }

        &-balance-info {
          & .MuiListItemText-secondary {
            font-size: 16px;
          }
        }

        &-balance-card {
          .MuiListItemText-primary {
            font-size: 14px;
          }
        }
      }
    }

    &__sites-copying {

      &_container {
        padding: 15px 20px;
      }

      &_description {
        font-size: 14px;
      }

      &_form-wrapper {
        margin-top: 30px;
      }

      &_form {
        &_title {
          font-size: 14px;
        }
        &_input {
          font-size: 14px;
        }

        &_btn {
          .btn {
            font-size: 14px;
          }
        }
      }
    }

    &__website-builder {
      &_container {
        padding: 10px 20px;
      }

      &_description {
        font-size: 14px;
      }

      &_form-wrapper {
        margin-top: 25px;
      }

      &_form {
        &_title {
          font-size: 14px;
        }

        &_input {
          font-size: 14px;
          margin-top: 15px !important;
        }
      }
    }

    &__video-conference {

      &_tab {
        min-height: unset;

        & .MuiButtonBase-root {
          font-size: 14px;
        }
      }

      &_tabpanel {
        padding: 10px 0 10px;
      }

      &_form {
        gap: 15px;

        &_label {
          font-size: 14px;
        }

        &_input {
          font-size: 14px;
        }

        &_date {
          font-size: 14px;

          & input {
            font-size: 14px;
          }
        }

        &_time {
          font-size: 14px;

          & input {
            font-size: 14px;
          }
        }

        &-copy-link {
          & .MuiGrid-root {
            padding-top: 0 !important;

            & .btn {
              font-size: 14px;
              padding: 8px 20px;
            }

            .btn-outline {
              font-size: 14px;
              padding: 8px 20px;
            }
          }
        }
      }
    }

    &__disc {

      &_title {
        font-size: 14px;
      }

      &_info {
        &-progress {
          height: 13px !important;
        }
      }

      &_btn {

        & .btn {
          font-size: 14px;
        }
      }
    }

    &__services {

      &_title {
        font-size: 20px;
      }

      &_list {
        margin-top: 20px;
        gap: 15px;
      }

      &_item {
        &-icon {
          width: 50px;
          height: 50px;
        }
      }
    }
  }
}


.layoutMD {
  .my-services {
    &__ai {

      &_tab {
        & .MuiButtonBase-root {
          font-size: 13px;
        }
      }

      &_list {
        height: 220px;
        margin-top: 10px !important;
      }

      &_item {
        &-time {
          padding: 10px 5px !important;
          width: 92px;
          P {
            font-size: 13px;
            line-height: 18px;
          }
        }

        &-log {
          width: calc(100% - 90px);
          P {
            font-size: 13px;
            line-height: 18px;
          }
        }
      }
    }

    &__tasks {
      &_tab {
        min-height: unset;
      }
      &_item {
        &.MuiListItem-root {
          padding: 15px 0 !important;
        }

        & .MuiTypography-body1 {
          font-size: 14px;
        }

        & .MuiTypography-body2 {
          font-size: 13px;
        }

        &-more {
          & .MuiSvgIcon-root {
            width: 17px;
            height: 17px;
          }
        }
      }
    }

    &__domains {

      &-page {
        &_header {
          justify-content: space-between;
          padding-left: 2 * $space;
          padding-right: 2 * $space;
        }
        &_header-col {
          display: flex;
          flex-wrap: nowrap;
          align-items: center;
        }
        &_row {
          display: flex;
          flex-wrap: nowrap;
          padding: $space;
          border-top: 1px solid $delimiter;
          &:first-of-type {
            border-top: none;
            padding-top: 0;
          }
        }
        &_col {
          width: 22%;
          &:last-of-type {
            width: 12%;
          }
        }
      }

      &_container {
        padding: 15px 20px;
      }

      &_header {
        font-size: 14px;
      }

      &_items {
        & .MuiListItemText-root {
          & .MuiListItemText-primary {
            font-size: 14px;
          }

          & .MuiListItemText-secondary {
            font-size: 13px;
          }
        }
      }
    }

    &__cloud-hosting {
      &_container {
        padding: 10px 20px;
      }

      &_storage {
        &-heading {
          font-size: 14px;
        }

        &_ring {
          width: 120px;
          height: 120px;
          &-title {
            font-size: 14px;
          }
        }
      }

      &_site {
        &-heading {
          font-size: 14px;
          margin-top: 5px;
        }


        &-list {
          & .MuiListItemText-primary {
            font-size: 14px;
          }

          & .MuiListItemText-secondary {
            font-size: 13px;

          }
        }
      }

    }

    &__work-drive {
      &_upload-text {
        h3 {
          font-size: 14px;
        }
      }
    }

    &__bank {

      &_container {
        padding: 5px 0 20px 5px;
      }

      &_list {
        & .MuiListItem-root {
          padding: 15px 0;
        }
      }

      &_item {
        & .MuiListItemIcon-root:first-child {
          min-width: 45px;
        }
        &-currency {
          width: 40px;
          height: 40px;
        }

        &-balance-info {
          min-width: 100px;
          margin-left: 3px;

          & .MuiListItemText-secondary {
            font-size: 14px;
          }
        }

        &-balance-card {
          margin-right: 0;
          .MuiListItemText-primary {
            font-size: 11px;
          }
        }

        &-more {
          & .MuiSvgIcon-root {
            width: 17px;
            height: 17px;
          }
        }
      }
    }

    &__sites-copying {

      &_container {
        padding: 15px 20px;
      }

      &_description {
        font-size: 14px;
      }

      &_form-wrapper {
        margin-top: 30px;
      }

      &_form {
        &_title {
          font-size: 14px;
        }
        &_input {
          font-size: 14px;
        }
      }
    }

    &__website-builder {
      &_container {
        padding: 10px 20px;
      }

      &_description {
        font-size: 14px;
      }

      &_form-wrapper {
        margin-top: 25px;
      }

      &_form {
        &_title {
          font-size: 14px;
        }

        &_input {
          font-size: 14px;
          margin-top: 15px !important;
        }

      }
    }

    &__video-conference {

      &_container {
        padding: 0 15px;
      }

      &_tab {
        min-height: unset;

        & .MuiButtonBase-root {
          font-size: 14px;
        }
      }

      &_tabpanel {
        padding: 10px 0 10px;
      }

      &_form {
        margin-top: 5px;
        gap: 25px;

        &_label {
          font-size: 14px;
        }

        &_input {
          font-size: 14px;
        }

        &_date {
          font-size: 14px;

          & input {
            font-size: 14px;
          }
        }

        &_time {
          font-size: 14px;

          & input {
            font-size: 14px;
          }
        }

        &-copy-link {
          font-size: 13px;
          & .MuiGrid-root {
            padding-top: 0 !important;
          }
        }
      }
    }

    &__disc {

      &_title {
        font-size: 14px;
      }

      &_info {
        &-progress {
          height: 13px !important;
        }
      }
    }

    &__services {

      &_title {
        font-size: 20px;
      }

      &_list {
        margin-top: 20px;
        gap: 15px;
      }

      &_item {
        &-icon {
          width: 50px;
          height: 50px;
        }
      }
    }
  }
}


.layoutSM {
  .my-services {
    &__ai {

      &_tab {
        & .MuiButtonBase-root {
          font-size: 13px;
        }
      }

      &_list {
        height: 220px;
        margin-top: 10px !important;
      }

      &_item {
        &-time {
          padding: 10px 5px !important;
          width: 165px;
          P {
            font-size: 13px;
            line-height: 18px;
          }
        }

        &-log {
          width: calc(100% - 160px);
          P {
            font-size: 13px;
            line-height: 18px;
          }
        }
      }
    }

    &__tasks {
      &_tab {
        min-height: unset;
      }
      &_item {
        &.MuiListItem-root {
          padding: 15px 0 !important;
        }

        & .MuiTypography-body1 {
          font-size: 14px;
        }

        & .MuiTypography-body2 {
          font-size: 13px;
        }

        &-more {
          & .MuiSvgIcon-root {
            width: 20px;
            height: 20px;
          }
        }
      }
    }

    &__domains {

      &-page {
        &_header {
          justify-content: space-between;
          padding-left: 2 * $space;
          padding-right: 2 * $space;
        }
        &_header-col {
          display: flex;
          flex-wrap: nowrap;
          align-items: center;
        }
        &_row {
          display: flex;
          flex-wrap: nowrap;
          padding: $space;
          border-top: 1px solid $delimiter;
          &:first-of-type {
            border-top: none;
            padding-top: 0;
          }
        }
        &_col {
          width: 22%;
          &:last-of-type {
            width: 12%;
          }
        }
      }

      &_container {
        padding: 15px 20px;
      }

      &_header {
        font-size: 14px;
      }

      &_items {
        & .MuiListItemText-root {
          & .MuiListItemText-primary {
            font-size: 14px;
          }

          & .MuiListItemText-secondary {
            font-size: 13px;
          }
        }
      }
    }

    &__cloud-hosting {
      &_container {
        padding: 10px 20px;
      }

      &_storage {
        &-heading {
          font-size: 14px;
        }

        &_ring {
          width: 120px;
          height: 120px;
          &-title {
            font-size: 14px;
          }
        }
      }

      &_site {
        &-heading {
          font-size: 14px;
          margin-top: 5px;
        }


        &-list {
          & .MuiListItemText-primary {
            font-size: 14px;
          }

          & .MuiListItemText-secondary {
            font-size: 13px;

          }
        }
      }

    }

    &__work-drive {
      &_upload-text {
        h3 {
          font-size: 14px;
        }
      }
    }

    &__bank {

      &_container {
        padding: 5px 0 20px 5px;
      }

      &_list {
        & .MuiListItem-root {
          padding: 15px 0;
        }
      }

      &_item {
        & .MuiListItemIcon-root:first-child {
          min-width: 45px;
        }
        &-currency {
          width: 40px;
          height: 40px;
        }

        &-balance-info {
          min-width: 100px;
          margin-left: 3px;

          & .MuiListItemText-secondary {
            font-size: 14px;
          }
        }

        &-balance-card {
          margin-right: 0;
          .MuiListItemText-primary {
            font-size: 13px;
          }
        }

        &-more {
          & .MuiSvgIcon-root {
            width: 20px;
            height: 20px;
          }
        }
      }
    }

    &__sites-copying {

      &_container {
        padding: 15px 20px;
      }

      &_description {
        font-size: 14px;
      }

      &_form-wrapper {
        margin-top: 30px;
      }

      &_form {
        &_title {
          font-size: 14px;
        }
        &_input {
          font-size: 14px;
        }
      }
    }

    &__website-builder {
      &_container {
        padding: 10px 20px;
      }

      &_description {
        font-size: 14px;
      }

      &_form-wrapper {
        margin-top: 25px;
      }

      &_form {
        &_title {
          font-size: 14px;
        }

        &_input {
          font-size: 14px;
          margin-top: 15px !important;
        }

      }
    }

    &__video-conference {

      &_container {
        padding: 0 15px;
      }

      &_tab {
        min-height: unset;

        & .MuiButtonBase-root {
          font-size: 14px;
        }
      }

      &_tabpanel {
        padding: 10px 0 10px;
      }

      &_form {
        margin-top: 5px;
        gap: 25px;

        &_label {
          font-size: 14px;
        }

        &_input {
          font-size: 14px;
        }

        &_date {
          font-size: 14px;

          & input {
            font-size: 14px;
          }
        }

        &_time {
          font-size: 14px;

          & input {
            font-size: 14px;
          }
        }

        &-copy-link {
          font-size: 13px;
          & .MuiGrid-root {
            padding-top: 0 !important;
          }
        }
      }
    }

    &__disc {

      &_title {
        font-size: 14px;
      }

      &_info {
        &-progress {
          height: 13px !important;
        }
      }
    }

    &__services {

      &_title {
        font-size: 20px;
      }

      &_list {
        margin-top: 20px;
        gap: 15px;
      }

      &_item {
        &-icon {
          width: 50px;
          height: 50px;
        }
      }
    }
  }
}


.layoutXS {
  .my-services {
    &__ai {

      &_tab {
        & .MuiButtonBase-root {
          font-size: 13px;
        }
      }

      &_list {
        height: 220px;
        margin-top: 10px !important;
      }

      &_item {
        &-time {
          padding: 10px 0 !important;
          width: 95px;
          P {
            font-size: 13px;
            line-height: 18px;
          }
        }

        &-log {
          width: calc(100% - 80px);
          P {
            font-size: 13px;
            line-height: 18px;
          }
        }
      }
    }

    &__tasks {
      &_tab {
        min-height: unset;
      }
      &_item {
        &.MuiListItem-root {
          padding: 15px 0 !important;
        }

        & .MuiTypography-body1 {
          font-size: 14px;
        }

        & .MuiTypography-body2 {
          font-size: 13px;
        }

        &-more {
          & .MuiSvgIcon-root {
            width: 20px;
            height: 20px;
          }
        }
      }
    }

    &__domains {

      &-page {
        &_header {
          justify-content: space-between;
          padding-left: 2 * $space;
          padding-right: 2 * $space;
        }
        &_header-col {
          display: flex;
          flex-wrap: nowrap;
          align-items: center;
        }
        &_row {
          display: flex;
          flex-wrap: nowrap;
          padding: $space;
          border-top: 1px solid $delimiter;
          &:first-of-type {
            border-top: none;
            padding-top: 0;
          }
        }
        &_col {
          width: 22%;
          &:last-of-type {
            width: 12%;
          }
        }
      }

      &_container {
        padding: 15px 20px;
      }

      &_header {
        font-size: 14px;
      }

      &_items {
        & .MuiListItemText-root {
          & .MuiListItemText-primary {
            font-size: 14px;
          }

          & .MuiListItemText-secondary {
            font-size: 13px;
          }
        }
      }
    }

    &__cloud-hosting {
      &_container {
        padding: 10px 20px;
      }

      &_storage {
        &-heading {
          font-size: 14px;
        }

        &_ring {
          width: 120px;
          height: 120px;
          &-title {
            font-size: 14px;
          }
        }
      }

      &_site {
        &-heading {
          font-size: 14px;
          margin-top: 5px;
        }


        &-list {
          & .MuiListItemText-primary {
            font-size: 14px;
          }

          & .MuiListItemText-secondary {
            font-size: 13px;

          }
        }
      }

    }

    &__work-drive {
      &_upload-text {
        h3 {
          font-size: 14px;
        }
      }
    }

    &__bank {

      &_container {
        padding: 5px 0 20px 5px;
      }

      &_list {
        & .MuiListItem-root {
          padding: 15px 0;
        }
      }

      &_item {
        & .MuiListItemIcon-root:first-child {
          min-width: 45px;
        }
        &-currency {
          width: 40px;
          height: 40px;
        }

        &-balance-info {
          min-width: 100px;
          margin-left: 3px;

          & .MuiListItemText-secondary {
            font-size: 14px;
          }
        }

        &-balance-card {
          margin-right: 0;
          .MuiListItemText-primary {
            font-size: 13px;
          }
        }

        &-more {
          & .MuiSvgIcon-root {
            width: 20px;
            height: 20px;
          }
        }
      }
    }

    &__sites-copying {

      &_container {
        padding: 15px 20px;
      }

      &_description {
        font-size: 14px;
      }

      &_form-wrapper {
        margin-top: 30px;
      }

      &_form {
        &_title {
          font-size: 14px;
        }
        &_input {
          font-size: 14px;
        }
      }
    }

    &__website-builder {
      &_container {
        padding: 10px 20px;
      }

      &_description {
        font-size: 14px;
      }

      &_form-wrapper {
        margin-top: 25px;
      }

      &_form {
        &_title {
          font-size: 14px;
        }

        &_input {
          font-size: 14px;
          margin-top: 15px !important;
        }

      }
    }

    &__video-conference {

      &_container {
        padding: 0 15px;
      }

      &_tab {
        min-height: unset;

        & .MuiButtonBase-root {
          font-size: 14px;
        }
      }

      &_tabpanel {
        padding: 10px 0 10px;
      }

      &_form {
        margin-top: 5px;
        gap: 25px;

        &_label {
          font-size: 14px;
        }

        &_input {
          font-size: 14px;
        }

        &_date {
          font-size: 14px;

          & input {
            font-size: 14px;
          }
        }

        &_time {
          font-size: 14px;

          & input {
            font-size: 14px;
          }
        }

        &-copy-link {
          font-size: 13px;
          & .MuiGrid-root {
            padding-top: 0 !important;
          }
        }
      }
    }

    &__disc {

      &_title {
        font-size: 14px;
      }

      &_info {
        &-progress {
          height: 13px !important;
        }
      }
    }

    &__services {

      &_title {
        font-size: 20px;
      }

      &_list {
        margin-top: 20px;
        gap: 15px;
      }

      &_item {
        &-icon {
          width: 50px;
          height: 50px;
        }
      }
    }
  }
}


.dark .my-services {
  &__guide {
    width: 100%;
    height: 380px;

    background-image: url("../../../assets/images/my-services/guide/guide-back.jpg");
    background-size: cover;
    background-position: center;
    border-radius: $border-radius-big;

    &-container {
      padding: 50px 30px;
    }

    &_header {
      &-title {
        font-size: 32px;
        color: $gray-text;
        font-weight: normal;
      }
    }
  }

  &__domains {
    &-page {
      &_header {
        justify-content: space-between;
        padding-left: 2 * $space;
        padding-right: 2 * $space;
      }
      &_header-col {
        display: flex;
        flex-wrap: nowrap;
        align-items: center;
      }
      &_row {
        display: flex;
        flex-wrap: nowrap;
        padding: $space;
        border-top: 1px solid $delimiter;
        &:first-of-type {
          border-top: none;
          padding-top: 0;
        }
      }
      &_col {
        width: 22%;
        &:last-of-type {
          width: 12%;
        }
      }
    }

    &_header {
      color: $dark-theme-title-color;
    }
    &_items {
      & .MuiListItemText-primary {
        color: $dark-theme-title-color;
      }

      & .MuiListItemText-secondary {
        color: $dark-theme-subtitle-color;
      }
    }
  }

  &__cloud-hosting {
    &_storage {
      &-heading {
        color: $dark-theme-title-color;
      }

      &_ring {
        &-title {
          color: $dark-theme-title-color;
        }
        &-subtitle {
          color: $dark-theme-subtitle-color;
        }
      }
    }

    &_site {
      &-heading {
        color: $dark-theme-title-color;
      }
      &-list {
        & .MuiListItemText-primary {
          color: $dark-theme-title-color;
        }
        & .MuiListItemText-secondary {
          color: $dark-theme-subtitle-color;
        }
      }
    }
  }

  &__tasks {
    &_tab {
      & .MuiButtonBase-root {
        color: $dark-theme-title-color;
      }
    }
    &_item {
      border-color: #7c7c7c;

      & .MuiTypography-body1 {
        color: $dark-theme-title-color;
      }

      & .MuiTypography-body2 {
        color: $dark-theme-subtitle-color;
      }

      &-more {
        & .MuiButtonBase-root {
          color: $dark-theme-subtitle-color;
        }
      }
    }
  }

  &__ai {
    &_tab {
      & .MuiButtonBase-root {
        color: $dark-theme-title-color;
      }
    }

    &_list {
      background-color: $dark-theme-darker-background-color;
      border-color: #ffffff;
    }

    &_item {
      border-color: #7c7c7c;
      color: $dark-theme-title-color;

      &-time {
        border-color: #7c7c7c;
      }
    }
  }

  &__work-drive {
    &_upload-text {
      color: $dark-theme-title-color;
    }
  }

  &__bank {
    &_item {
      border-color: #7c7c7c;
      &-balance-info {
        & .MuiListItemText-primary {
          color: $dark-theme-title-color;
        }
        & .MuiListItemText-secondary {
          color: $dark-theme-subtitle-color;
        }
      }

      &-balance-card {
        color: $dark-theme-title-color;
      }

      &-more {
        & .MuiButtonBase-root {
          color: $dark-theme-subtitle-color;
        }
      }
    }
  }

  &__logo-builder {
    &_description {
      color: $dark-theme-title-color;
    }

    &_form {
      &_title {
        color: $dark-theme-title-color;
      }
      &_input {
        background-color: $dark-theme-darker-background-color;

        & input {
          color: $dark-theme-title-color;
        }

        & label {
          color: $dark-theme-title-color;
        }
        & .MuiOutlinedInput-root {
          & fieldset {
            border-color: $dark-theme-title-color;
          }
        }
      }

      &_btn {
        margin-top: 20px;
        text-align: right;
      }
    }
  }

  &__sites-copying {
    &_description {
      color: $dark-theme-title-color;
    }

    &_form {
      &_title {
        color: $dark-theme-title-color;
      }
      &_input {
        background-color: $dark-theme-darker-background-color;

        & input {
          color: $dark-theme-title-color;
        }

        & label {
          color: $dark-theme-title-color;
        }
        & .MuiOutlinedInput-root {
          & fieldset {
            border-color: $dark-theme-title-color;
          }
        }
      }

      &_btn {
        margin-top: 20px;
        text-align: right;
      }
    }
  }

  &__website-builder {
    &_description {
      color: $dark-theme-title-color;
    }

    &_form {
      &_title {
        color: $dark-theme-title-color;
      }
      &_input {
        background-color: $dark-theme-darker-background-color;

        & textarea {
          color: $dark-theme-title-color;
        }

        & label {
          color: $dark-theme-title-color;
        }
        & .MuiOutlinedInput-root {
          & fieldset {
            border-color: $dark-theme-title-color;
          }
        }
      }

      &_btn {
        margin-top: 20px;
        text-align: right;
      }
    }
  }

  &__disc {
    &_title {
      color: $dark-theme-title-color;
    }

    &_info {
      &-text {
        color: $dark-theme-subtitle-color;
      }
    }
  }

  &__video-conference {
    &_tab {
      & .MuiButtonBase-root {
        color: $dark-theme-title-color;
      }
    }

    &_form {
      &_label {
        color: $dark-theme-title-color;
      }

      &_input {
        background-color: unset;
        & input {
          color: $dark-theme-title-color;
          background-color: $dark-theme-darker-background-color;
          border-radius: 8px;
        }

        & .MuiOutlinedInput-root {
          & fieldset {
            border-color: #ececec;
          }
        }
      }

      &_date {
        border-radius: 8px;
        background-color: $dark-theme-darker-background-color;
        & label {
          color: $dark-theme-title-color;
        }
        & .MuiOutlinedInput-root {
          & fieldset {
            border-color: #ececec;
          }
        }
        & input {
          color: $dark-theme-title-color;
        }
      }

      &_time {
        border-radius: 8px;
        background-color: $dark-theme-darker-background-color;
        & label {
          color: $dark-theme-title-color;
        }
        & .MuiOutlinedInput-root {
          & fieldset {
            border-color: #ececec;
          }
        }
        & input {
          color: $dark-theme-title-color;
        }
      }
    }
  }

  &__services {
    background: rgba(92, 92, 92, 0.6901960784);

    &_title {
      font-size: 22px;
      color: $dark-theme-title-color;
      font-weight: 400;
    }
  }
}
