.schedule {
    padding: 10px;

    &-actions {
        button.MuiButtonBase-root {
            border: unset;
            color: #333;
            font-size: 15px;
            font-weight: 500 !important;
            text-transform: capitalize;
            padding-left: 0;

            &:hover {
                background-color: transparent;
            }
        }
    }

    &-content {

        &_icon {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            gap: 10px;

            img {
                width: 54px;
                height: 54px;
            }

            p {
                font-size: 15px;
                font-weight: 500;
                color: #333;
            }
        }

        &_form {
            padding: 20px 3vw;
            display: flex;
            flex-direction: column;
            gap: 20px;

            .MuiFormControl-root  {
                width: 100%;
                background-color: #F8FAFC;
                border-radius: 8px;
                height: 40px;
                box-sizing: border-box;

            }

            &-item {

                &-title {
                    display: flex;
                    align-items: center;
                    gap: 5px;
                    margin-bottom: 5px;
                    color: #333;
                    font-family: 'OpenSans' !important;
                    font-size: 15px;

                    &-info {
                        color: #888888;
                        display: block;
                        margin-bottom: 5px;
                        font-size: 13px;
                    }
                }

                &-input {
                    input {
                        border-radius: 8px;
                        background-color: #F8FAFC;
                        padding: 10px;
                        height: 40px;
                        box-sizing: border-box;
                        font-family: 'OpenSans' !important;
                        font-size: 15px;
                    }

                    .MuiOutlinedInput-notchedOutline {
                        border-radius: 8px;
                        border-color: #ECECEC;
                    }

                    .MuiInputBase-root:hover .MuiOutlinedInput-notchedOutline {
                        border-color: #1976d2;
                    }
                }

                &-date {
                    &-title {
                        display: flex;
                        align-items: center;
                        gap: 5px;
                        margin-bottom: 5px;
                        color: #333;
                        font-family: 'OpenSans' !important;
                        font-size: 15px;

                        img {
                            width: 20px;
                            height: 20px;
                        }
                    }

                    &-form {
                        margin-top: 5px;

                        &-field {
                            .MuiInputBase-input {
                                padding: 10px;
                                background-color: #F8FAFC;
                                border-radius: 8px;
                                height: 40px;
                                box-sizing: border-box;
                                font-family: 'OpenSans' !important;
                                border: 1px solid #ECECEC;
                            }

                            .MuiOutlinedInput-notchedOutline {
                                border-radius: 8px;
                                height: 40px;
                                box-sizing: border-box;
                                border: unset;
                            }

                            .MuiInputBase-root:hover .MuiOutlinedInput-notchedOutline {
                                border-color: #1976d2;
                            }

                            button.MuiButtonBase-root {
                                border: unset;
                                padding: 10px 10px 10px 0;
                                background-color: transparent;
                                text-align: center;
                                color: #4382C4;
                                font-size: 13px;
                                border-radius: 8px;
                                text-transform: none;
                            }

                            &:nth-child(1), &:nth-child(2) {
                                padding-right: 10px;
                            }
                        }
                    }
                }

                &-timezone {
                    &-title {
                        display: flex;
                        align-items: center;
                        gap: 5px;
                        margin-bottom: 5px;
                        color: #333;
                        font-family: 'OpenSans' !important;
                        font-size: 15px;

                        img {
                            width: 20px;
                            height: 20px;
                        }
                    }

                    &-select {
                        & > div > div {
                            border-radius: 8px;
                            background-color: #F8FAFC;
                            line-height: 22px;
                            border: 1px solid #ECECEC;
                            // height: 40px;
                            box-sizing: border-box;

                            & > div > div {
                                // height: 32px;
                                // line-height: 32px;
                            }

                            & div {
                                font-family: 'OpenSans' !important;
                                padding-bottom: 1px;
                            }
                        }
                    }
                    [class^="-control"] {
                        border-radius: 8px;
                    }

                    [class^="-singleValue"] {
                        font-family: 'OpenSans' !important;
                    }
                }

                &-switch {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;

                    &-label {
                        display: flex;
                        align-items: center;
                        gap: 8px;
                        cursor: pointer;
                        color: #333333;

                        img {
                            width: 20px;
                        }
                    }
                }

                &-checkbox {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;

                    &-label {
                        display: flex;
                        align-items: center;
                        gap: 8px;
                        cursor: pointer;

                        img {
                            width: 20px;
                        }
                    }

                    .MuiFormControlLabel-root {
                        margin: 0;
                        color: #333333;

                        .MuiTypography-root  {
                            color: #333333;
                            padding-left: 5px;
                            font-family: "Open Sans", sans-serif;
                        }

                        .MuiSvgIcon-root {
                            color: #333;
                            font-size: 18px;
                            stroke: rgba(223, 233, 248, 0.37);
                        }

                        .Mui-checked .MuiSvgIcon-root {
                            color: #51A3FF;
                        }
                    }

                    .MuiButtonBase-root {
                        padding: 0;
                    }
                }

                &-submit {
                    text-align: right;

                    &-btn.MuiButtonBase-root {
                        padding: 10px 24px;
                        background-color: #51A3FF;
                        color: #ffffff;
                        border-radius: 30px;
                        font-size: 15px;
                        text-transform: none;
                        line-height: unset;

                        &:hover {
                            background-color: #51A3FF;
                        }
                    }
                }
            }

        }
    }
}
