@import '../../../styles/sass/variables.scss';

.calendar-page {
    width: 100%;
    // overflow: auto;
    box-sizing: border-box;
    // height: 75vh;
    height: 100%;

    .fc-daygrid-event {
        border-radius: 5px;
    }

    .grid-content_right {
        // max-height: calc(100% - 30px);
    }

    & .MuiGrid-container {
        margin: 0;
    }

    &_sidebar {
        padding: 0 5px 50px;
        display: flex;
        flex-direction: column;
        gap: 20px;

        &_create-event {

            &_btn.MuiButtonBase-root {
              font-size: 16px;
              background-color: #4382C4;
              width: 100%;
              padding: 10px;
              text-align: center;
              color: #fff;
              border-radius: 8px;
              text-transform: none;
              height: 44px;
              box-sizing: border-box;
            }

            &_btn.MuiButtonBase-root:hover {
              background-color: #4382C4;
            }
        }

        &-section {

            .react-calendar {
                margin-bottom: 18px;
            }

            &_filter {
                margin-top: 10px;

                & .MuiFormControlLabel-root {
                    margin: 0;
                    height: 40px;
                    box-sizing: border-box;

                    &:hover {
                        .MuiButtonBase-root {
                            display: flex;
                        }
                    }
                }


                & .MuiFormControlLabel-root:not(:last-child) {
                    border-bottom: 1px solid #D2D2D2;
                }

                & .MuiTypography-root {
                    width: 100%;
                    height: 40px;
                    color: #333333;
                    font-size: 13px;
                    box-sizing: border-box;
                    line-height: 40px;
                }

                &-action {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;

                    .MuiButtonBase-root {
                        display: none;
                    }
                }
            }

            &_add-btn.MuiButtonBase-root {
                text-transform: none;
                font-size: 13px;
                margin-top: 10px;
            }

            &_add-calendar {
                width: 100%;
                height: 40px;
                color: #666666;
                margin-left: 0;
                // background-color: rgba(255, 255, 255, 0.5019607843);
                border-radius: 8px;
                padding: 0 !important;
                box-sizing: border-box;


                .MuiInputBase-input {
                    height: 40px;
                    box-sizing: border-box;
                    padding: 0 10px;
                }

            }
        }
    }

    &_main {
        padding: 0 5px 0 15px;
        height: 100%;

        &_calendar {
            height: 100%;

            .fc {
                .fc-col-header,
                .fc-daygrid-body,
                .fc-scrollgrid-sync-table,
                .fc-scrollgrid-section,
                .fc-timegrid-body,
                .fc-timegrid-body table {
                  inline-size: 100% !important;
                }

                .fc-daygrid-body, .fc-scrollgrid-sync-table {
                    height: 100% !important;
                }
            }

            .fc-theme-standard table tbody .fc-scrollgrid-section > td {
                border-radius: 0 0 8px 0;
            }

            .fc-theme-standard table thead > tr > th {
                border-radius: 8px 8px 0 0;
            }

            & .fc-timeGridDay-view.fc-view.fc-timegrid,
            & .fc-timeGridWeek-view.fc-view.fc-timegrid {
                padding-bottom: 20px;
            }

            & .fc-media-screen {
                height: 100%;

                & .fc-header-toolbar {
                    display: none;
                    margin-bottom: 8px;

                    & .fc-toolbar-chunk {
                        display: flex;
                        gap: 10px;

                        .fc-prev-button,
                        .fc-next-button,
                        .fc-prev-button:not(:disabled):active,
                        .fc-next-button:not(:disabled):active {
                            background-color: transparent;
                            border: unset;
                            color: #333333;
                            font-size: 14px;
                        }

                        .fc-prev-button:focus,
                        .fc-next-button:focus                  ,
                        .fc-today-button:focus,
                        .fc-prev-button:not(:disabled):active:focus
                        .fc-next-button:not(:disabled):active:focus,
                        .fc-today-button:not(:disabled):active:focus {
                            box-shadow: unset;
                        }

                        .fc-toolbar-title {
                            font-size: 15px;
                            color: #414141;
                            display: flex;
                            align-items: center;
                            padding: 0 20px;
                        }

                        .fc-today-button {
                            font-size: 14px;
                            background-color: rgba(223, 233, 248, 0.65);
                            border: unset;
                            color: #6B686A;
                            border-radius: 8px;
                            padding: 10px 15px;
                            line-height: unset;
                            cursor: pointer;
                        }


                        .fc-button-group {
                            gap: 10px;
                            background-color: rgba(223, 233, 248, 0.65);
                            border-radius: 8px;

                            .fc-button:not(:disabled).fc-bu   tton-active {
                                font-size: 14px;
                                background-color: #7676801f;
                                color: #6B686A;
                                border-radius: 8px;
                                padding: 10px 15px;
                                border: unset;
                                line-height: unset;
                            }

                            .fc-button:not(.fc-button-active) {
                                font-size: 14px;
                                color: #6B686A;
                                background-color: unset;
                                border-radius: 8px;
                                padding: 10px 15px;
                                border: unset;
                                line-height: unset;
                            }

                            .fc-button:not(:disabled):focus {
                                box-shadow: unset;
                            }

                            .fc-button-active {
                                background-color: hsla(240,4%,48%,.122);
                                border: initial;
                                border-radius: 8px;
                                color: #6b686a;
                                font-size: 14px;
                                line-height: inherit;
                                padding: 10px 15px;
                            }
                        }

                    }
                }


                & .fc-view-harness {
                    background-color: white;
                    border-radius: 8px;
                    max-height: 100%;
                    height: auto;

                    & .fc-scrollgrid {
                        border: unset;

                        & .fc-scroller {

                            & .fc-timegrid-axis {
                                border-right: unset;
                            }

                            & .fc-col-header-cell {
                                border-left: unset;
                                border-right: unset;

                                &.fc-day-today {
                                    .fc-col-header-cell-cushion {
                                        span:last-child {
                                            // padding: 2px 5px;
                                            width: 20px;
                                            height: 20px;
                                            display: inline-block;
                                            border-radius: 16px;
                                            border: 1px solid #4382C4;
                                        }
                                    }

                                    .weekdate-box {
                                        width: 23px;
                                        // height: 20px;
                                        display: inline-block;
                                        border-radius: 50%;
                                        border: 1px solid #4382C4;
                                    }
                                }

                                & .fc-scrollgrid-sync-inner {
                                    padding: 5px 0;

                                    .fc-col-header-cell-cushion {
                                        color: #333333;
                                        font-size: 13px;
                                        font-weight: 500;
                                        line-height: 19px;

                                        span{
                                            color: #333333;
                                            font-size: 13px;
                                            line-height: 19px;

                                            &:first-child {
                                                font-weight: 400;
                                            }

                                            &:last-child {
                                                font-weight: 600;
                                            }
                                        }
                                    }
                                }
                            }
                        }

                        & .fc-timegrid-body {
                            & .fc-timegrid-event:hover {
                                box-shadow: 2px 2px 5px rgba(0,0,0,0.3), 1px 1px 2px rgba(0,0,0,0.2);
                                opacity: 1 !important;
                            }
                        }

                        & .fc-daygrid-body {
                            & .fc-day {
                                padding: 5px;

                                .fc-daygrid-day-frame .fc-daygrid-day-top {
                                    flex-direction: row;

                                    .fc-daygrid-day-number {
                                        color: #333333;
                                        font-size: 13px;
                                        width: 25px;
                                        height: 25px;
                                        text-align: center;
                                        padding: 5px 4px;
                                    }
                                }


                                & .fc-daygrid-day-number {
                                    padding: 6px 6px;
                                }

                                & .fc-daygrid-block-event:hover {
                                    box-shadow: 2px 2px 5px rgba(0,0,0,0.3), 1px 1px 2px rgba(0,0,0,0.2);
                                    opacity: 1 !important;
                                }

                                & .fc-daygrid-day-events {
                                    & .fc-event {
                                        font-size: 14px;
                                        padding: 4px 8px;
                                        text-align: left;

                                        .event-content {
                                            text-align: left;
                                            width: 100%;
                                            height: 100%;
                                            padding: 10px 5px;
                                            white-space: nowrap;
                                            overflow: hidden;
                                            text-overflow: ellipsis;
                                            display: block;
                                            border-radius: 5px;
                                            border: 1px solid;
                                            height: 26px;
                                            box-sizing: border-box;
                                            display: flex;
                                            align-items: center;
                                        }

                                        b {
                                            margin-right: 7px;
                                        }

                                        i {
                                            white-space: nowrap;
                                            overflow: hidden;
                                            text-overflow: ellipsis;
                                        }

                                        & .fc-event-main {
                                            white-space: nowrap;
                                            overflow: hidden;
                                            text-overflow: ellipsis;
                                        }
                                    }
                                }
                            }

                            & .fc-day-today {
                                background-color: unset;

                                & .fc-daygrid-day-number {
                                    border: 1px solid $blue;
                                    border-radius: 50%;
                                }
                            }

                            & .fc-scrollgrid-sync-table {
                                tbody {
                                    tr:last-child {
                                        td:last-child.fc-day {
                                            // border-radius: 8px !important;
                                        }
                                    }
                                }
                            }
                        }
                    }


                    & .fc-timegrid-cols {
                        & .fc-timegrid-axis {
                            & .fc-timegrid-now-indicator-arrow {
                                border-right-color: #653EB9;
                                border-left-color: #653EB9;
                                border-width: 7px 0 7px 7px;
                            }
                        }

                        & .fc-day{
                            & .fc-event {

                                padding: 5px 0;
                                background-color: #AB9BCD;
                                // border-color: #AB9BCD;

                                .fc-event-main {
                                    font-size: 13px;
                                    padding: 9px 5px;
                                    text-align: left;
                                    color: #fff;
                                    white-space: nowrap;
                                    overflow: hidden;
                                    text-overflow: ellipsis;
                                    display: block;

                                    b {
                                        margin-right: 10px;
                                    }
                                }
                            }

                            & .fc-timegrid-now-indicator-line {
                                border-color: #653EB9;
                                border-width: 3px 0 0;

                                &::before {
                                    content: "";
                                    width: 13px;
                                    height: 13px;
                                    background-color: #653EB9;
                                    display: block;
                                    border-radius: 50%;
                                    top: -7px;
                                    position: absolute;
                                }
                            }
                        }

                        & .fc-day-today {
                            background-color: unset;
                        }
                    }

                    & thead{
                        & .fc-scrollgrid-section {

                            & .fc-scroller-harness {
                                & .fc-scroller {
                                    overflow: unset !important;

                                    colgroup {
                                        col {
                                            width: 70px !important;
                                        }
                                    }
                                }
                            }

                        }
                    }
                    & tbody tr:first-child {
                            & .fc-scroller-harness {
                                & .fc-scroller {
                                    // overflow: auto !important;

                                    .fc-timegrid-slots {
                                        table tbody {

                                            tr {
                                                display: grid;
                                                grid-template-columns: 70px calc(100% - 70px);

                                                td:first-child {
                                                    border: unset;
                                                    width: 70px;
                                                }

                                                .fc-timegrid-slot.fc-timegrid-slot-lane {
                                                    border-left: unset;
                                                    border-right: none;
                                                }
                                            }

                                            tr:nth-child(odd) {
                                                td:first-child {
                                                    .fc-timegrid-slot-label-frame {
                                                        position: relative;
                                                        text-align: center;

                                                        .fc-timegrid-slot-label-cushion {
                                                            font-size: 13px;
                                                            color: #999;
                                                            position: relative;
                                                        }
                                                    }
                                                }
                                            }

                                            tr:nth-child(even) {
                                                td {
                                                    border: unset;
                                                }
                                            }
                                        }
                                    }

                                    .fc-timegrid-cols {
                                        colgroup {
                                            col {
                                                width: 70px !important;
                                            }
                                        }
                                    }
                                }
                            }
                    }

                    & .fc-listWeek-view {
                        .fc-event {
                            .fc-list-event-graphic {
                                .fc-list-event-dot {
                                    border-color: #AB9BCD;
                                }
                            }
                        }
                    }

                    & .fc-listMonth-view {
                        overflow-y: scroll;
                        padding: 15px 15px;
                        border: unset;

                        tbody {
                            display: flex;
                            flex-wrap: wrap;

                            .fc-list-day {
                                width: 120px;

                                th {
                                    border: unset;

                                    .fc-list-day-cushion  {
                                        background-color: unset;
                                        padding: 5px;

                                        div:first-child {
                                            span {
                                                color: #333;
                                                font-size: 13px;
                                                font-weight: 500;
                                            }
                                        }

                                        div:last-child {
                                            margin-top: 5px;
                                            span {
                                                color: #B8B8B8;
                                                font-size: 13px;
                                                font-weight: 400;
                                            }
                                        }
                                    }
                                }
                            }

                            .fc-event {
                                width: 100%;
                                margin-left: 120px;
                                position: relative;
                                bottom: 45px;
                                box-sizing: border-box;

                                td {
                                    border: unset;

                                    &:first-child {
                                        border-radius: 8px 0 0 8px;
                                    }


                                    &:last-child {
                                        border-radius: 0 8px 8px 0;
                                    }
                                }

                                .fc-list-event-time {
                                    color: #414141;
                                    font-size: 13px;
                                    font-weight: 400;
                                    padding: 10px 10px 10px 10px;
                                }

                                .fc-list-event-graphic {
                                    padding: 8px 10px;
                                    .fc-list-event-dot {
                                        border-color: #AB9BCD;
                                    }
                                }

                                .fc-list-event-title {
                                    color: #414141;
                                    font-size: 13px;
                                    font-weight: 400;
                                    padding: 10px 10px 10px 10px;
                                }
                            }
                        }
                    }
                }
            }


        }
    }

    // .fc-timeGridDay-view .fc-timegrid-body .fc-timegrid-event-harness-inset {
    //     inset-inline-end: -2.4% !important;
    // }

    // .fc-timeGridWeek-view .fc-timegrid-body .fc-timegrid-event-harness-inset {
    //     inset-inline-end: -2% !important;
    // }

    .fc-timeGridDay-view {
        .fc-timegrid-event-harness.fc-timegrid-event-harness-inset {
            margin-right: 3px;
        }

        .fc-timegrid-event-harness.fc-timegrid-event-harness-inset:last-of-type {
            inset-inline-end: -2.65% !important;
        }
    }

    .fc-timeGridWeek-view {
        .fc-timegrid-event-harness.fc-timegrid-event-harness-inset {
            margin-right: 3px;
        }

        .fc-timegrid-event-harness.fc-timegrid-event-harness-inset:last-of-type {
            inset-inline-end: -4% !important;
        }
    }

    .agenda-event-content:hover {
        background-color: #f4f5f9;
    }

    .fc-timegrid-body .fc-event-draggable:hover::before {
        content: '.';
        position: absolute;
        left: 45%;
        bottom: 5%;
        font-size: 18px;
        line-height: 20px;
        color: white;
        text-shadow: 0 3px white, 3px 0 white, 3px 3px white, 9px 0px white, 6px 0 white, 6px 3px white, 9px 3px white;
    }

    .fc-event.fc-event-draggable.fc-event-start.fc-event-end.fc-event-future.fc-daygrid-event.fc-daygrid-block-event.fc-h-event {
        opacity: 1 !important;
    }

    .fc-event.fc-event-draggable.fc-event-start.fc-event-end.fc-event-future.fc-daygrid-event.fc-daygrid-block-event.fc-h-event:hover {
        box-shadow: 2px 2px 5px rgba(0,0,0,0.3), 1px 1px 2px rgba(0,0,0,0.2) !important;
        font-size: 14px !important;
        text-align: left !important;
    }

    .fc-event.fc-event-draggable.fc-event-start.fc-event-end.fc-daygrid-event.fc-daygrid-block-event.fc-h-event:hover {
        opacity: 1 !important;
        box-shadow: 2px 2px 5px rgba(0,0,0,0.3), 1px 1px 2px rgba(0,0,0,0.2) !important;
        font-size: 14px !important;
    }

    .fc-event.fc-event-mirror.fc-event-start.fc-event-end.fc-event-future.fc-timegrid-event.fc-v-event,
    .fc-event.fc-event-mirror.fc-event-start.fc-event-end.fc-event-past.fc-timegrid-event.fc-v-event {
        opacity: 1 !important;
        box-shadow: 2px 2px 5px rgba(0,0,0,0.3), 1px 1px 2px rgba(0,0,0,0.2) !important;
        font-size: 14px !important;
    }

    .fc .fc-scrollgrid-section > * {
        border-left-width: 0px;
        border-top-width: 0px;
        border-right-width: 0px;
        border-bottom-width: 0px;
    }


    .fc-popover.fc-more-popover.fc-day {
        border-radius: 8px;

        .fc-popover-header {
            background: transparent;
            padding: 8px 8px 0px 8px;
        }

        .fc-daygrid-event.fc-event-end {
            margin-bottom: 2px;
        }

        .fc-event-main {
            padding: 4px;
        }

        .fc-popover-title {
            font-size: 14px;
            font-weight: 500;
        }

        .fc-event.fc-event-draggable.fc-event-start.fc-event-end.fc-daygrid-event.fc-daygrid-block-event.fc-h-event:hover {
            font-size: var(--fc-small-font-size) !important;
        }
    }
}

.calendar-page_sidebar_create-event_drawer.with-full-page {
    position: unset !important;

    .MuiDrawer-paper {
        height: 95% !important;
        margin-top: 50px !important;
    }
}

.calendar-page_sidebar_create-event_drawer {
    top: 90px !important;
    bottom: 45px !important;
    align-items: start;
    display: flex;
    justify-content: center;
    padding-top: 45px;
    margin: 0 auto;
    max-width: 1600px;

    @media (max-width:1600px) {
        right: 100px !important;
    }

    .MuiDrawer-paper {
        height: 100%;
        outline: none;
        box-sizing: border-box;
        margin: 0 auto;
        position: absolute;
        border-radius: 8px;
    }

    .MuiBackdrop-root {
        top: 90px !important;
        bottom: 45px !important;
        align-items: start;
        display: flex;
        justify-content: center;
        padding-top: 45px;
        margin: 0 auto;
        max-width: 1600px;
        left: 100px !important;
        right: 100px;
        border-radius: 16px;
        background-color: transparent;
    }
}

.calendar-page_sidebar-section_filter-action-popup {
    .MuiList-root {
        .MuiButtonBase-root {
            .circle-picker {
                background: transparent !important;
                border: unset !important;
                box-shadow: unset !important;


                & > div:nth-child(1) {
                    display: none;
                }

                & > div:nth-child(3) {
                    padding: 10px !important;
                }
            }

            &:last-child {
                border-top: 1px solid #ececec;
            }
        }
    }
}

.fc-daygrid-dot-event .fc-event-title {
    text-overflow: ellipsis;
}

.scrollable-section:hover {
    &::-webkit-scrollbar {
        width: 1px;
        cursor: pointer;
    }

    &::-webkit-scrollbar-thumb {
        background-color: #D2D2D2;
        outline: 1px solid #D2D2D2;
    }
}

.my-services__calendar  {
    .fc-timegrid-event-harness.fc-timegrid-event-harness-inset {
        margin-right: 3px;
    }

    .fc-timegrid-event-harness.fc-timegrid-event-harness-inset:last-of-type {
        inset-inline-end: -3% !important;
    }

    .fc-media-screen .fc-view-harness tbody tr:first-child .fc-scroller-harness .fc-scroller .fc-timegrid-slots table tbody tr .fc-timegrid-slot.fc-timegrid-slot-lane {
        border-left: unset;
        border-right: none;
    }

    .fc-media-screen .fc-view-harness tbody tr:first-child .fc-scroller-harness .fc-scroller .fc-timegrid-slots table tbody tr td:first-child {
        border: unset;
    }
}

.scrollable-section {
    height: 80px !important;
}

.calendar-page_sidebar-section .react-calendar {
    margin-bottom: 0 !important;
}

@media only screen and (min-width: 1800px) {
    /* styles for browsers larger than 960px; */
    .scrollable-section {
        height: 20vh !important;
    }
}

.fc .fc-highlight {
    background: none;
}

.fc-v-event {
    border: none;
    background-color: none;
}
