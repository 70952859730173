.video-conferencing_rightside {
    width: 100%;
    height: 100%;
    padding: 10px;
    box-sizing: border-box;

    &-tabs {

        .MuiTabs-scroller {
            justify-content: center;
            display: flex;
        }
        .MuiButtonBase-root {
            color: #6B686A;
            // background: rgba(248, 250, 252, 0.50);
            border-radius: 8px;
            padding: 8px 22px;
            font-size: 13px;
            text-transform: capitalize;
            height: 36px;
            box-sizing: border-box;
            min-height: unset;
            line-height: 36px;
        }
        .MuiButtonBase-root.Mui-selected {
            color: #6B686A;
            background-color: rgba(118, 118, 128, 0.12);
        }

        .MuiTabs-indicator {
            display: none;
        }

        .MuiTabs-flexContainer {
            background-color: rgba(223, 233, 248, 0.65);
            border-radius: 8px;
            height: 36px;
            box-sizing: border-box;
        }
    }

    &-tabpanels {
        .meetings-tabpanels {
            & > div {
                display: flex;
                flex-direction: column;
                gap: 40px;
                padding: 20px 10px;
            }
        }

        .meeting {
            display: flex;
            flex-direction: column;
            gap: 20px;

            &_time {
                color: #653EB9;
                font-size: 15px;
                text-align: left;
                font-weight: 500;
            }

            &_card {
                display: flex;
                justify-content: space-between;
                align-items: center;
                gap: 10px;

                border: 1px solid #ECECEC;
                background-color: rgba(248, 250, 252, 0.7);
                box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
                border-radius: 8px;
                padding: 12px 18px;
                height: 90px;
                box-sizing: border-box;

                &:hover {
                    border-width: 2px;
                    border-color: #51A3FF;

                    .meet-now-button {
                        display: block;
                    }
                }

                .meet-now-button {
                    display: none;
                }

                &-icon {
                    width: 45px;
                    height: 45px;

                    .MuiAvatar-root {
                        width: inherit;
                        height: inherit;
                        box-sizing: border-box;
                        padding: 5px;
                        font-size: 15px;
                        background-color: #DCD6E8;
                        color: #653EB9;
                    }
                }

                &-details {
                    display: flex;
                    flex-direction: column;
                    gap: 6px;
                    overflow: hidden;
                    width: 100%;
                    height: 100%;
                    justify-content: space-around;

                    &-name {
                        font-size: 15px;
                        color: #333;
                        font-weight: 500;
                    }

                    &-date {
                        font-size: 13px;
                    }

                    &-description {
                        font-size: 13px;
                        color: #333;
                        white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;
                    }
                }
            }

            &_actions {
                width: 50px;
                text-align: right;
                height: 100%;
                display: flex;
                align-items: center;

                .MuiSvgIcon-root {
                    font-size: 20px;
                }
            }
        }
    }
}
