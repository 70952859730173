.clients-filterpopup {
    width: 320px;
    padding: 20px;
    box-sizing: border-box;

    &-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 10px 0;

        &-title {
            display: flex;
            align-items: center;
            gap: 5px;
            font-size: 20px;
            color: #51A3FF;

            p {
                font-family: 'OpenSans' !important;
            }
        }
        
        &-btn {
            .MuiButtonBase-root {
                color: #51A3FF;
            }
        }
    }

    &-list {
        padding: 10px 0;
        display: flex;
        flex-direction: column;
        gap: 20px;
    }

    &-item {
        &-title {
            display: flex;
            align-items: center;
            gap: 5px;
            margin-bottom: 5px;
            color: #444;
            font-size: 13px;
            line-height: 22px;
            font-family: 'OpenSans' !important;
        }

         

        & .MuiInputBase-root {
            width: 100%;
            border-radius: 8px;
            background-color: #F8FAFC;

            input {
                height: 35px;
                box-sizing: border-box;
                padding: 10px;
                color: #444;
                font-size: 13px;
                font-family: 'OpenSans' !important;

                &::placeholder {
                    color: #444;
                    font-size: 13px;
                    opacity: 1;
                }
            }

            .MuiOutlinedInput-notchedOutline {
                border-radius: 8px;
                border-color: #ECECEC;
            }

            &:hover .MuiOutlinedInput-notchedOutline {
                border-color: #1976d2;
            }
        }

        &-select {
            width: 100%;

            .MuiSelect-select {
                height: 35px !important;
                box-sizing: border-box;
                padding: 10px;
                background-color: #F8FAFC;
                font-size: 13px;
                color: #444;
                font-family: 'OpenSans' !important;
                line-height: 16px;
                border-radius: 8px;
            }

            .MuiOutlinedInput-notchedOutline  {
                border-radius: 8px;
            }

            .MuiSvgIcon-root {
                color: #ACACAC;
            }

            &.MuiInputBase-root:hover .MuiOutlinedInput-notchedOutline {
                border-color: #1976d2;
            }
        }


        &-double {
            display: flex;
            gap: 10px;

            .MuiFormControl-root {
                width: 100%;
            }

            .MuiInputBase-adornedStart {
                padding-left: 10px;

                input {
                    box-sizing: border-box;
                    border-left: 1px solid #ececec;
                }

                .MuiTypography-body1 {
                    font-size: 13px;
                    color: #ACACAC;
                }
            }


        }
    }

    &-btn {
        display: flex;
        justify-content: end;
        gap: 10px;

        &-submit.MuiButtonBase-root {
            text-transform: none;
            color: #fff;
            background-color: #51A3FF;
            padding: 10px 24px;
            border-radius: 30px;
            font-size: 14px;
            margin-top: 20px;
            font-family: 'OpenSans' !important;
            height: 40px;
            box-sizing: border-box;

            &:hover {
                text-transform: none;
                color: #fff;
                background-color: #51A3FF;
                padding: 10px 24px;
                border-radius: 30px;
            }
        }

        
        &-reset.MuiButtonBase-root {
            text-transform: none;
            color: #888888;    
            background-color: #fff;
            padding: 10px 24px;
            border-radius: 30px;
            border: 1px solid #cdcdcd;
            font-size: 14px;
            margin-top: 20px;
            font-family: 'OpenSans' !important;
            height: 40px;
            box-sizing: border-box;

            &:hover {
                text-transform: none;
                color: #888888;    
                background-color: #fff;
                padding: 10px 24px;
                border-radius: 30px;
                border: 1px solid #cdcdcd;
            }
        }
    }
}

.clients-addnewpopup {
    width: 80%;
    max-width: 630px;
    height: auto;
    max-height: 70vh;
    overflow-y: auto;
    background-color: white;
    border-radius: 8px;
    padding: 40px;
    box-sizing: border-box;

    &-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 0 10px 0;
        border-bottom: 1px solid #ECECEC;
        font-family: 'OpenSans' !important;


        &-title {
            display: flex;
            align-items: center;
            gap: 5px;
            font-size: 20px;
            color: #51A3FF;
        }
        
        &-btn {
            .MuiButtonBase-root {
                color: #51A3FF;
            }
        }
    }

    &-list {
        padding: 20px 0;
        display: flex;
        flex-direction: column;
        // gap: 20px;
    }

    &-item {
        padding-bottom: 20px;

        &.pb-0 {
            padding-bottom: 0;
        }

        &.flex {
            display: flex;
            align-items: center;
            justify-content: space-between;
        }

        &-title {
            width: auto;
            font-size: 14px;
            color: #444;
            font-family: 'OpenSans' !important;

            &.required {
                font-size: 13px;
                margin-top: 27px;
            }
        }

        .MuiFormControl-root {
            width: 80%;
            max-width: 380px;
        }

        &-number {
            width: 80%;
            max-width: 380px;
            
            .MuiFormControl-root {
                width: 100%;
            }

            & .MuiInputBase-root {
                width: 100%;
                border-radius: 8px;
                background-color: #F8FAFC;
    
                input {
                    padding: 10px;
                }
    
                .MuiOutlinedInput-notchedOutline {
                    border-radius: 8px;
                }
    
                &:hover .MuiOutlinedInput-notchedOutline {
                    border-color: #1976d2;
                }
            }

            .MuiInputBase-adornedStart {
                padding-left: 10px;

                input {
                    border-left: 1px solid #e3e3e3;
                }
            }
        }

        &-input {

            & .MuiInputBase-root {
                width: 100%;
                border-radius: 8px;
                background-color: #F8FAFC;
    
                input {
                    padding: 10px;
                    height: 35px;
                    box-sizing: border-box;
                    font-size: 14px;
                    color: #444;
                    font-family: 'OpenSans' !important;
                }
    
                .MuiOutlinedInput-notchedOutline {
                    border-radius: 8px;
                    border-color: #ECECEC;
                }
    
                &:hover .MuiOutlinedInput-notchedOutline {
                    border-color: #1976d2;
                }
            }
        }

        &-project_selection {
            width: 100%;
            text-align: right;
        }

        &-select {
            width: 100%;

            .MuiSelect-select {
                padding: 10px;
                background-color: #F8FAFC;
                height: 35px !important;
                box-sizing: border-box;
                line-height: 1;
                font-size: 14px;
                color: #444;
                font-family: 'OpenSans' !important;
                border-radius: 8px;
            }

            .MuiOutlinedInput-notchedOutline  {
                border-radius: 8px;
                border-color: #ECECEC;
            }

            .MuiSvgIcon-root {
                color: #ACACAC;
            }

            &.MuiInputBase-root:hover .MuiOutlinedInput-notchedOutline {
                border-color: #1976d2;
            }
        }

        &-date {
            width: 80%;
            max-width: 380px;

            .MuiFormControl-root {
                width: 100%;

                .MuiInputBase-input {
                    padding: 10px;
                    background-color: #F8FAFC;
                }

                .MuiOutlinedInput-notchedOutline {
                    border-radius: 8px;
                }

                .MuiInputBase-root:hover .MuiOutlinedInput-notchedOutline {
                    border-color: #1976d2;
                }
            }
        }

        &.switch {
            display: flex;
            align-items: center;
            justify-content: space-between;    
            height: 38px;
            padding-bottom: 10px;

            p {
                cursor: pointer;
            }
        }

        &.tags {            
            display: flex;
            flex-direction: column;
            gap: 5px;

            .MuiInputBase-root {
                width: 100%;
                max-width: unset;

                &:hover .MuiOutlinedInput-notchedOutline {
                    border-color: #1976d2;
                }
            }

            .MuiSelect-select {
                height: 35px !important;
                box-sizing: border-box;
                background-color: #F8FAFC;
                padding: 10px;
                line-height: 1;
                font-size: 14px;
                color: #444;
                font-family: 'OpenSans' !important;
                border-radius: 8px;
            }

            .MuiOutlinedInput-notchedOutline {
                border-radius: 8px;
                border-color: #ECECEC;
            }
        }

        &.notes {
            display: flex;
            flex-direction: column;
            gap: 5px;

            .MuiFormControl-root {
                width: 100%;
                max-width: unset;
            }

            & .MuiInputBase-root {
                border-radius: 8px;
                background-color: #F8FAFC;
                width: 100%;
                min-height: 100px;
                align-items: start;
    
                input {
                    padding: 10px;
                }
    
                .MuiOutlinedInput-notchedOutline {
                    border-radius: 8px;
                }
    
                &:hover .MuiOutlinedInput-notchedOutline {
                    border-color: #1976d2;
                }
            }
        }

        .MuiPaper-root {
            width: 100%;
            color: #93742A;
            background-color: #FCF6EC;
            border-color: #93742A;
            border-radius: 8px;
        }

        .MuiToggleButtonGroup-root {
            justify-content: end;
            padding: 10px 0;

            .MuiButtonBase-root {
                background-color: transparent;
                border: unset;
                padding-top: 0;
                padding-bottom: 0;
                text-transform: none;
                font-size: 14px;

                &:last-child {
                    border-left: 1px solid #51A3FF;
                    padding-right: 0;
                }

                &.Mui-selected {
                    color: #51A3FF;
                }

                &:hover {
                    background-color: unset;
                }
            }
        }

        .MuiCheckbox-root.Mui-checked {
            color: #51A3FF;
        }

        .MuiFormControlLabel-root {
            .MuiTypography-root {
                font-size: 13px;
                font-family: 'OpenSans' !important;
            } 
        }
    }

    &-btn {
        display: flex;
        justify-content: end;
        gap: 10px;

        &-submit.MuiButtonBase-root {
            text-transform: none;
            color: #fff;
            background-color: #51A3FF;
            padding: 10px 24px;
            border-radius: 30px;
            font-size: 14px;
            margin-top: 20px;
            font-family: 'OpenSans' !important;
            height: 40px;
            box-sizing: border-box;

            &:hover {
                text-transform: none;
                color: #fff;
                background-color: #51A3FF;
                padding: 10px 24px;
                border-radius: 30px;
            }
        }

        
        &-reset.MuiButtonBase-root {
            text-transform: none;
            color: #888888;    
            background-color: #fff;
            padding: 10px 24px;
            border-radius: 30px;
            border: 1px solid #cdcdcd;
            font-size: 14px;
            margin-top: 20px;
            font-family: 'OpenSans' !important;
            height: 40px;
            box-sizing: border-box;

            &:hover {
                text-transform: none;
                color: #888888;    
                background-color: #fff;
                padding: 10px 24px;
                border-radius: 30px;
                border: 1px solid #cdcdcd;
            }
        }
    }
}

.clients-deletepopup {
    width: 80%;
    max-width: 390px;
    height: auto;
    background-color: white;
    border-radius: 8px;
    padding: 30px;
    box-sizing: border-box;

    &-header {
        display: flex;
        flex-direction: column;
        text-align: center;
        padding: 0 0 10px 0;
        gap: 10px;
        // border-bottom: 1px solid #ECECEC;


        &-title {
            color: #444;
            line-height: 22px;
            text-align: center;
            font-size: 20px;
            font-weight: 600;
        }

        &-subtitle {
            text-align: center;
            color: #888888;
            font-size: 14px;
        }
        
        &-btn {
            .MuiButtonBase-root {
                color: #51A3FF;
            }
        }
    }

    &-content {
        display: flex;
        gap: 10px;
        justify-content: center;
        padding-top: 50px;

        &-btn {
            display: flex;
            justify-content: end;
            gap: 10px;

            &:first-child {
                .MuiButtonBase-root:first-child {
                    border: 1px solid #ECECEC;
                    color: #888888;
                    text-transform: none;
                    font-size: 14px;
                    padding: 10px 24px;
                    border-radius: 50px;
                    font-size: 14px;
                    margin-top: 20px;
                    font-family: 'OpenSans' !important;
                    height: 40px;
                    box-sizing: border-box;
                }
            }

            &:last-child {
                .MuiButtonBase-root:first-child {
                    border: 1px solid #FD5162;
                    color: #FD5162;
                    text-transform: none;
                    font-size: 14px;
                    padding: 10px 24px;
                    border-radius: 50px;
                    font-size: 14px;
                    margin-top: 20px;
                    font-family: 'OpenSans' !important;
                    height: 40px;
                    box-sizing: border-box;

                    &:hover {
                        color: #fff;
                        background-color: #FD5162;
                    }
                }
            }
        }
    }
}

.clients-editinvoicepopup {
    width: 80%;
    max-width: 630px;
    height: auto;
    max-height: 70vh;
    overflow-y: auto;
    background-color: white;
    border-radius: 8px;
    padding: 40px;
    box-sizing: border-box;

    &-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 0 10px 0;
        border-bottom: 1px solid #ECECEC;


        &-title {
            display: flex;
            align-items: center;
            gap: 5px;
            font-size: 20px;
            color: #51A3FF;

            p {
                font-family: 'OpenSans' !important;
            }
        }
        
        &-btn {
            .MuiButtonBase-root {
                color: #51A3FF;
            }
        }
    }

    &-list {
        padding: 20px 0;
        display: flex;
        flex-direction: column;
        // gap: 20px;
    }

    &-item {
        padding-bottom: 20px;

        &.pb-0 {
            padding-bottom: 0;
        }

        &.flex {
            display: flex;
            align-items: center;
            justify-content: space-between;
        }

        &-title {
            width: auto;
            font-size: 14px;
            color: #444;
            font-family: 'OpenSans' !important;

            &.required {
                font-size: 13px;
                margin-top: 27px;
            }
        }

        .MuiFormControl-root {
            width: 80%;
            max-width: 380px;
        }

        &-number {
            width: 80%;
            max-width: 380px;
            
            .MuiFormControl-root {
                width: 100%;
            }

            & .MuiInputBase-root {
                width: 100%;
                border-radius: 8px;
                background-color: #F8FAFC;
    
                input {
                    padding: 10px;
                }
    
                .MuiOutlinedInput-notchedOutline {
                    border-radius: 8px;
                }
    
                &:hover .MuiOutlinedInput-notchedOutline {
                    border-color: #1976d2;
                }
            }

            .MuiInputBase-adornedStart {
                padding-left: 10px;

                input {
                    border-left: 1px solid #e3e3e3;
                }
            }
        }

        &-input {

            & .MuiInputBase-root {
                width: 100%;
                border-radius: 8px;
                background-color: #F8FAFC;
    
                input {
                    padding: 10px;
                    height: 35px;
                    box-sizing: border-box;
                    font-size: 14px;
                    color: #444;
                    font-family: 'OpenSans' !important;
                }
    
                .MuiOutlinedInput-notchedOutline {
                    border-radius: 8px;
                    border-color: #ECECEC;
                }
    
                &:hover .MuiOutlinedInput-notchedOutline {
                    border-color: #1976d2;
                }
            }
        }

        &-project_selection {
            width: 100%;
            text-align: right;
        }

        &-select {
            width: 100%;

            .MuiSelect-select {
                padding: 10px;
                background-color: #F8FAFC;
                height: 35px !important;
                box-sizing: border-box;
                line-height: 1;
                font-size: 14px;
                color: #444;
                font-family: 'OpenSans' !important;
                border-radius: 8px;
            }

            .MuiOutlinedInput-notchedOutline  {
                border-radius: 8px;
                border-color: #ECECEC;
            }

            .MuiSvgIcon-root {
                color: #ACACAC;
            }

            &.MuiInputBase-root:hover .MuiOutlinedInput-notchedOutline {
                border-color: #1976d2;
            }
        }

        &-date {
            width: 80%;
            max-width: 380px;

            .MuiFormControl-root {
                width: 100%;

                .MuiInputBase-input {
                    padding: 10px;
                    background-color: #F8FAFC;
                }

                .MuiOutlinedInput-notchedOutline {
                    border-radius: 8px;
                }

                .MuiInputBase-root:hover .MuiOutlinedInput-notchedOutline {
                    border-color: #1976d2;
                }
            }
        }

        &.switch {
            display: flex;
            align-items: center;
            justify-content: space-between;    
            height: 38px;
            padding-bottom: 10px;

            p {
                cursor: pointer;
            }
        }

        &.tags {            
            display: flex;
            flex-direction: column;
            gap: 5px;

            .MuiInputBase-root {
                width: 100%;
                max-width: unset;

                &:hover .MuiOutlinedInput-notchedOutline {
                    border-color: #1976d2;
                }
            }

            .MuiSelect-select {
                height: 35px !important;
                box-sizing: border-box;
                background-color: #F8FAFC;
                padding: 10px;
                line-height: 1;
                font-size: 14px;
                color: #444;
                font-family: 'OpenSans' !important;
                border-radius: 8px;
            }

            .MuiOutlinedInput-notchedOutline {
                border-radius: 8px;
                border-color: #ECECEC;
            }
        }

        &.notes {
            display: flex;
            flex-direction: column;
            gap: 5px;

            .MuiFormControl-root {
                width: 100%;
                max-width: unset;
            }

            & .MuiInputBase-root {
                border-radius: 8px;
                background-color: #F8FAFC;
                width: 100%;
                min-height: 100px;
                align-items: start;
    
                input {
                    padding: 10px;
                }
    
                .MuiOutlinedInput-notchedOutline {
                    border-radius: 8px;
                }
    
                &:hover .MuiOutlinedInput-notchedOutline {
                    border-color: #1976d2;
                }
            }
        }

        .MuiPaper-root {
            width: 100%;
            color: #93742A;
            background-color: #FCF6EC;
            border-color: #93742A;
            border-radius: 8px;
        }

        .MuiToggleButtonGroup-root {
            justify-content: end;
            padding: 10px 0;

            .MuiButtonBase-root {
                background-color: transparent;
                border: unset;
                padding-top: 0;
                padding-bottom: 0;
                text-transform: none;
                font-size: 14px;

                &:last-child {
                    border-left: 1px solid #51A3FF;
                    padding-right: 0;
                }

                &.Mui-selected {
                    color: #51A3FF;
                }

                &:hover {
                    background-color: unset;
                }
            }
        }

        .MuiCheckbox-root.Mui-checked {
            color: #51A3FF;
        }

        .MuiFormControlLabel-root {
            .MuiTypography-root {
                font-size: 13px;
                font-family: 'OpenSans' !important;
            } 
        }
    }

    &-btn {
        display: flex;
        justify-content: end;
        gap: 10px;

        &-submit.MuiButtonBase-root {
            text-transform: none;
            color: #fff;
            background-color: #51A3FF;
            padding: 10px 24px;
            border-radius: 30px;
            font-size: 14px;
            margin-top: 20px;
            font-family: 'OpenSans' !important;
            height: 40px;
            box-sizing: border-box;

            &:hover {
                text-transform: none;
                color: #fff;
                background-color: #51A3FF;
                padding: 10px 24px;
                border-radius: 30px;
            }
        }

        
        &-reset.MuiButtonBase-root {
            text-transform: none;
            color: #888888;    
            background-color: #fff;
            padding: 10px 24px;
            border-radius: 30px;
            border: 1px solid #cdcdcd;
            font-size: 14px;
            margin-top: 20px;
            font-family: 'OpenSans' !important;
            height: 40px;
            box-sizing: border-box;

            &:hover {
                text-transform: none;
                color: #888888;    
                background-color: #fff;
                padding: 10px 24px;
                border-radius: 30px;
                border: 1px solid #cdcdcd;
            }
        }
    }
}

.clients-invoicerecordpopup {
    width: 80%;
    max-width: 580px;
    height: auto;
    background-color: white;
    border-radius: 8px;
    padding: 40px;
    box-sizing: border-box;

    &-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 0 10px 0;
        border-bottom: 1px solid #ECECEC;


        &-title {
            display: flex;
            align-items: center;
            gap: 5px;
            font-size: 20px;
            color: #51A3FF; 

            p {
                font-family: 'OpenSans' !important;
            }
        }
        
        &-btn {
            .MuiButtonBase-root {
                color: #51A3FF;
            }
        }
    }

    &-list {
        padding: 20px 0;
        display: flex;
        flex-direction: column;
        // gap: 20px;
    }

    &-table {
        box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.10);
        border-radius: 8px;

        &-body {
            &-tr {
                display: flex;

                &:not(:last-child) {
                    border-bottom: 1px solid #E8E7E4;
                }

                &.expense {
                    background-color: #B0B0B01A;
                    border-radius: 0 0 8px 8px;
                    
                    .money-invoicerecordpopup-table-body-tr-value {
                        font-weight: 600;
                    }
                }

                &-title {
                    width: 50%;
                    padding: 15px;
                    font-weight: 600;
                    color: #333;
                    font-size: 13px;
                }

                &-value {
                    width: 50%;
                    padding: 15px;
                    color: #444;
                    font-size: 13px;
                    border-left: 1px solid #E8E7E4;
                    text-transform: capitalize;
                    display: flex;
                    gap: 10px;

                    .active-label {
                        color: #51A3FF;
                        background-color: #51A3FF33;
                        padding: 5px 15px;
                        border-radius: 8px;
                    }

                    .notactive-label {
                        color: #A6B5BD;
                        background-color: #B0B0B033;
                        padding: 5px 15px;
                        border-radius: 8px;
                    }
                }
            }
        }
    }

}

.clients-sendemailpopup {
    width: 80%;
    max-width: 700px;
    height: auto;
    max-height: 70vh;
    overflow-y: auto;
    background-color: white;
    border-radius: 8px;
    padding: 40px;
    box-sizing: border-box;

    &-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 0 10px 0;
        border-bottom: 1px solid #ECECEC;


        &-title {
            display: flex;
            align-items: center;
            gap: 5px;
            font-size: 20px;
            color: #51A3FF;

            p {
                font-family: 'OpenSans' !important;
            }
        }
        
        &-btn {
            .MuiButtonBase-root {
                color: #51A3FF;
            }
        }
    }

    &-list {
        padding: 20px 0;
        display: flex;
        flex-direction: column;
        // gap: 20px;
    }

    &-item {
        padding-bottom: 20px;

        &.pb-0 {
            padding-bottom: 0;
        }

        &.flex {
            display: flex;
            flex-direction: column;
            gap: 5px;
        }

        &-title {
            width: auto;
            font-size: 14px;
            color: #444;
            font-family: 'OpenSans' !important;
        }

        .MuiFormControl-root {
            width: 100%;
            max-width: unset;
        }


        &-input {

            & .MuiInputBase-root {
                width: 100%;
                border-radius: 8px;
                background-color: #F8FAFC;
    
                input {
                    padding: 10px;
                }
    
                .MuiOutlinedInput-notchedOutline {
                    border-radius: 8px;
                }
    
                &:hover .MuiOutlinedInput-notchedOutline {
                    border-color: #1976d2;
                }
            }
        }

        &-project_selection {
            width: 100%;
            text-align: right;
        }

        &-select {
            width: 100%;

            .MuiSelect-select {
                height: 35px !important;
                box-sizing: border-box;
                line-height: 1;
                padding: 10px;
                background-color: #F8FAFC;
                font-family: 'OpenSans' !important;
                color: #444;
                font-size: 14px;
                border-radius: 8px;
            }

            .MuiOutlinedInput-notchedOutline  {
                border-radius: 8px;
                border-color: #ECECEC;
            }

            .MuiSvgIcon-root {
                color: #ACACAC;
            }

            &.MuiInputBase-root:hover .MuiOutlinedInput-notchedOutline {
                border-color: #1976d2;
            }
        }

        &-attach {
            border-color: #D7D7D7 !important;
            border-radius: 8px !important;
            min-height: 130px !important;
            align-items: center;
            display: flex;
            justify-content: center;

            .MuiDropzoneArea-textContainer {
                display: flex;
                flex-direction: column-reverse;
                justify-content: center;

                .MuiTypography-root.MuiDropzoneArea-text {
                    color: #888888;
                    font-size: 13px;
                    margin-top: 12px;
                    margin-bottom: 12px;
                    font-family: 'OpenSans' !important;
                }

                img {
                    width: 41px;
                    height: 41px;
                    margin: 0 auto;
                }
            }


        }

        &.notes {
            display: flex;
            align-items: top;
            justify-content: space-between;

            & .MuiInputBase-root {
                border-radius: 8px;
                background-color: #F8FAFC;
                width: 100%;
                min-height: 200px;
                align-items: start;

                textarea {
                    font-family: 'OpenSans' !important;
                    color: #444;
                    font-size: 14px;
                }
    
                input {
                    padding: 10px;
                }
    
                .MuiOutlinedInput-notchedOutline {
                    border-radius: 8px;
                    border-color: #ECECEC;
                }
    
                &:hover .MuiOutlinedInput-notchedOutline {
                    border-color: #1976d2;
                }
            }
        }
    }

    &-btn {
        display: flex;
        justify-content: end;
        gap: 10px;

        &-submit.MuiButtonBase-root {
            text-transform: none;
            color: #fff;
            background-color: #51A3FF;
            padding: 10px 24px;
            border-radius: 30px;
            font-size: 14px;
            margin-top: 20px;
            font-family: 'OpenSans' !important;
            height: 40px;
            box-sizing: border-box;

            &:hover {
                text-transform: none;
                color: #fff;
                background-color: #51A3FF;
                padding: 10px 24px;
                border-radius: 30px;
            }
        }

        
        &-reset.MuiButtonBase-root {
            text-transform: none;
            color: #888888;    
            background-color: #fff;
            padding: 10px 24px;
            border-radius: 30px;
            border: 1px solid #cdcdcd;
            font-size: 14px;
            margin-top: 20px;
            font-family: 'OpenSans' !important;
            height: 40px;
            box-sizing: border-box;

            &:hover {
                text-transform: none;
                color: #888888;    
                background-color: #fff;
                padding: 10px 24px;
                border-radius: 30px;
                border: 1px solid #cdcdcd;
            }
        }
    }
}

.clients-companyinfopopup {
    width: 80%;
    max-width: 580px;
    height: auto;
    background-color: white;
    border-radius: 8px;
    padding: 40px;
    box-sizing: border-box;


    &-content {
        &-logo {
            text-align: center;
            padding-bottom: 15px;

            img {
                width: 160px;
            }
        }

        &-tabs {

            & .MuiBox-root {
                height: 32px;
            }

            & .MuiTabs-scroller {
                height: 32px;
            } 

            &-btn.MuiButtonBase-root {
                text-transform: none;
                font-size: 14px;
                padding: 0;
                color: #888;
                font-family: 'OpenSans' !important;
                min-width: unset;
                flex: unset;
                min-height: unset;
                height: 22px;
            

                &.Mui-selected {
                    font-weight: 600;
                    color: #51A3FF;
                }
            }

            & .MuiTabs-indicator {
                color: #51A3FF;
                background-color: #51A3FF;
            }

            .MuiTabs-flexContainer.MuiTabs-centered {
                justify-content: space-between;
            }
        }

        &-tab {
            padding: 0 15px;

            &-info {
                display: flex;
                flex-direction: column;
                gap: 15px;

                &-item {
                    display: flex;
                    flex-direction: column;
                    // gap: 4px;

                    &-title {
                        font-size: 13px;
                        color: #B4B4B4;
                        font-family: 'OpenSans' !important;
                        line-height: 22px;
                    }
    
                    &-subtitle {
                        font-size: 14px;
                        color: #444444;
                        font-family: 'OpenSans' !important;
                        line-height: 22px;

                        &.linked a{
                            color: #51A3FF;
                        }

                        &.avatar img {
                            width: 30px;
                            height: 30px;
                        }
                    }
                }

                &-item.owner {
                    .clients-companyinfopopup-content-tab-info-item-subtitle {
                        display: flex;
                        align-items: center;
                        gap: 5px;
                    }
                }

                
                &-item.status {
                    .clients-companyinfopopup-content-tab-info-item-subtitle {
                        .active {
                            padding: 0 10px;
                            background-color: #58D8C1;
                            color: #ffffff;
                            display: inline-block;
                            border-radius: 30px;
                            font-size: 12px;
                            font-weight: 500;
                            height: 24px;
                            text-align: center;
                            line-height: 2;
                        }

                        .notactive {
                            padding: 0 10px;
                            background-color: #FD5162;
                            color: #ffffff;
                            display: inline-block;
                            border-radius: 30px;
                            font-size: 12px;
                            font-weight: 500;
                            height: 24px;
                            text-align: center;
                            line-height: 2;
                        }
                    }
                }

                &-item.category {
                    .clients-companyinfopopup-content-tab-info-item-subtitle {
                        .category {
                            padding: 0 10px;
                            background-color: #51A3FF;
                            color: #ffffff;
                            display: inline-block;
                            border-radius: 30px;
                            font-size: 12px;
                            font-weight: 500;
                            height: 24px;
                            text-align: center;
                            line-height: 2;
                        }
                    }
                }

                &-item.tags {
                    .clients-companyinfopopup-content-tab-info-item-subtitle {
                        display: flex;
                        gap: 10px;

                        .MuiChip-root {
                            font-size: 12px;
                            border-radius: 20px;
                            background: #ECECEC;          
                            height: 24px;
                            padding: 0px 12px;
                        }

                        .MuiChip-label {
                            padding-left: 0;
                            padding-right: 0;
                            height: 100%;
                            line-height: 2;
                            color: #444;
                        }
                    }
                }
            }

            &-project {
                padding-top: 15px;

                &-table {
                    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
                    border-radius: 8px;
            
                    &-body {
                        &-tr {
                            display: flex;
            
                            &:not(:last-child) {
                                border-bottom: 1px solid #E8E7E4;
                            }
            
                            &.expense {
                                background-color: #B0B0B01A;
                                border-radius: 0 0 8px 8px;
                                
                                .money-invoicerecordpopup-table-body-tr-value {
                                    font-weight: 600;
                                }
                            }
            
                            &-title {
                                width: 50%;
                                padding: 15px;
                                font-weight: 600;
                                color: #444;
                                font-size: 13px;
                                width: 170px;
                            }
            
                            &-value {
                                width: 50%;
                                padding: 15px;
                                color: #444;
                                font-size: 13px;
                                border-left: 1px solid #E8E7E4;
                                text-transform: capitalize;
                                display: flex;
                                gap: 10px;
            
                                .active-label {
                                    color: #51A3FF;
                                    background-color: #51A3FF33;
                                    padding: 5px 15px;
                                    border-radius: 8px;
                                }
            
                                .notactive-label {
                                    color: #A6B5BD;
                                    background-color: #B0B0B033;
                                    padding: 5px 15px;
                                    border-radius: 8px;
                                }
                            }
                        }
                    }
                }
            }

            &-address {
                display: flex;
                flex-direction: column;
                gap: 10px;
                padding-top: 15px;

                &-item {
                    color: #444444;
                    font-size: 14px;
                    line-height: 22px;
                    font-family: 'OpenSans' !important;
                }
            }

            &-other {
                display: flex;
                flex-direction: column;
                gap: 15px;
                padding-top: 15px;

                &-item {
                    display: flex;
                    flex-direction: column;
                    // gap: 8px;

                    &-title {
                        color: #B4B4B4;
                        font-size: 13px;
                        font-family: 'OpenSans' !important;
                        line-height: 22px;
                    }
    
                    &-subtitle {
                        color: #444;
                        font-size: 14px;
                        font-family: 'OpenSans' !important;
                        line-height: 22px;
                    }

                    &.alert {
                        
                        .MuiPaper-root {
                            align-items: flex-start;
                            border-color: #51A3FF;
                            border-radius: 8px;
                            position: relative;
                        }
            
                        .MuiAlert-message {
                            color: #2E6098;
                            font-size: 13px;
                            font-family: 'OpenSans' !important;
            
                            .MuiTypography-root {
                                font-size: 16px;
                                color: #51A3FF;
                                font-family: 'OpenSans' !important;
                            }
                        }
            
            
                        .MuiAlert-icon {
                            color: #51A3FF !important;
                        }
            
                        .MuiAlert-action {
                            position: absolute;
                            right: 20px;
                            
                            .MuiButtonBase-root {
                                color: #51A3FF;
                            }
                        }
                    }
                }
            }
        }
    }
}