@import '../../../styles/sass/variables.scss';

.invoice-manager-page {
    width: 100%;
    // padding-bottom: 10px;
    // height: 75vh;
    height: 100%;
    box-sizing: border-box;

    .grid-content_right {
        // max-height: 100%;
        // overflow: auto;
    }

    & .MuiGrid-container {
        margin: 0;
      }

      & .MuiGrid-item {
        padding-top: 0 !important;
      }

      & .MuiGrid-root {
        height: 100%;
      }

    &_sidebar {
        padding: 0 5px 50px 5px;
        display: flex;
        flex-direction: column;
        gap: 20px;

        &_create-event {
            &_btn.MuiButtonBase-root {
              background-color: $blue;
              width: 100%;
              padding: 10px;
              text-align: center;
              color: #fff;
              border-radius: 8px;
              text-transform: none;
            }

            &_btn.MuiButtonBase-root:hover {
              background-color: $blue;
            }
        }

        &-section {
            &_category {
                &-list {

                    & .MuiTabs-flexContainer {
                        gap: 5px;
                    }

                }

                &-item {
                    &.MuiButtonBase-root {
                        text-transform: none;
                        display: flex;
                        flex-direction: row;
                        justify-content: space-between;
                        padding: 10px 20px;
                        border-radius: 8px;
                        max-width: unset;

                        &:hover {
                            background-color: rgba(67, 130, 196, 0.1);
                        }
                    }

                    .blue-icon {
                        display: none;
                    }

                    &.Mui-selected {
                        background: rgba(67, 130, 196, 0.10);

                        .invoice-manager-page_sidebar-section_category-item_content-title {
                            color: #4382c4;
                        }

                        .MuiSvgIcon-root {
                            color: #1976d2;
                        }

                        .blue-icon {
                            display: inline-block;
                        }

                        .gray-icon {
                            display: none;
                        }
                    }



                    &_content {
                        display: flex;
                        gap: 15px;
                        align-items: center;

                        &-icon {
                            width: 20px;
                            height: 20px;
                        }

                        &-title {
                            font-size: 15px;
                            color: #414141;
                            // font-weight: 600;
                        }

                        svg {
                            color: rgba(0, 0, 0, 0.54);
                        }
                    }

                    &_unreadnum {
                        background-color: #4382C4;
                        border-radius: 5px;
                        padding: 4px 8px;
                        color: white;
                    }
                }

                & .MuiTabs-indicator {
                    display: none;
                }
            }
        }
    }

    &_main {
        height: 100%;
        padding: 0 5px 0 15px;

        &_tabs {
            width: 100%;
            height: 100%;
        }

        &_invoices-tab {
            height: 100%;
            width: 100%;
            box-sizing: border-box;

            .MuiBox-root {
                height: 100%;
                box-sizing: border-box;
                padding: 0;
            }

            .MuiTypography-root {
                height: 100%;
                box-sizing: border-box;
            }

            .MuiTablePagination-root {
                overflow: hidden;
                min-height: unset;
                height: 44px;
                box-sizing: border-box;

                .MuiToolbar-root {
                    min-height: unset;
                    height: 44px;
                    box-sizing: border-box;
                }

            }

            .MuiPaper-root {
                height: 100%;
                box-sizing: border-box;
                display: flex;
                flex-direction: column;
                box-shadow: unset;
                padding: 10px;
                padding-top: 5px;
                background-color: #ffffff;
                border-radius: 8px;
            }

            .MuiTableCell-root {
                img {
                    vertical-align: middle;
                }
            }

            &-row {

                &-item {
                    &.MuiTableCell-root  {
                        cursor: auto;
                        max-width: 100px;
                        white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        color: #333333;
                        font-size: 13px;
                        padding: 5px;
                        text-align: left;
                        height: 50px;
                        box-sizing: border-box;
                    }

                    &.blue {
                        color: #51A3FF;
                    }

                    &.paid span {
                        color: #66CFA9;
                        background-color: #66CFA91A;
                        display: inline-block;
                        padding: 5px 10px;
                        border-radius: 8px;
                        height: 24px;
                        box-sizing: border-box;
                        line-height: 16px;
                        font-size: 12px;
                    }

                    &.due span {
                        color: #E0B974;
                        background-color: #E0B97426;
                        display: inline-block;
                        padding: 5px 10px;
                        border-radius: 8px;
                        height: 24px;
                        box-sizing: border-box;
                        line-height: 16px;
                        font-size: 12px;
                    }

                    &.overdue span {
                        color: #FD5162;
                        background-color: #F44D251A;
                        display: inline-block;
                        padding: 5px 10px;
                        border-radius: 8px;
                        height: 24px;
                        box-sizing: border-box;
                        line-height: 16px;
                        font-size: 12px;
                    }

                    &.draft span {
                        color: #A3A3A3;
                        background-color: #B2B2B21A;
                        display: inline-block;
                        padding: 5px 10px;
                        border-radius: 8px;
                        height: 24px;
                        box-sizing: border-box;
                        line-height: 16px;
                        font-size: 12px;
                    }

                }

                &-actions.MuiTableCell-root {
                    padding: 5px;
                    cursor: pointer;
                    max-width: 100px;

                    .MuiButtonBase-root {
                        padding: 5px;

                        .MuiSvgIcon-root {
                            font-size: 20px;
                            color: #B4B4B4;
                        }

                        .color-icon {
                            display: none;
                        }
                    }
                }

                &:hover {
                    .invoice-manager-page_main_invoices-tab-row-actions {
                        .MuiButtonBase-root:nth-child(1) {
                            .MuiSvgIcon-root {
                                color: #FD5162;
                            }
                        }

                        .MuiButtonBase-root:nth-child(2) {
                            .MuiSvgIcon-root {
                                color: #66CFA9;
                            }
                        }

                        .MuiButtonBase-root:nth-child(3) {
                            .MuiSvgIcon-root {
                                color: #51A3FF;
                            }
                        }

                        .MuiButtonBase-root:nth-child(4) {
                            .MuiSvgIcon-root {
                                color: #51A3FF;
                            }
                        }
                    }

                    .gray-icon {
                        display: none;
                    }

                    .color-icon {
                        display: inline-block !important;
                    }
                }

                &-actions_option-list {
                    .MuiPaper-root {
                        box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.1) !important;

                        .MuiButtonBase-root {
                            padding: 8px 30px 8px 20px;

                            .MuiTypography-root {
                                font-size: 14px;
                                font-weight: 400;
                                color: #444;
                            }
                        }
                    }
                }

                .MuiCheckbox-root {
                    .MuiSvgIcon-root {
                        stroke: #fff;
                        color: #ADABA8;
                    }
                }

                .Mui-checked .MuiSvgIcon-root, .MuiCheckbox-indeterminate .MuiSvgIcon-root {
                    color: #51A3FF;
                    stroke: rgba(25, 118, 210, 0.08);
                }
            }
        }

        &_money-tab {
            width: 100%;
            height: 100%;
            box-sizing: border-box;

            .MuiBox-root {
                // height: 100%;
                height: calc(100% - 50px);
                box-sizing: border-box;
                padding: 0;
            }

            .MuiTypography-root {
                height: 100%;
                box-sizing: border-box;
            }

            .MuiTablePagination-root {
                overflow: hidden;
                min-height: unset;
                height: 44px;
                box-sizing: border-box;

                .MuiToolbar-root {
                    min-height: unset;
                    height: 44px;
                    box-sizing: border-box;
                }

            }

            .MuiPaper-root {
                height: 100%;
                box-sizing: border-box;
                display: flex;
                flex-direction: column;
                box-shadow: unset;
                padding: 10px;
                padding-top: 5px;
                background-color: #ffffff;
                border-radius: 8px;
                margin-bottom: 0;
            }

            .MuiTableCell-root {
                img {
                    vertical-align: middle;
                }
            }

            &-row {

                &-item {
                    &.MuiTableCell-root  {
                        cursor: auto;
                        max-width: 200px;
                        white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        color: #333333;
                        font-size: 13px;
                        padding: 5px;
                        text-align: left;
                        height: 50px;
                        box-sizing: border-box;
                    }

                    &-plus {
                        color: #5AA376;
                        background-color: #CFF4DD80;
                        display: inline-block;
                        padding: 5px 10px;
                        border-radius: 8px;
                        height: 24px;
                        box-sizing: border-box;
                        line-height: 15px;
                        font-size: 12px;
                        font-weight: 500;
                        min-width: 78px;
                        text-align: center;
                    }

                    &-minus {
                        color: #894E92;
                        background-color: #EBDEEDCC;
                        display: inline-block;
                        padding: 5px 10px;
                        border-radius: 8px;
                        height: 24px;
                        box-sizing: border-box;
                        line-height: 15px;
                        font-size: 12px;
                        font-weight: 500;
                        min-width: 78px;
                        text-align: center;
                    }

                    &.blue {
                        color: #51A3FF;
                    }

                }

                &-actions.MuiTableCell-root {
                    padding: 5px;
                    cursor: pointer;
                    max-width: 100px;
                    text-align: left;

                    .MuiButtonBase-root {
                        padding: 5px;

                        .MuiSvgIcon-root {
                            font-size: 20px;
                            color: #B4B4B4;
                        }
                    }
                    .color-icon {
                        display: none;
                    }
                }

                &:hover {
                    .invoice-manager-page_main_money-tab-row-actions {
                        .MuiButtonBase-root:nth-child(1) {
                            .MuiSvgIcon-root {
                                color: #FD5162;
                            }
                        }

                        .MuiButtonBase-root:nth-child(2) {
                            .MuiSvgIcon-root {
                                color: #66CFA9;
                            }
                        }

                        .MuiButtonBase-root:nth-child(3) {
                            .MuiSvgIcon-root {
                                color: #51A3FF;
                            }
                        }

                        .MuiButtonBase-root:nth-child(4) {
                            .MuiSvgIcon-root {
                                color: #51A3FF;
                            }
                        }
                    }

                    .gray-icon {
                        display: none;
                    }

                    .color-icon {
                        display: inline-block !important;
                    }
                }

                &-actions_option-list {
                    .MuiPaper-root {
                        box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.1) !important;
                        border-radius: 8px !important;

                        .MuiButtonBase-root {
                            padding: 8px 30px 8px 20px;

                            .MuiTypography-root {
                                font-size: 14px;
                                font-weight: 400;
                                color: #414141;
                            }
                        }
                    }
                }

                .MuiCheckbox-root {
                    .MuiSvgIcon-root {
                        stroke: #fff;
                        color: #ADABA8;
                    }
                }

                .Mui-checked .MuiSvgIcon-root, .MuiCheckbox-indeterminate .MuiSvgIcon-root {
                    color: #51A3FF;
                    stroke: rgba(25, 118, 210, 0.08);
                }
            }

            .money-transactions {
                display: flex;
                width: 100%;
                gap: 20px;
                margin-bottom: 20px;

                &-card {
                    width: 33%;
                    background-color: #ffffff;
                    padding: 20px;
                    border-radius: 8px;
                    height: 80px;
                    box-sizing: border-box;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    gap: 20px;

                    &-info {
                        display: flex;
                        font-size: 14px;
                        gap: 15px;
                        align-items: center;

                        &-icon {
                            width: 30px;
                            height: 30px;

                            img {
                                width: inherit;
                                height: inherit;
                            }
                        }

                        &-text {
                            &-title {
                                color: #444444;
                                font-weight: 600;
                                font-size: 13px;
                            }

                            &-subtitle {
                                color: #888888;
                                font-size: 13px;
                            }
                        }
                    }

                    &-amount {
                        font-size: 14px;
                        color: #444444;
                        font-weight: 600;
                        font-family: 'OpenSans' !important;
                    }

                }
            }
        }

        &_clients-tab {
            height: 100%;
            width: 100%;
            box-sizing: border-box;

            .MuiBox-root {
                height: 100%;
                box-sizing: border-box;
                padding: 0;
            }

            .MuiTypography-root {
                height: 100%;
                box-sizing: border-box;
            }

            .MuiTablePagination-root {
                overflow: hidden;
                min-height: unset;
                height: 44px;
                box-sizing: border-box;

                .MuiToolbar-root {
                    min-height: unset;
                    height: 44px;
                    box-sizing: border-box;
                }

            }

            .MuiPaper-root {
                height: 100%;
                box-sizing: border-box;
                display: flex;
                flex-direction: column;
                box-shadow: unset;
                padding: 10px;
                padding-top: 5px;
                background-color: #ffffff;
                border-radius: 8px;
            }

            .MuiTableCell-root {
                img {
                    vertical-align: middle;
                }
            }

            &-row {

                &-item {
                    &.MuiTableCell-root  {
                        cursor: auto;
                        // max-width: 100px;
                        white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        color: #333333;
                        font-size: 13px;
                        padding: 5px;
                        text-align: left;
                        height: 50px;
                        box-sizing: border-box;
                    }

                    &.tag {
                        .MuiChip-root {
                            background-color: #F7F7F7;
                            color: #A3A3A3;
                            border-radius: 6px;
                            text-transform: capitalize;
                            height: 24px;
                            box-sizing: border-box;
                            line-height: 15px;
                            font-size: 12px;
                            font-weight: 500;
                            text-align: center;

                            .MuiChip-label {
                                color: #A3A3A3;
                                font-size: 12px;
                                padding: 0 8px;
                            }
                        }
                    }

                    &.category {
                        .MuiChip-root {
                            background-color: #F7F7F7;
                            color: #A3A3A3;
                            border-radius: 6px;
                            text-transform: capitalize;
                            height: 24px;
                            box-sizing: border-box;
                            line-height: 15px;
                            font-size: 12px;
                            font-weight: 500;
                            text-align: center;

                            .MuiChip-label {
                                color: #A3A3A3;
                                font-size: 12px;
                                padding: 0 8px;
                            }
                        }
                    }

                    &.status {
                        .MuiChip-root.active {
                            background-color: #51A3FF1A;
                            color: #51A3FF;
                            border-radius: 6px;
                            text-transform: capitalize;
                            height: 24px;
                            box-sizing: border-box;
                            line-height: 15px;
                            font-size: 12px;
                            font-weight: 500;
                            text-align: center;

                            .MuiChip-label {
                                font-size: 12px;
                                padding: 0 8px;
                            }
                        }

                        .MuiChip-root.notactive {
                            background-color: #FD51621A;
                            color: #FD5162;
                            border-radius: 6px;
                            text-transform: capitalize;
                            height: 24px;
                            box-sizing: border-box;
                            line-height: 15px;
                            font-size: 12px;
                            font-weight: 500;
                            text-align: center;

                            .MuiChip-label {
                                font-size: 12px;
                                padding: 0 8px;
                            }
                        }
                    }

                    & .owner {
                        display: flex;
                        align-items: center;
                        gap: 10px;
                        max-width: 150px;

                        .MuiAvatar-root {
                            width: 26px;
                            height: 26px;
                        }
                    }

                    &.blue {
                        color: #51A3FF;
                    }

                    &.pointer {
                        cursor: pointer;
                    }

                    &.paid span {
                        color: #66CFA9;
                        background-color: #66CFA91A;
                        display: inline-block;
                        padding: 5px 10px;
                        border-radius: 8px;
                    }

                    &.due span {
                        color: #E0B974;
                        background-color: #E0B97426;
                        display: inline-block;
                        padding: 5px 10px;
                        border-radius: 8px;
                    }

                    &.overdue span {
                        color: #FD5162;
                        background-color: #F44D251A;
                        display: inline-block;
                        padding: 5px 10px;
                        border-radius: 8px;
                    }

                    &.draft span {
                        color: #A3A3A3;
                        background-color: #B2B2B21A;
                        display: inline-block;
                        padding: 5px 10px;
                        border-radius: 8px;
                    }

                }

                &-actions.MuiTableCell-root {
                    padding: 10px;
                    cursor: pointer;
                    max-width: 100px;

                    .MuiButtonBase-root {
                        padding: 5px;

                        .MuiSvgIcon-root {
                            font-size: 20px;
                            color: #B4B4B4;
                        }
                    }

                    .color-icon {
                        display: none;
                    }
                }

                &:hover {
                    .invoice-manager-page_main_clients-tab-row-actions {
                        .MuiButtonBase-root:nth-child(1) {
                            .MuiSvgIcon-root {
                                color: #FD5162;
                            }
                        }

                        .MuiButtonBase-root:nth-child(2) {
                            .MuiSvgIcon-root {
                                color: #66CFA9;
                            }
                        }

                        .MuiButtonBase-root:nth-child(3) {
                            .MuiSvgIcon-root {
                                color: #51A3FF;
                            }
                        }

                        .MuiButtonBase-root:nth-child(4) {
                            .MuiSvgIcon-root {
                                color: #51A3FF;
                            }
                        }
                    }

                    .gray-icon {
                        display: none;
                    }

                    .color-icon {
                        display: inline-block !important;
                    }
                }

                &-actions_option-list {
                    .MuiPaper-root {
                        box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.1) !important;

                        .MuiButtonBase-root {
                            padding: 8px 30px 8px 20px;
                        }
                    }
                }

                .MuiCheckbox-root {
                    .MuiSvgIcon-root {
                        stroke: #fff;
                        color: #ADABA8;
                    }
                }

                .Mui-checked .MuiSvgIcon-root, .MuiCheckbox-indeterminate .MuiSvgIcon-root {
                    color: #51A3FF;
                    stroke: rgba(25, 118, 210, 0.08);
                }
            }
        }

        &_products-tab {
            height: 100%;
            width: 100%;
            box-sizing: border-box;

            .MuiBox-root {
                height: 100%;
                box-sizing: border-box;
                padding: 0;
            }

            .MuiTypography-root {
                height: 100%;
                box-sizing: border-box;
            }

            .MuiTablePagination-root {
                overflow: hidden;
                min-height: unset;
                height: 44px;
                box-sizing: border-box;

                .MuiToolbar-root {
                    min-height: unset;
                    height: 44px;
                    box-sizing: border-box;
                }

            }

            .MuiPaper-root {
                height: 100%;
                box-sizing: border-box;
                display: flex;
                flex-direction: column;
                box-shadow: unset;
                padding: 10px;
                padding-top: 5px;
                background-color: #ffffff;
                border-radius: 8px;
            }

            .MuiTableCell-root {
                img {
                    vertical-align: middle;
                }
            }

            &-row {

                &-item {
                    &.MuiTableCell-root  {
                        cursor: auto;
                        // max-width: 100px;
                        // width: 15%;
                        white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        color: #333333;
                        font-size: 13px;
                        padding: 5px;
                        text-align: left;
                        height: 50px;
                        box-sizing: border-box;
                    }

                    &.blue {
                        color: #51A3FF;
                    }

                    &.paid span {
                        color: #66CFA9;
                        background-color: #66CFA91A;
                        display: inline-block;
                        padding: 5px 10px;
                        border-radius: 8px;
                    }

                    &.due span {
                        color: #E0B974;
                        background-color: #E0B97426;
                        display: inline-block;
                        padding: 5px 10px;
                        border-radius: 8px;
                    }

                    &.overdue span {
                        color: #FD5162;
                        background-color: #F44D251A;
                        display: inline-block;
                        padding: 5px 10px;
                        border-radius: 8px;
                    }

                    &.draft span {
                        color: #A3A3A3;
                        background-color: #B2B2B21A;
                        display: inline-block;
                        padding: 5px 10px;
                        border-radius: 8px;
                    }

                }

                &-actions.MuiTableCell-root {
                    padding: 5px;
                    cursor: pointer;
                    text-align: left;


                    .MuiButtonBase-root {
                        padding: 5px;

                        .MuiSvgIcon-root {
                            font-size: 20px;
                            color: #B4B4B4;
                        }
                    }

                    .color-icon {
                        display: none;
                    }
                }

                &:hover {
                    .invoice-manager-page_main_products-tab-row-actions {
                        .MuiButtonBase-root:nth-child(1) {
                            .MuiSvgIcon-root {
                                color: #FD5162;
                            }
                        }

                        .MuiButtonBase-root:nth-child(2) {
                            .MuiSvgIcon-root {
                                color: #66CFA9;
                            }
                        }

                        .MuiButtonBase-root:nth-child(3) {
                            .MuiSvgIcon-root {
                                color: #51A3FF;
                            }
                        }

                        .MuiButtonBase-root:nth-child(4) {
                            .MuiSvgIcon-root {
                                color: #51A3FF;
                            }
                        }
                    }

                    .gray-icon {
                        display: none;
                    }

                    .color-icon {
                        display: inline-block !important;
                    }
                }

                &-actions_option-list {
                    .MuiPaper-root {
                        box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.1) !important;

                        .MuiButtonBase-root {
                            padding: 8px 30px 8px 20px;
                            .MuiTypography-root {
                                font-size: 14px;
                                font-weight: 400;
                                color: #444;
                            }
                        }
                    }
                }

                &-item:nth-child(2) {
                    // width: 40%;
                    max-width: 350px;
                }

                .MuiCheckbox-root {
                    .MuiSvgIcon-root {
                        stroke: #fff;
                        color: #ADABA8;
                    }

                }

                .Mui-checked .MuiSvgIcon-root, .MuiCheckbox-indeterminate .MuiSvgIcon-root {
                    color: #51A3FF;
                    stroke: rgba(25, 118, 210, 0.08);
                }
            }
        }

    }

    .invoices-table {
        border-collapse: separate;
    }

    .money-table {
        border-collapse: separate;


    }

    .clients-table {
        border-collapse: separate;
    }

    .products-table {
        border-collapse: separate;
    }



    .invoices-header {
        width: 100%;
        position: sticky;
        top: 0;
        background-color: white;
        z-index: 1;


        &-row {
            &-cell {
                color: #333333;
                font-weight: 500;
                padding: 7px 5px;
                font-size: 13px;
                text-align: left;

                &:last-child {
                    padding-left: 18px;
                }
            }

            &-selected {
                font-size: 15px;
                text-align: left;
                stroke: #fff;

                .MuiSvgIcon-root {
                    color: #ADABA8;
                }

                .Mui-checked .MuiSvgIcon-root, .MuiCheckbox-indeterminate .MuiSvgIcon-root {
                    color: #51A3FF;
                    stroke: rgba(25, 118, 210, 0.08);
                }
            }

            &-option {
                &-container {
                    display: flex;
                    gap: 10px;
                    height: 28px;

                    .MuiButtonBase-root {
                        background-color: #F0F0F0;
                        letter-spacing: 0.13px;
                        font-size: 13px;
                        color: #666;
                        border-radius: 8px;
                        padding: 5px 10px;
                        text-transform: none;
                        display: flex;
                        align-items: center;
                        gap: 3px;

                        &.more {
                            background-color: unset;
                        }

                        .MuiButton-startIcon {
                            margin: 0;

                            img  {
                                width: 18px;
                                height: 18px;
                            }
                        }
                    }
                }
            }
        }

        &-row:first-child {
            height: 44px;
            box-sizing: border-box;
            padding: 0;

            .MuiTableCell-root {
                padding-bottom: 4px;
                height: 44px;
                box-sizing: border-box;
            }
        }
    }

    .money-header {
        width: 100%;
        position: sticky;
        top: 0;
        background-color: white;
        z-index: 1;



        &-row {
            &-cell {
                color: #333333;
                font-size: 13px;
                font-weight: 500;
                padding: 7px 5px;
                text-align: left;

                &:last-child {
                    padding-left: 13px;
                }
            }

            &-selected {
                font-size: 15px;
                text-align: left;
                stroke: #fff;

                .MuiSvgIcon-root {
                    color: #ADABA8;
                }

                .Mui-checked .MuiSvgIcon-root, .MuiCheckbox-indeterminate .MuiSvgIcon-root {
                    color: #51A3FF;
                    stroke: rgba(25, 118, 210, 0.08);
                }
            }

            &-option {
                &-container {
                    display: flex;
                    gap: 10px;
                    height: 28px;

                    .MuiButtonBase-root {
                        background-color: #F0F0F0;
                        letter-spacing: 0.13px;
                        font-size: 13px;
                        color: #666;
                        border-radius: 8px;
                        padding: 5px 10px;
                        text-transform: none;
                        display: flex;
                        align-items: center;
                        gap: 3px;

                        &.more {
                            background-color: unset;
                        }

                        .MuiButton-startIcon {
                            margin: 0;

                            img {
                                width: 18px;
                                height: 18px;
                            }
                        }
                    }
                }
            }
        }

        &-row:first-child {
            height: 44px;
            box-sizing: border-box;
            padding: 0;

            .MuiTableCell-root {
                padding-bottom: 4px;
                height: 44px;
                box-sizing: border-box;
            }
        }
    }

    .clients-header {
        width: 100%;
        position: sticky;
        top: 0;
        background-color: white;
        z-index: 1;



        &-row {
            &-cell {
                color: #333333;
                font-size: 13px;
                font-weight: 500;
                padding: 7px 5px;
                text-align: left;

                &:last-child {
                    padding-left: 13px;
                }
            }

            &-selected {
                font-size: 15px;
                text-align: left;
                stroke: #fff;

                .MuiSvgIcon-root {
                    color: #ADABA8;
                }

                .Mui-checked .MuiSvgIcon-root, .MuiCheckbox-indeterminate .MuiSvgIcon-root {
                    color: #51A3FF;
                    stroke: rgba(25, 118, 210, 0.08);
                }
            }

            &-option {
                &-container {
                    display: flex;
                    gap: 10px;
                    height: 28px;

                    .MuiButtonBase-root {
                        background-color: #F0F0F0;
                        letter-spacing: 0.13px;
                        font-size: 13px;
                        color: #666;
                        border-radius: 8px;
                        padding: 5px 10px;
                        text-transform: none;
                        display: flex;
                        align-items: center;
                        gap: 3px;

                        &.more {
                            background-color: unset;
                        }

                        .MuiButton-startIcon {
                            margin: 0;

                            img {
                                width: 18px;
                                height: 18px;
                            }
                        }
                    }
                }
            }
        }

        &-row:first-child {
            height: 44px;
            box-sizing: border-box;
            padding: 0;

            .MuiTableCell-root {
                padding-bottom: 4px;
                height: 44px;
                box-sizing: border-box;
            }
        }
    }

    .products-header {
        width: 100%;
        position: sticky;
        top: 0;
        background-color: white;
        z-index: 1;



        &-row {
            &-cell {
                color: #333333;
                font-weight: 500;
                padding: 7px 5px;
                font-size: 13px;
                text-align: left;

                &:last-child {
                    padding-left: 12px;
                }
            }

            &-selected {
                font-size: 15px;
                text-align: left;
                stroke: #fff;

                .MuiSvgIcon-root {
                    stroke: #fff;
                    color: #ADABA8;
                }

                .Mui-checked .MuiSvgIcon-root, .MuiCheckbox-indeterminate .MuiSvgIcon-root {
                    color: #51A3FF;
                    stroke: rgba(25, 118, 210, 0.08);
                }
            }

            &-option {
                &-container {
                    display: flex;
                    gap: 10px;

                    &.actions {
                        display: inline-block;
                        box-sizing: border-box;
                        height: 44px;
                        display: flex;
                        align-items: center;
                        display: flex;
                        // gap: 100px;
                    }

                    .MuiButtonBase-root {
                        background-color: #F0F0F0;
                        letter-spacing: 0.13px;
                        font-size: 13px;
                        color: #666;
                        border-radius: 8px;
                        padding: 5px 10px;
                        text-transform: none;
                        display: flex;
                        align-items: center;
                        gap: 3px;
                        height: 28px;

                        &.more {
                            background-color: unset;
                        }

                        .MuiButton-startIcon {
                            margin: 0;

                            img {
                                width: 18px;
                                height: 18px;
                            }
                        }
                    }

                    .MuiButtonBase-root:nth-child(2) {
                        margin-left: 100px;
                    }
                }
            }
        }

        &-row:first-child {
            height: 44px;
            box-sizing: border-box;
            padding: 0;

            .MuiTableCell-root {
                padding-bottom: 4px;
                height: 44px;
                box-sizing: border-box;
            }
        }
    }

    .invoices_pagination {
        &-container {
            display: flex;
            align-items: center;

            p {
                display: flex;
                gap: 5px;
            }
        }
    }

    .money_pagination {
        &-container {
            display: flex;
            align-items: center;

            p {
                display: flex;
                gap: 5px;
            }
        }
    }

    .clients_pagination {
        &-container {
            display: flex;
            align-items: center;

            p {
                display: flex;
                gap: 5px;
            }
        }
    }

    .products_pagination {
        &-container {
            display: flex;
            align-items: center;

            p {
                display: flex;
                gap: 5px;
            }
        }
    }
}


.cloud-page__header_invoice-manager-details {
    // height: 40px;
    display: flex;
    gap: 10px;
    align-items: center;
    width: 100%;
    padding-left: 10px;
    box-sizing: border-box;

    &_container {
        display: flex;
        width: 100%;
        gap: 28px;

        & > div {
            width: 50%;
            display: flex;
            gap: 20px;
        }

        & > div:last-child {
            justify-content: end;
        }
    }

    &_search {
        width: 310px;

        .MuiFormControl-root {
            width: 100%;
        }

        &_container {
            position: relative;
            color: #666666;
            margin-left: 0;
            width: 100%;
            display: flex;
            align-items: center;
            // background: rgba(240, 242, 245, 0.8);
            background-color: #ffffff80;
            border-radius: 8px;
            transform: translateX(0);
            transition: all 0.3s ease;
            width: auto;
            padding: 0 10px;

            label img {
              width: 18px;
            }
        }

        &-input {
            width: 100%;
            height: 36px;
            font-size: 15px;
            background-color: transparent;
            padding: 0 8px;
            line-height: 33px;
            border: unset;
            transition: all 0.3s ease;
            color: #74737B;
            transform: translateX(0);

            &::placeholder {
              color: #74737B;

            }
        }
    }

    &_filter {
        background-color: #ffffff80;
        border-radius: 8px;
        width: 36px;
        height: 36px;

        .MuiButtonBase-root {
            width: 36px;
            height: 36px;
        }

        .MuiButtonBase-root:hover {
            background-color: unset;
        }
    }

    &_drawer {

        top: 90px !important;
        bottom: 45px !important;
        align-items: start;
        display: flex;
        justify-content: center;
        padding-top: 45px;
        margin: 0 auto;
        max-width: 1600px;
        left: 100px !important;

        @media (max-width:1600px) {
            right: 100px !important;
        }

        .MuiBackdrop-root.MuiModal-backdrop {
            top: 90px !important;
            bottom: 45px !important;
            align-items: start;
            display: flex;
            justify-content: center;
            padding-top: 45px;
            margin: 0 auto;
            max-width: 1600px;
            left: 100px !important;
            right: 100px;
            border-radius: 16px;
            background-color: rgba(0, 0, 0, 0.30);

        }

        .MuiDrawer-paper {
            height: 100%;
            outline: none;
            box-sizing: border-box;
            margin: 0 auto;
            position: absolute;
            border-radius: 8px;
        }
    }

    &_add {
        &-btn.MuiButtonBase-root {
          background-color: #51A3FF;
          font-size: 13px;
          width: 100%;
          padding: 8px 15px;
          text-align: center;
          color: #fff;
          border-radius: 8px;
          text-transform: none;
        }

        &-btn.MuiButtonBase-root:hover {
          background-color: #51A3FF;
        }

        &-modal {
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }


    &_actions {
        width: 100%;
        display: flex;
        background-image: linear-gradient(to right,#BBCFFC, #F3A8F5);
        box-sizing: border-box;
        height: 40px;
        gap: 10px;
        align-items: center;
        padding: 0 10px;
        border-radius: 8px;
        margin-left: 10px;
        // flex-grow: 1;
        // flex-shrink: 1;

        &-btn.MuiButtonBase-root  {
            // width: max-content;
            height: 25px;
            padding: 3px 8px;
            background-color: #ffffff;
            color: #333333;
            box-shadow: unset;
            flex-grow: 1;
            flex-shrink: 1;
            line-height: 11px;
            font-size: 12px;

            .MuiButton-startIcon  {
                background-color: #E9F0F7;
                font-size: 11px;
                width: 17px;
                height: 17px;
                text-align: center;
                border-radius: 4px;
                display: flex;
                justify-content: center;
                align-items: center;
            }
        }

        &-btn.MuiButtonBase-root:hover {
            background-color: #ffffff;
            color: #333333;
            box-shadow: unset;

            .MuiButton-startIcon  {
                background-color: #E9F0F7;
            }
        }
    }
}
