.isnot-home .notification-popup {
  width: max-content;
  position: relative;
}

.notification-popup {
    width: 390px;
    // height: calc(100% - 190px);
    height: calc(100% - 135px);
    max-height: 90vh;
    padding: 15px;
    border-radius: 18px;
    // background: rgba(223, 233, 248, 0.65);
    background: rgba(223, 233, 248, 0.37);
    // box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.15);
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.15), inset 0px 0px 0px 1px rgba(255, 255, 255, 0.3019607843);
    backdrop-filter: blur(30px);
    display: flex;
    z-index: 3;
    flex-direction: row;
    // height: 100%;
    position: fixed;
    transition: all 0.3s ease;
    right: 20px;
    // top: 69px;
    top: 90px;
    box-sizing: border-box;
    // box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.15);
    filter: drop-shadow(0px 4px 20px rgba(0, 0, 0, 0.15));

    &-list {
      width: 100%;
      height: 100%;
      box-sizing: border-box;
      display: flex;
      flex-direction: column;
      display: flex;
      padding: 20px;
      border-radius: 8px;
      background: #FFF;

      &__header {
        padding-bottom: 20px;
        display: flex;
        justify-content: space-between;
        border-bottom: 1px solid #ececec;
        align-items: center;
        box-sizing: border-box;

        &-title {
          font-size: 20px;
          color: #000000;
        }

        &-actions {
          display: flex;
          gap: 15px;

          &_btn {
            button.MuiButtonBase-root {
              padding: 3px;
              margin-right: -3px;
            }
          }
        }
      }

      &__body {
        overflow: hidden;
        // height: calc(100% - 90px);
        height: 100%;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        &-messages {
          height: 100%;
          padding: 0 !important;
          gap: 15px;
          display: flex;
          flex-direction: column;
          overflow-y: scroll;


          &_container {
            height: 100%;

            & .MuiBox-root {
              padding: 0;
              height: 100%;
            }
            & .MuiTypography-root {
              height: 100%;
            }
          }

          &_item {
            flex-direction: column;
            padding: 0 !important;
            gap: 6px;
            border-bottom: 1px solid #ececec;

            &:first-child {
              padding-top: 20px !important;
            }

            & .MuiTabs-flexContainer  {
              flex-direction: column-reverse;
            }

            &_header {
                display: flex;
                width: 100%;
                gap: 6px;
                align-items: center;

                &-icon {
                  padding-bottom: 3px;
                    & .MuiSvgIcon-root {
                    font-size: 7px;
                    color: #4382C4;
                    }
                }

                &-category {
                    display: flex;
                    align-items: center;
                    gap: 6px;
                    color: #828282;
                    font-size: 13px;
                    line-height: 20px;

                    span {
                      width: 3px;
                      height: 3px;
                      border-radius: 50%;
                      background-color: #828282;
                      display: inline-block;
                    }
                }

                &-time {
                    color: #828282;
                    font-size: 13px;
                    line-height: 20px;
                }
            }

            &_content {
                & .MuiListItemText-root {
                    display: flex;
                    flex-direction: column;
                    gap: 6px;
                    margin-bottom: 15px;
                    margin-top: 0;
                }

                & .MuiListItemText-primary {
                    color: #333;
                    font-size: 14px;
                    font-weight: 500;
                }

                & .MuiListItemText-secondary {
                    color: #828282;
                    font-size: 14px;
                }

            }

            &:last-child {
                border-bottom: unset;
            }
          }
        }

      }
    }
}


// .siteWrapperLG .notification-popup {

//   &-list {
//     width: 330px;


//     &__header {
//       padding: 20px;

//       &-title {
//         font-size: 20px;
//       }
//     }

//     &__body {
//       &-messages {
//         padding: 20px !important;

//         &_item {
//           &_header {
//               &-icon {
//                   & .MuiSvgIcon-root {
//                   font-size: 11px;
//                   }
//               }

//               &-category {
//                   font-size: 13px;
//               }

//               &-time {
//                   font-size: 12px;
//               }
//           }

//           &_content {
//               & .MuiListItemText-primary {
//                   font-size: 13px;
//               }

//               & .MuiListItemText-secondary {
//                   font-size: 12px;
//               }
//           }
//         }
//       }

//     }
//   }
// }

// .siteWrapperMD .notification-popup {

//   &-list {
//     width: 330px;

//     &__header {
//       &-title {
//         font-size: 20px;
//       }
//     }

//     &__body {
//       &-messages {
//         &_item {
//           &_header {
//               &-icon {
//                   & .MuiSvgIcon-root {
//                   font-size: 11px;
//                   }
//               }

//               &-category {
//                   font-size: 13px;
//               }

//               &-time {
//                   font-size: 12px;
//               }
//           }

//           &_content {
//               & .MuiListItemText-primary {
//                   font-size: 13px;
//               }

//               & .MuiListItemText-secondary {
//                   font-size: 12px;
//               }
//           }
//         }
//       }

//     }
//   }
// }

// .siteWrapperSM .notification-popup {

//   &-list {
//     width: 350px;

//     &__header {
//       &-title {
//         font-size: 20px;
//       }
//     }

//     &__body {
//       &-messages {
//         &_item {
//           &_header {
//               &-icon {
//                   & .MuiSvgIcon-root {
//                   font-size: 11px;
//                   }
//               }

//               &-category {
//                   font-size: 13px;
//               }

//               &-time {
//                   font-size: 12px;
//               }
//           }

//           &_content {
//               & .MuiListItemText-primary {
//                   font-size: 13px;
//               }

//               & .MuiListItemText-secondary {
//                   font-size: 12px;
//               }
//           }
//         }
//       }

//     }
//   }
// }

// .siteWrapperXS .notification-popup {
//   &-list {
//     width: 350px;

//     &__header {
//       &-title {
//         font-size: 20px;
//       }
//     }

//     &__body {
//       &-messages {
//         &_item {
//           &_header {
//               &-icon {
//                   & .MuiSvgIcon-root {
//                   font-size: 11px;
//                   }
//               }

//               &-category {
//                   font-size: 13px;
//               }

//               &-time {
//                   font-size: 12px;
//               }
//           }

//           &_content {
//               & .MuiListItemText-primary {
//                   font-size: 13px;
//               }

//               & .MuiListItemText-secondary {
//                   font-size: 12px;
//               }
//           }
//         }
//       }

//     }
//   }
// }

.dark .notification-popup {
  background-color: $dark-theme-background-color;

  &-list {
    &__header {
      border-color: #7c7c7c;

      &-title {
        color: $dark-theme-title-color;
      }

      &-actions {
        & .MuiSvgIcon-root {
          color: $dark-theme-title-color;
        }
      }
    }

    &__body {
      &-messages {
        &_item {
          border-color: #7c7c7c;

          &_header {
              &-icon {
                  & .MuiSvgIcon-root {
                  color: #51a3ff;
                  }
              }

              &-category {
                  color: #51a3ff;
              }

              &-time {
                  color: $dark-theme-subtitle-color;
              }
          }

          &_content {
            & .MuiListItemText-primary {
                color: $dark-theme-title-color;
            }

            & .MuiListItemText-secondary {
                color: $dark-theme-subtitle-color;
            }
          }
        }
      }
    }
  }
}
