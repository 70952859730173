$blue: #3089dc !default;
$light-blue: #d8e8f8 !default;

$white: white;
$red: #e50f4c !default;

$green: #5abc60 !default;
$light-green: #eef8ef !default;

$black: #565f77;

$gray: #5f6483;
$light-gray: #dee2ee;

$orange: #f08c33 !default;
$light-orange: #fff4e7;

$purple: #621866;
$light-purple: #f9f0fc;

$silver: #f6f9fd;
$delimiter: rgba(21,57,75, 0.07) !default;

$border-radius-big: 18px;
$border-radius-main: 5px;
$border-radius-second: 3px;

$space: 10px;
$min-width: 1280px;

$logo-desktop-width: 100px;

$gray-text: #5D697F;

$cloud-header-background: #DFE9F8;
$cloud-title-color: #000;
$cloud-subtitle-color: #515154;


$light-theme-background-color: #ffffff;
$light-theme-title-color: #000000;
$light-theme-subtitle-color: #515154;


$dark-theme-background-color:  #5c5c5c;
$dark-theme-darker-background-color:#171717;
$dark-theme-title-color: #ffffff;
$dark-theme-subtitle-color: #cbcbcb;
$dark-theme-friend-chat-background: #7c7c7c;
$dark-theme-own-chat-background: #849cb9;
